import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MyTextArea, MyTextInput} from "../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {DASHBOARD, DETAIL, MEMBERSHIP_LIST, NEW, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {createMembership, getMembershipById, updateMembership} from "../../actions/membershipsActions";
import {toast} from "react-toastify";


const MembershipDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, MEMBERSHIP_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        discordRole: "",
    });

    const onSubmit = (values) => {
        if (values.discountPercent && values.discountAmount) {
            toast.error("Vyber pouze jedno ze Sleva % a Sleva Kč");
            return;
        }
        if (isValidId(id)) {
            updateMembership(values, id)
                .then(response => {
                    navigate(PATH.MEMBERSHIPS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createMembership(values)
                .then(response => {
                    navigate(PATH.MEMBERSHIPS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const courseSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        description: Yup.string()
            .max(1024, intl.formatMessage({id: "validation.tooLong"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getMembershipById(id)
                .then(res => {
                    if (mounted) {
                        setData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.MEMBERSHIPS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={courseSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "membership.detailTitle" : "membership.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "membership.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "membership.name"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "membership.discordRole"})}
                                        name="discordRole"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "membership.discordRole"})}
                                    />
                                    <MyTextArea
                                        label={intl.formatMessage({id: "membership.description"})}
                                        name="description"
                                        placeholder={intl.formatMessage({id: "membership.description"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "membership.discountPercent"})}
                                        name="discountPercent"
                                        type="decimal"
                                        placeholder={intl.formatMessage({id: "membership.discountPercent"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "membership.discountAmount"})}
                                        name="discountAmount"
                                        type="decimal"
                                        placeholder={intl.formatMessage({id: "membership.discountAmount"})}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

export default MembershipDetail;