import {BASE_API_URL} from "../const";
import {post} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/stream`;
const BASE_PUBLIC_URL = `${BASE_API_URL}/api/public/stream`;

export const getVideoToken = (courseVideoId) => {
    const token = post(`${BASE_URL}/generate`, {courseVideoId: courseVideoId});
    return token;
}

export const getFreeVideoToken = (courseVideoId) => {
    const token = post(`${BASE_PUBLIC_URL}/generate`, {courseVideoId: courseVideoId});
    return token;
}