import {Formik} from 'formik';
import {IconButton, List, ListItem, ListItemText, Stack, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {MyTextInput} from "../../../components/form/components";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../../components/actionPanel";
import {getProductById} from "../../../actions/productActions";
import {PATH} from "../../../const";
import TitledForm from "../../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, OPTION_LIST, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {isValidId} from "../../../utils/utils";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {createOption, getOptionById, updateOption} from "../../../actions/optionActions";
import DeleteIcon from '@mui/icons-material/Delete';

const OptionDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, OPTION_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        optionType: "STRING",
        values: []
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateOption(values, id)
                .then(response => {
                    navigate(PATH.OPTIONS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createOption(values)
                .then(response => {
                    navigate(PATH.OPTIONS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const optionSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        values: Yup.array()
            .min(1, intl.formatMessage({id: "validation.array.notEmpty"}))
    });

    const initData = useCallback(data => {
        setData(data);
    }, []);

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getOptionById(id)
                .then(res => {
                    const d = res.data[0];
                    if (mounted) {
                        initData(d);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.OPTIONS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={optionSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "option.detailTitle" : "option.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "option.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "option.name"})}
                                    />
                                    <Accordion className={"ka-accordion"} defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><FormattedMessage
                                                id={"option.values.title"}/></Accordion.Header>
                                            <Accordion.Body>
                                                <OptionValuesForm intl={intl} setFieldValue={setFieldValue} values={values} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

const OptionValuesForm = ({intl, setFieldValue, values}) => {
    const [value, setValue] = useState();
    return <Stack spacing={10}>
        <Stack direction={"row"}>
            <TextField
                label={intl.formatMessage({id: "option.values.value"})}
                type="text"
                name="newValue"
                value={value}
                placeholder={intl.formatMessage({id: "option.values.value"})}
                onChange={e => setValue(e.target.value)}
            />
            <button type={"button"} onClick={() => {
                if (!value) {
                    return;
                }
                const current = values.values || [];
                const existing = current.find(c=>c.value == value);
                if (existing) {
                    return;
                }
                setFieldValue("values", [...current, {value: value, id: uuidv4()}]);
                setValue("");
            }
            }><FormattedMessage id={"common.addNew"} /></button>
        </Stack>
        <List dense={false} className={"ka-list"}>
            {values.values.map((v, index)=>{
                return <ListItem
                    key={v.id}
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={()=>{
                            const filtered = [...values.values];
                            filtered.splice(index, 1)
                            setFieldValue("values", filtered);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    <ListItemText
                        primary={v.value}
                    />
                </ListItem>
            })}
        </List>
    </Stack>
}

export default OptionDetail;