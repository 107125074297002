import {patch, post, put} from "../utils/api";

export const getById = (baseUrl, id) => {
    const filterModel = {
        columnField: 'id',
        operatorValue: '=',
        value: id,
    }
    return post(`${baseUrl}/filter`, {filterModel, pageNumber: 0, pageSize: 1});
}

export const createEntity = (baseUrl, values) => {
    return post(`${baseUrl}/create`, values);
}

export const updateEntity = (baseUrl, values, id) => {
    return patch(`${baseUrl}/${id}`, values);
}

export const getAllFiltered = (baseUrl, sortModel, filterModel, pageNumber, pageSize, fulltext) => {
    return post(`${baseUrl}/filter`, {sortModel, filterModel, pageNumber, pageSize, fulltext});
}