import {Container, Row} from "react-bootstrap";
import KaNavbar from "./Menu/menu";
import React from "react";
import {useAuth} from "../utils/auth";

const PublicHeader = ({titleComp, type, children}) => {
    const auth = useAuth();
    const loggedIn = !!auth.token && !!auth?.user;

    return <header>
        <Container fluid>
            <KaNavbar loggedIn={loggedIn}/>
            <Row className={`title ${type || ''}`}>
                <div>{titleComp}</div>
            </Row>
        </Container>
        <Container>
            {children}
        </Container>
    </header>
}

export default PublicHeader;