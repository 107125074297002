import React from 'react';

import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {DASHBOARD, GIFT_LIST, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {getGifts} from "../../../actions/giftActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "gift.name"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.GIFTS.LIST}/${id}`)),
    ];
}

const GiftsScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, GIFT_LIST]);

    const actions = [
        addNewAction(PATH.GIFTS.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'name', sort: 'asc' }}
                getData={getGifts}
            />
        </div>
    )
}

export default GiftsScreen;