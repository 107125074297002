import React from 'react';
import {DASHBOARD, useBreadcrumbsComponent} from "../utils/breadcrumbs";

const Dashboard = (props) => {
    useBreadcrumbsComponent([DASHBOARD]);
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    )
}

export default Dashboard;