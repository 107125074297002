import {Menu, MenuItem, SubMenu} from "react-pro-sidebar";
import {
    FaBriefcase,
    FaCog,
    FaSign,
    FaTachometerAlt,
    FaShoppingCart,
    FaUser,
    FaUsers,
    FaBook,
    FaUserFriends, FaVideo, FaStar
} from "react-icons/fa";
import {Link} from "react-router-dom";
import React from "react";
import {useIntl} from "react-intl";
import {createAdminLink} from "../../utils/utils";

const PMenu = ({shouldOpen, pathname}) => {
    const intl = useIntl();
    return (
        <>
            <Menu iconShape="circle">
                <MenuItem icon={<FaTachometerAlt/>}>
                    <Link to={"/"}>{intl.formatMessage({id: 'menu.dashboard'})}</Link>
                </MenuItem>
            </Menu>
            {/*<Menu iconShape="circle">
                <SubMenu
                    defaultOpen={shouldOpen("/settings", pathname)}
                    title={intl.formatMessage({id: 'menu.settings'})}
                    icon={<FaCog/>}
                >

                </SubMenu>
            </Menu>*/}
            <Menu iconShape="circle">
                <MenuItem icon={<FaBook/>}>
                    <Link to={createAdminLink("courses")}>{intl.formatMessage({id: 'menu.courses'})}</Link>
                </MenuItem>
            </Menu>
            <Menu iconShape="circle">
                <MenuItem icon={<FaVideo/>}>
                    <Link to={createAdminLink("streams")}>{intl.formatMessage({id: 'menu.streams'})}</Link>
                </MenuItem>
            </Menu>
            <Menu iconShape="circle">
                <MenuItem icon={<FaUsers/>}>
                    <Link to={createAdminLink("memberships")}>{intl.formatMessage({id: 'menu.membership'})}</Link>
                </MenuItem>
            </Menu>
            <Menu iconShape="circle">
                <MenuItem icon={<FaUserFriends/>}>
                    <Link
                        to={createAdminLink("referalDiscount")}>{intl.formatMessage({id: 'menu.referalDiscounts'})}</Link>
                </MenuItem>
            </Menu>
            <Menu iconShape="circle">
                <SubMenu
                    defaultOpen={shouldOpen("/eshop", pathname)}
                    title={intl.formatMessage({id: 'menu.eshop'})}
                    icon={<FaShoppingCart/>}
                >
                    <MenuItem><Link
                        to={createAdminLink("eshop/orders")}>{intl.formatMessage({id: 'menu.orders'})}</Link></MenuItem>
                    <MenuItem><Link
                        to={createAdminLink("eshop/tmpOrders")}>{intl.formatMessage({id: 'menu.tmpOrders'})}</Link></MenuItem>
                    <MenuItem><Link
                        to={createAdminLink("eshop/products")}>{intl.formatMessage({id: 'menu.products'})}</Link></MenuItem>
                    <MenuItem><Link
                        to={createAdminLink("eshop/options")}>{intl.formatMessage({id: 'menu.options'})}</Link></MenuItem>
                    <MenuItem><Link
                        to={createAdminLink("eshop/categories")}>{intl.formatMessage({id: 'menu.categories'})}</Link></MenuItem>
                    <MenuItem><Link
                        to={createAdminLink("eshop/coupons")}>{intl.formatMessage({id: 'menu.coupons'})}</Link></MenuItem>
                    <MenuItem><Link to={createAdminLink("eshop/gifts")}>{intl.formatMessage({id: 'menu.gifts'})}</Link></MenuItem>
                </SubMenu>
            </Menu>
            <Menu iconShape="circle">
                <MenuItem icon={<FaUser/>}>
                    <Link to={createAdminLink("users")}>{intl.formatMessage({id: 'menu.users'})}</Link>
                </MenuItem>
            </Menu>
            <Menu iconShape="circle">
                <MenuItem icon={<FaStar/>}>
                    <Link to={createAdminLink("reviews")}>{intl.formatMessage({id: 'menu.reviews'})}</Link>
                </MenuItem>
            </Menu>
        </>
    );
}

export default PMenu;