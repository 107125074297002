import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {BASE_API_URL} from "../const";

const BASE_URL = `${BASE_API_URL}/api/category`;

export const getCategories = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const createCategory = (values) => {
    return createEntity(BASE_URL, values);
}

export const updateCategory = (values) => {
    return updateEntity(BASE_URL, values);
}

export const getCategoryById = (id) => {
    return getById(BASE_URL, id);
}