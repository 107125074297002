import {useField} from 'formik';
import {
    Autocomplete, Box,
    Button, Checkbox, Chip, createFilterOptions,
    FormControl,
    FormControlLabel, FormHelperText,
    Grid,
    InputLabel,
    MenuItem, OutlinedInput, Radio, RadioGroup,
    Select,
    TextField
} from "@mui/material";
import React from "react";
//import DateTimePicker from '@mui/lab/DateTimePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import Dropzone from "react-dropzone";
import {FormattedMessage} from "react-intl";
import {v4 as uuidv4} from "uuid";

const basicLabel = {
    xs: 12,
    md: 3
};
const basicValue = {
    xs: 12,
    md: 9
};

export const SubmitButton = () => {
    return <Button type="submit" color="blue" variant="contained">
        <FormattedMessage id={"common.save"}/>
    </Button>;
}

export const MyTextInput = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return <TextField
        label={label}
        type="text"
        {...field}
        {...props}
        error={!!meta.error}
        helperText={meta.error}
        InputLabelProps={{shrink: !!field.value}}
    />
};

export const MyDateTimePicker = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);
    return (
        <Grid item container columnSpacing={2}>
            <Grid className="form-component-label" item {...basicLabel}>
                <label htmlFor={props.id || props.name}>{label}</label>
            </Grid>
            <Grid item {...basicValue}>
                <DateTimePicker
                    renderInput={(p) => <TextField className="datetime-picker" {...p} />}
                    //label={label}
                    value={field.value}
                    onChange={(newValue) => {
                        helpers.setValue(newValue);
                    }}
                />
                {meta.touched && meta.error ? (
                    <div className="error">{meta.error}</div>
                ) : null}

            </Grid>
        </Grid>
    );
};

export const MyTextArea = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return <TextField
        label={label}
        type="text"
        {...field}
        {...props}
        multiline
        rows={5}
        error={meta.touched && meta.error}
        helperText={meta.error}
    />
};

export const MyCheckbox = ({label, ...props}) => {
    const [field, meta, helpers] = useField({...props, type: 'checkbox'});
    return (
        <FormControlLabel
            {...field}
            control={<Checkbox color="success" onChange={(value) => {
                helpers.setValue(value.target.checked);
            } }/>}
            label={label}/>
    );
};

export const MySelect = ({label, multiple = false, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const defaultOptionsLabel = (e) => e.name;

    const {options} = props;

    const labelId = `${props.id}_label`;

    /*console.log("Options", options, typeof options[0].id);
    console.log("Value", field.value, typeof field.value);*/

    return <FormControl fullWidth error={!!meta.error}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            labelId={labelId}
            //value={field.value}
            label={label}
            {...field}
            {...props}
            onChange={(value) => {
                helpers.setValue(value.target.value);
                props.onChange && props.onChange(value.target.value);
            }}
            error={meta.touched && meta.error}
            helperText={meta.error}
            multiple={multiple}
        >
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {props.getOptionsLabel ? props.getOptionsLabel(option) : defaultOptionsLabel(option)}
                </MenuItem>
            ))}
        </Select>
        <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const MyRadioGroup = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);

    const defaultOptionsLabel = (e) => e.name;

    const {options, onChange} = props;
    const labelId = `${props.id}_label`;

    return (
        <FormControl className={"radio-group"}>
            <InputLabel shrink={true} id={labelId}>{label}</InputLabel>
            <RadioGroup
                aria-labelledby={labelId}
                name="radio-buttons-group"
                labelId={labelId}
                //value={field.value}
                label={label}
                {...field}
                {...props}
                onChange={(value) => {
                    helpers.setValue(value.target.value);
                    if (onChange) {
                        onChange(value.target.value);
                    }
                }}
                error={meta.touched && meta.error}
                helperText={meta.error}
                InputLabelProps={{shrink: !!field.value}}
            >
                {options.map((option) => (
                    <FormControlLabel value={option.id} control={<Radio />} label={props.getOptionsLabel ? props.getOptionsLabel(option) : defaultOptionsLabel(option)} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export const MySelectChipCreatable = ({label, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const defaultOptionsLabel = (e) => e.name;

    const {options} = props;
    const labelId = `${props.id}_label`;

    const filter = createFilterOptions();

    return (
        <Autocomplete
            {...field}
            multiple
            label={label}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    helpers.setValue({
                        name: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    helpers.setValue({
                        name: newValue.inputValue,
                    });
                } else {
                    const newValArray = newValue.map(v=>{
                        if (v.name) {
                            return v;
                        }
                        return {name: v};
                    });
                    helpers.setValue(newValArray);
                }
            }}
            /*filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}*/
            freeSolo
            id={field.name}
            options={options}
            getOptionLabel={(option) => {
                return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={label}/>
            )}
            {...props}
        />
    );
};

export const MyFileUpload = ({label, selectDialog = false, ...props}) => {
    const [field, meta, helpers] = useField(props);

    const onDrop = (files) => {
        if (files.length > 0) {
            if (!props.multiple) {
                const file = files[0];
                file.preview = URL.createObjectURL(file);
                helpers.setValue(file);
            } else {
                files.forEach(f => {
                    f.preview = URL.createObjectURL(f);
                    f.uuid = uuidv4();
                });
                const value = field.value || [];
                helpers.setValue([...value, ...files]);
            }
        }
    }

    return (
        <Grid item container columnSpacing={2} className="file-upload">
            <Grid className="form-component-label" item {...basicLabel}>
                <label htmlFor={props.id || props.name}>{label}</label>
            </Grid>
            <Grid item {...basicValue}>
                <Dropzone onDrop={onDrop} multiple={props.multiple}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps({className: "dropzone"})}>
                                <input {...getInputProps()} />
                                <h6><FormattedMessage id="common.fileUpload"/></h6>
                            </div>
                        </section>
                    )}
                </Dropzone>

                {field.value && field.value.name ? (
                    <div className="selected-file">
                        {field.value && <img
                            alt={"Selected File"}
                            src={field.value.preview}
                            style={{}}
                        />}
                    </div>
                ) : null}

                {field.value && Array.isArray(field.value) ? (
                    <div className="selected-files">
                        {field.value.map(i => {
                            return <div><img
                                key={`selected-file-${i.name}`}
                                src={i.preview}
                                style={{}}
                            />
                                <div className={'image-delete'}><a onClick={() => {
                                    const afterDeleted = field.value.filter(a => a.name != i.name);
                                    helpers.setValue(afterDeleted);
                                }}><span>x</span></a></div>
                            </div>
                        })}
                    </div>
                ) : null}

                {meta.touched && meta.error ? (
                    <div className="error">{meta.error}</div>
                ) : null}

            </Grid>
            <Grid>
                {selectDialog && <Button>Otevrit galerii</Button>}
            </Grid>
        </Grid>
    );
};