import React, {useState} from "react";

const Image = ({src, hoverSrc, link, onMouseOver, onMouseOut, hovered = false}) => {
    const [hover,setHover] = useState(false);
    return <div className={"text-center"}
                onMouseOver={() => onMouseOver ? onMouseOver() : setHover(true)}
                onMouseOut={() => onMouseOut ? onMouseOut() : setHover(false)}
    >
        {(hover || hovered) && <a href={link} target={"_blank"}><img src={hoverSrc} /></a>}
        {!hover && !hovered && <img src={src} />}
    </div>;
}

export default Image;