const messages = {
    title: 'Kryptoanalyzy',
    sidebarTitle: 'Kryptoanalyzy',
    common: {
        id: "ID",
        created: "Created",
        updated: "Updated",
        deleted: "Deleted",
        addNew: "Add New",
        edit: "Edit",
        detail: "Detail",
        back: "Back",
        save: "Save",
        fileUpload: "Drag and drop file here, or click to select file",
    },
    login: {
        submit: "Sign in",
        email: "Email",
        password: "Password",
        invalidCredentials: "Invalid credentials",
    },
    menu: {
        dashboard: 'Dashboard',
        settings: 'Settings',
        courses: 'Courses',
        membership: 'Membership',
        referalDiscounts: 'Referals',
        orders: 'Orders',
        products: 'Products',
        options: 'Options',
        categories: 'Categories',
        users: 'Users',
        reviews: 'Reviews',
        coupons: 'Coupons',
        gifts: 'Gifts',
        eshop: 'E-Shop',
        logout: 'Logout',
    },
    breadcrumbs: {
        dashboard: 'Dashboard',
        eshop: 'E-Shop',
        new: 'New',
        detail: 'Detail',
        products: "Products",
        options: 'Options',
        categories: 'Categories',
        courses: 'Courses',
        memberships: 'Memberships',
        referalDiscounts: 'Referals',
        coupons: 'Coupons',
    },
    product: {
        newTitle: 'New Product',
        detailTitle: 'Product Detail',
        name: "Name",
        price: "Price",
        description: "Description",
        image: 'Image',
        type: 'Type',
        membership: 'Membership',
        virtual: 'Virtual',
        variant: {
            title: 'Product Variants',
            missingOption: 'Fill at least 1 option!',
        },
        options: {
            title: 'Product Options'
        },
        discount: {
            title: 'Discount',
        }
    },
    referalDiscount: {
        level: "Level",
        discountPercent: 'Discount %',
        discountAmount: 'Discount CZK',
    },
    option: {
        newTitle: 'New Option',
        detailTitle: 'Option Detail',
        name: 'Name',
        values: {
            title: 'Values',
            value: 'Value',
        }
    },
    category: {
        name: 'Name'
    },
    course: {
        newTitle: 'New Course',
        detailTitle: 'Course Detail',
        name: 'Name',
        order: 'Order',
        description: 'Description',
        link: 'Link',
        membership: 'Memberships',
        detail: {
            order: {
                title: 'Order'
            }
        }
    },
    membership: {
        newTitle: 'New Membership',
        detailTitle: 'Membership Detail',
        name: 'Name',
        discordRole: 'Discord Role',
        discountPercent: 'Discount %',
        discountAmount: 'Discount CZK',
        description: 'Description',
    },
    coupon: {
        newTitle: 'New Coupon',
        detailTitle: 'Coupon Detail',
        code: 'Code',
        validFrom: 'Valid From',
        validTo: 'Valid To',
        amount: 'Amount',
        amountType: 'Amount Type',
    },
    validation: {
        tooShort: "Too Short",
        tooLong: "Too Long",
        required: "Required",
    },
    homepage: {
        footer: {
            text1: "Jakékoliv analýzy, novinky, cenové odhady, vstupy, cenová rozhraní nebo jiné informace sdílené na této webové stránce, jsou poskytovány jako obecný komentář a sdíleny prostřednictvím webových stránek www.kryptoanalyzy.cz a aplikace Discord s množstvím klientů, proto se nedají považovat za investiční poradenství nebo investiční výzkum či rady. Nejsme obchodní ani finanční poradci.",
            text2: "Obchodování na kryptoměnách, forexu, futures marketech, opcích, akciích nebo jakýkoliv dalši druh marketu Trading je riskantní a NEJSOU žádné garantované způsoby jak profitovat v tradu. Pokud se budete řídit jakýmikoli nastaveními, tak to děláte na vlastní riziko a berete na sebe plnou zodpovědnost. Vždy zde je a bude určitá možnost, že o své vklady můžete přijít. Naše webové stránky a informace, které zde sdílíme by se neměly slepě následovat a nahrazovat tak nezávislý odborný výzkum provedený před nutnou investicí či obchodním rozhodnutím. V žádném případě nejsme zodpovědni za jakoukoliv ztrátu na Vašem účtu na základě našich produktů. Pro skutečné finanční rady kontaktujte svého finančního poradce.",
            text3: "Rozdílové smlouvy jsou komplexní nástroje a v důsledku použití finanční páky jsou spojeny s vysokým rizikem rychlého vzniku finanční ztráty. U 74 až 89% účtů retailových investorů došlo při obchodování s rozdílovými smlouvami ke vzniku ztráty. Měli byste zvážit, zda rozumíte tomu, jak rozdílové smlouvy fungují, a zda si můžete dovolit vysoké riziko ztráty svých finančních prostředů.",
            links: {
                gdpr: "GDPR",
                termsConditions: "Obchodní podmínky"
            },
            company: {
                name: "K & T analysis s.r.o",
                icTitle: "IČ",
                ic: "11863561",
                address: "Adresa",
                city: "Praha - Vinohrady",
                street: "Korunní 2569/108",
                zip: "PSČ 101 00",
                emailTitle: "email",
                email: "info@kryptoanalyzy.cz"
            }
        },
        krystof: {
            description: "Začal jsem dřív obchodovat, než jsem stihl zjistit, co je Bitcoin. Postupně od technické analýzy na forexu jsem přešel na kryptoměny a to především na BTC. Postupně jsem nabíral zkušenosti v TA, u které jsem zůstal poslední dva roky. Na svojí cestě jsem poznal pár obchodníků, který mi nenabízeli produkty, ale jejich zkušenosti. Přesně tyto kluky najdeš u nás ve skupině! Veškeré vědomosti rád předávám dál, nejvíce ze všeho mě motivují lidé, kteří se posouvají neustále dopředu. Jediný způsob jak selhat, je přestat. Jestli se mám nazvat CEO, tak se tím může nazvat úplně každý v naší skupině, protože všichni jsme na stejným místě, všichni hrajeme tu samou hru na trhu a zdokonalujeme ji. Mojí specializací je footprint, díky kterému dokážu říct přesný pohyb trhu."
        },
        zinda: {
            description: "K obchodování jsem se dostal náhodou přes reklamu na Facebooku. Zaujalo mě jak jednoduše a přitom složitě se dají vydělat peníze. Začal jsem se o to více zajímat ale první 3 měsíce jsem padal víc a víc do mínusu. Začátkem 4 měsíce v kryptu jsem potkal skupinu Krypto Analýzý. Od té doby jsem učení věnoval všechen volný čas, to je pro mě 10hodin denně. Víkendy 24/7 jen u grafu a učení. Udělal jsem spousty chyb, které mi dali cenné lekce, které s radostí předávám dál. Nemám přesnou strategii nebo přesný typ technické analýzy, kterou dělám. Při vstupu do obchodu nebo určování delší predikci používám všechny možné nástroje pro nejlepší přesnost a já si stále udržoval vědomosti v hlavě.",
        },
        sylvestr: {
            description: ""
        },
        zabijak: {
            description: ""
        }
    },
    payment: {
        firstname: "First Name",
        lastname: "Last Name",
        company: "Company",
        country: "Country / Region",
        street: "Street and house number",
        city: "City",
        zip: "ZIP",
        phone: "Phone number",
        email: "E-mail",
        password: "Account password",
        differentDelivery: "Deliver to different address",
        notes: "Notes"
    }
};

export default messages;