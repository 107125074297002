import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {MyDateTimePicker, MyRadioGroup, MySelect, MyTextInput} from "../../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../../components/actionPanel";
import {getProductById} from "../../../actions/productActions";
import {PATH} from "../../../const";
import TitledForm from "../../../components/form/Form";
import {COUPON_LIST, DASHBOARD, DETAIL, NEW, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {isValidId} from "../../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {createCoupon, getCouponById, updateCoupon} from "../../../actions/couponActions";
import moment from "moment";

const CouponDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, COUPON_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        code: "",
        validFrom: null,
        validTo: null,
        amount: 0,
        amountType: "PERCENT",
        couponType: 'BOTH',
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateCoupon(values, id)
                .then(response => {
                    navigate(PATH.COUPONS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createCoupon(values)
                .then(response => {
                    navigate(PATH.COUPONS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const couponSchema = Yup.object().shape({
        code: Yup.string()
            .min(3, intl.formatMessage({id: "validation.tooShort"}))
            .max(10, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        amountType: Yup.string()
            .min(5, intl.formatMessage({id: "validation.tooShort"}))
            .required(intl.formatMessage({id: "validation.required"})),
        amount: Yup.number()
            .required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getCouponById(id)
                .then(res => {
                    const d = res.data[0];
                    if (mounted) {
                        setData({...d, validFrom: d.validFrom ? moment(d.validFrom) : null, validTo: d.validTo ? moment(d.validTo) : null});
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.COUPONS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={couponSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "coupon.detailTitle" : "coupon.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "coupon.code"})}
                                        name="code"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "coupon.code"})}
                                    />
                                    <MySelect
                                        label={intl.formatMessage({id: "coupon.amountType"})}
                                        name="amountType"
                                        options={[{id: "PERCENT", name: "Procenta"}, {id: "AMOUNT", name: "Částka"}]}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "coupon.amount"})}
                                        name="amount"
                                        type="decimal"
                                        placeholder={intl.formatMessage({id: "coupon.amount"})}
                                    />
                                    <MyDateTimePicker
                                        name="validFrom"
                                        label={intl.formatMessage({id: "coupon.validFrom"})}
                                        placeholder={intl.formatMessage({id: "coupon.validFrom"})}
                                    />
                                    <MyDateTimePicker
                                        name="validTo"
                                        label={intl.formatMessage({id: "coupon.validTo"})}
                                        placeholder={intl.formatMessage({id: "coupon.validTo"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "coupon.usage"})}
                                        name="usage"
                                        type="number"
                                        placeholder={intl.formatMessage({id: "coupon.usage"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "coupon.usageRemain"})}
                                        name="usageRemain"
                                        type="number"
                                        disabled
                                        placeholder={intl.formatMessage({id: "coupon.usageRemain"})}
                                    />
                                    <MyRadioGroup
                                        label={intl.formatMessage({id: "coupon.type"})}
                                        defaultValue={data.couponType || "BOTH"}
                                        name="couponType"
                                        onChange={(value) => {
                                            //setData({...data, paymentType: value});
                                            setFieldValue("couponType", value);
                                        }}
                                        options={[{id: "BOTH", name: "Obojí"},  {id: "PRODUCTS", name: "Pouze produkty"}, {id: "MEMBERSHIPS", name: "Pouze členství"}]}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}


export default CouponDetail;