import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MyTextInput} from "../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, REFERAL_DISCOUNT_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {
    createReferalDiscount,
    getReferalDiscountById,
    updateReferalDiscount
} from "../../actions/referalDiscountActions";
import {getMemberships} from "../../actions/membershipsActions";


const ReferalDiscountDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, REFERAL_DISCOUNT_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        discounts: {},
    });
    const [memberships, setMemberships] = useState([]);

    const onSubmit = (values) => {
        //console.log("VALUES", values)
        /*if (values.discountPercent && values.discountAmount) {
            toast.error("Vyber pouze jedno ze Sleva % a Sleva Kč");
            return;
        }*/
        if (isValidId(id)) {
            updateReferalDiscount(values, id)
                .then(response => {
                    navigate(PATH.REFERAL_DISCOUNT.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createReferalDiscount(values)
                .then(response => {
                    navigate(PATH.REFERAL_DISCOUNT.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const courseSchema = Yup.object().shape({
        level: Yup.string()
            .min(1, intl.formatMessage({id: "validation.tooShort"}))
            .max(5, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getReferalDiscountById(id)
                .then(res => {
                    if (mounted) {
                        const dis = res.discounts;
                        let formatted = {};
                        if (dis) {
                            const d = dis.reduce((prev,cur)=>{
                                prev[cur.membershipId] = {
                                    discountAmount: cur.discountAmount,
                                    discountPercent: cur.discountPercent,
                                };
                                return prev;
                            }, {});
                            formatted = d;
                        }
                        setData({...res, discounts: formatted});
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    useEffect(()=>{
        getMemberships([{field: "name", sort: "asc"}])
            .then(res => setMemberships(res.data));
    }, []);

    const actions = (values) => [
        addBackAction(PATH.REFERAL_DISCOUNT.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={courseSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "referalDiscount.detailTitle" : "referalDiscount.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "referalDiscount.level"})}
                                        name="level"
                                        type="number"
                                        placeholder={intl.formatMessage({id: "referalDiscount.level"})}
                                    />
                                    <Accordion className={"ka-accordion"}>
                                        {(memberships || []).map((m, index) =>
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header>{m.name}</Accordion.Header>
                                                <Accordion.Body>
                                                    <MyTextInput
                                                        label={intl.formatMessage({id: "membership.discountPercent"})}
                                                        name={`discounts.${m.id}.discountPercent`}
                                                        type="decimal"
                                                        placeholder={intl.formatMessage({id: "membership.discountPercent"})}
                                                    />
                                                    <MyTextInput
                                                        label={intl.formatMessage({id: "membership.discountAmount"})}
                                                        name={`discounts.${m.id}.discountAmount`}
                                                        type="decimal"
                                                        placeholder={intl.formatMessage({id: "membership.discountAmount"})}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                    </Accordion>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

export default ReferalDiscountDetail;