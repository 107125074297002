import React, {useEffect, useState} from "react";

import {
    useNavigate,
    useLocation,
    Navigate,
    } from 'react-router-dom';
import {authProvider, COOKIE_KEY} from "./authProvider";
import jscookie from "js-cookie";
import {getUserByEmail} from "../actions/userActions";

const LS_KEY = "KryptoAnalyzyUser";

export function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();

    if (!auth.user) {
        return <p>You are not logged in.</p>;
    }

    return (
        <p>
            Welcome {auth.user}!{" "}
            <button
                onClick={() => {
                    auth.signout(() => navigate("/"));
                }}
            >
                Sign out
            </button>
        </p>
    );
}

// interface AuthContextType {
//     user: any;
//     signin: (user, callback) => void;
//     signout: (callback) => void;
// }

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
    const saved = localStorage.getItem(LS_KEY);
    const savedUser = JSON.parse(!!saved ? saved : "{}");
    let [user, setUser] = React.useState(savedUser || null);

    const token = jscookie.get(COOKIE_KEY) || null;

    let signin = (email, password, callback) => {
        authProvider.signin(email, password).then(response=>{
            //console.log("Response", response);
            if (!!response) {
                setUser(response.email);
                localStorage.setItem(LS_KEY, JSON.stringify(response.email));
                //console.log("User saved", response.email);
                callback && callback(true);
            } else {
                setUser(null);
                callback && callback(false);
            }
        }).catch(e=>{
            setUser(null);
            localStorage.removeItem(LS_KEY);
            callback && callback(false);
        });
    };

    let signout = (callback) => {
        return authProvider.signout(() => {
            setUser(null);
            jscookie.remove(COOKIE_KEY);
            localStorage.removeItem(LS_KEY);
            callback && callback();
        });
    };

    let value = { user, signin, signout, token };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuth({ children, setLocale }) {
    let auth = useAuth();
    let location = useLocation();

    //console.warn("AUTH", auth.user, auth.token);

    if (!!auth.token && !!auth?.user) {
        return children;
    }

    auth.signout();

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
}

export const useUser = () => {
    const [user, setUser] = useState(null);
    const auth = useAuth();
    useEffect(() => {
        if (!!auth.token && !!auth?.user) {
            getUserByEmail(auth.user)
                .then(res=>setUser(res));
        }
    }, []);
    return user;
}