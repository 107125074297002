import md5 from 'md5';

/**
 * @package     GoUrl Bitcoin/Altcoin Payment Box - show iFrame Payment Box
 * @copyright   2014-2023 Delta Consultants
 * @category    Javascript
 * @website     https://gourl.io
 * @api         https://gourl.io/api.html
 * @version     2.2.3
 */

/**
 * Display iFrame payment box
 * Full Instruction - https://gourl.io/bitcoin-payment-gateway-api.html
 */

export const Cryptobox = ({boxID, coinName, public_key, amount, amountUSD, language, iframeID, userID, userFormat, orderID, cookieName, width, height}) => {
    if (typeof width !== 'number') width = 0;
    if (typeof height !== 'number') height = 0;

    const PERIOD = "NOEXPIRY";
    const private_key = "65701AALy53NLitecoin77LTCPRV7lHrGoXRXZs8ozW1pcTbJg";
    const private_key_hash = "2abb2da0776e7870c5cf6bada3ad32e329b585189d9754d335e4071ca68ba0345ec0afe49961e2445c057eb01788c1b296d6d195e29a32f2d4721c5106c89cb9";

    const id = public_key.substr(0, public_key.indexOf("AA"));
    if (id == '' || boxID != id || public_key.indexOf("PUB") == -1) console.warn('Invalid payment box public_key');
    else if ((amount <= 0 && amountUSD <= 0) || (amount > 0 && amountUSD > 0)) console.warn('You can use in payment box options one of variable only: amount or amountUSD. You cannot place values in that two variables together');
    else if (amount != 0 && ((amount - 0) != amount || amount < 0.0001)) console.warn('Invalid payment box amount');
    else if (amountUSD != 0 && ((amountUSD - 0) != amountUSD || amountUSD < 0.01)) console.warn('Invalid payment box amountUSD');
    else if (userFormat != 'COOKIE' && userFormat != 'SESSION' && userFormat != 'IPADDRESS' && userFormat != 'MANUAL') console.warn('Invalid payment box userFormat value');
    else if (userFormat == 'COOKIE' && cookieName == '') console.warn('Invalid payment box cookie name');
    else if (userFormat == 'COOKIE' && cryptobox_cookie(cookieName) == '') { if (document.getElementById(iframeID).src != null) document.getElementById(iframeID).src = 'https://gourl.io/images/crypto_cookies.png'; console.warn('Please enable Cookies in your Browser !'); }
    else if (userFormat == 'COOKIE' && cryptobox_cookie(cookieName) != userID) console.warn('Invalid cookie value. It may be you are viewing an older copy of the page that is stored in the website cache. Please contact with website owner, need to disable/turn-off caching for current page');
    else if (orderID == '') console.warn('Invalid orderID');
    else if (public_key.length != 50) console.warn('Invalid public key');
    //const hash = md5(`${boxID}|${coinName}|${public_key}|65701AALy53NLitecoin77LTCPRV7lHrGoXRXZs8ozW1pcTbJg||${amount}|0|${userFormat}|${language}|${iframeID}|${orderID}|${cookieName}|`);
    const hash = md5(`${boxID}|${coinName}|${public_key}|${private_key}||${amount}|${amountUSD}|${PERIOD}|${language}|${orderID}|${userID}|${userFormat}|${iframeID}|${width}|${height}`);
    const hash2 = md5(`${boxID}|${coinName}|${public_key}|${private_key}||${amount}|${amountUSD}|${PERIOD}|${language}|${orderID}|${userID}|${userFormat}|127.0.0.1`);
    const hash3 = md5(`${boxID}${private_key_hash}${userID}${orderID}${language}NOEXPIRY127.0.0.1`);
    //console.log("HASH2 STRING", `${boxID}|${coinName}|${public_key}|${private_key}||${amount}|${amountUSD}|${PERIOD}|${language}|${orderID}|${userID}|${userFormat}|127.0.0.1`);
    if (hash) {
        const url = 'https://coins.gourl.io' +
            '/b/'+encodeURIComponent(boxID)+'/c/'+encodeURIComponent(coinName)+
            '/p/'+encodeURIComponent(public_key)+
            '/a/'+encodeURIComponent(amount)+'/au/'+encodeURIComponent(amountUSD)+
            '/pe/'+encodeURIComponent(PERIOD.replace(' ', '_'))+'/l/'+encodeURIComponent(language)+
            '/i/'+encodeURIComponent(iframeID)+'/u/'+encodeURIComponent(userID)+
            '/us/'+encodeURIComponent(userFormat)+'/o/'+encodeURIComponent(orderID)+
            '/t/'+encodeURIComponent("")+
            (width>0?'/ws/'+encodeURIComponent(width):'')+
            (height>0?'/hs/'+encodeURIComponent(height):'')+
            '/h/'+encodeURIComponent(hash);
            //'/z/'+Math.random();

        //console.log("URL", url);

        /*const url2 = 'https://coins.gourl.io' +
            '/b/'+encodeURIComponent(boxID)+'/c/'+encodeURIComponent(coinName)+
            '/p/'+encodeURIComponent(public_key)+
            '/a/'+encodeURIComponent(amount)+'/au/'+encodeURIComponent(amountUSD)+
            '/pe/'+encodeURIComponent(PERIOD.replace(' ', '_'))+'/l/'+encodeURIComponent(language)+
            '/o/'+encodeURIComponent(orderID)+
            '/u/'+encodeURIComponent(userID)+
            '/us/'+encodeURIComponent(userFormat)+
            '/j/'+"1"+
            '/d/'+(btoa("127.0.0.1"))+
            '/h/'+encodeURIComponent(hash2);*/

        //console.log("URL2", url2);
        //console.log("HASH3", hash3);

        /*const html = document.getElementById(iframeID);
        if (html == null) console.warn('Cryptobox iframeID HTML with id "' + iframeID + '" not exist!');
        else html.src = url;*/
        return <iframe id={"cryptoframe"} src={url}></iframe>
    }

    return <></>;
}

const cryptobox_cookie = (name) => {
    const nameEQ = name + "="; var ca = document.cookie.split(';'); for(var i=0;i <  ca.length;i++) {
    var c = ca[i]; while (c.charAt(0)==' ') c = c.substring(1,c.length); if (c.indexOf(nameEQ) == 0)
        return c.substring(nameEQ.length,c.length); } return '';
}

function cryptobox_msghide (id) {
    setTimeout(function(){ document.getElementById(id).style.display='none';}, 15000 );
}
