import {Formik} from 'formik';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MyCheckbox, MyDateTimePicker, MyTextInput} from "../../components/form/components";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, useBreadcrumbsComponent, USER_LIST} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {
    addUserMembership,
    getUserByIdWithMemberships,
    kickUserFromDiscord,
    updateUserDetail
} from "../../actions/userActions";
import {getMemberships} from "../../actions/membershipsActions";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {toast} from "react-toastify";
import moment from "moment";

const UserDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, USER_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [discordLoading,setDiscordLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState({
        membershipValidTo: null,
        membershipId: null,
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateUserDetail(values, id)
                .then(response => {
                    navigate(PATH.USERS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const streamSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        lastName: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        membershipValidTo: Yup.date().required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getUserByIdWithMemberships(id)
                .then(res => {
                    if (mounted) {
                        const active = res.activeMembership;
                        setData({...res, membershipValidTo: active ? moment(new Date(active.validTo)) : null, membershipId: active?.userMembershipId});
                    }
                });
        }
        return () => mounted = false;
    }, [id, open]);

    const actions = (values) => [
        addBackAction(PATH.USERS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={streamSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                {/*<ValueLogger values={values}/>*/}
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "user.detailTitle" : "user.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "user.firstName"})}
                                        name="firstName"
                                        type="text"
                                        disabled
                                        placeholder={intl.formatMessage({id: "user.firstName"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "user.lastName"})}
                                        name="lastName"
                                        type="text"
                                        disabled
                                        placeholder={intl.formatMessage({id: "user.lastName"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "user.email"})}
                                        name="email"
                                        type="email"
                                        disabled
                                        placeholder={intl.formatMessage({id: "user.email"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "user.discordNickname"})}
                                        name="discordNickname"
                                        type="text"
                                        disabled
                                        placeholder={intl.formatMessage({id: "user.discordNickname"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "user.discordId"})}
                                        name="discordId"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "user.discordId"})}
                                    />
                                </Stack>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyCheckbox
                                        name="autoRenewal"
                                        disabled={true}
                                        label={intl.formatMessage({id: "user.autoRenewal"})}
                                    />
                                    <Button type="submit" color="red" variant="contained" onClick={()=>{
                                        setDiscordLoading(true);
                                        kickUserFromDiscord(id)
                                            .then(res=>{
                                                navigate(0);
                                            })
                                            .catch(err=>{

                                            })
                                            .finally(()=>{
                                                setDiscordLoading(false);
                                            })
                                    }} disabled={!values.discordId || discordLoading}>
                                        <FormattedMessage id={"user.leaveDiscord"} />
                                    </Button>
                                    {values.discordRoles && Array.isArray(values.discordRoles) && <div className={"user_active_roles"}>
                                        <h5>Člen Discordu:</h5>
                                        {
                                            values.discordAvatarUrl && <div className={"text-center"}><img src={values.discordAvatarUrl} alt={"avatar"} /></div>
                                        }
                                         {values.discordRoles.map((r, i)=>{
                                             return <div className={`role role_${r.toLowerCase()}`} key={"role" + i}>{r}</div>
                                         })}
                                    </div>}
                                    {!values.discordRoles || !values.discordRoles.length && <div className={"user_discord_actions"}>
                                        {[{name: "VIP", color: ""}, {name: "Premium", color: ""}].map(r=><Button>{r.name}</Button>)}
                                    </div>}
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Button variant="outlined" onClick={() => setOpen(true)}>
                                    Přiřadit členství
                                </Button>
                            </Col>
                        </Row>
                        {values.activeMembership &&
                            <Row style={{marginTop: '20px'}}>
                                <Col xs={12} lg={6}>
                                    <h5>Aktivní členství</h5>
                                    <h6>{values.activeMembership.name}</h6>
                                    <p>Platné do:</p>
                                    <MyDateTimePicker
                                        name={"membershipValidTo"}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12}>
                                <h5>Předchozí členství</h5>
                                <table className={"user-detail-memberships"}>
                                    <thead>
                                        <tr>
                                            <td>Platné od</td>
                                            <td>Platné do</td>
                                            <td>Členství</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!values || !values.userMemberships || !values.userMemberships.length ? <tr><td>Žádné členství</td></tr>
                                        : values.userMemberships.sort((a,b)=>new Date(a.validFrom) - new Date(b.validFrom)).map(m=>{
                                            return <tr key={m.id}>
                                                <td>{formatDate(m.validFrom)}</td>
                                                <td>{formatDate(m.validTo)}</td>
                                                <td>{m.name}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
        <NewMembershipDialog open={open} setOpen={setOpen} userId={id} />
    </div>
}


const NewMembershipDialog = ({open, setOpen, userId}) => {
    const [memberships, setMemberships] = React.useState([]);

    useEffect(()=>{
        if (!open) return;
        getMemberships([{field: "name", sort: "asc"}]).then(res=>{
            setMemberships(res.data);
        });
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData).entries());
                        console.log("VALID", typeof formJson.validFrom, formJson.validFrom);
                        if (formJson.membership && formJson.validFrom && formJson.validTo) {
                            addUserMembership({...formJson,
                                validFrom: new Date(formJson.validFrom).toISOString(),
                                validTo: new Date(formJson.validTo).toISOString()}, userId).then(res=>{
                                toast.success("Členství přidáno");
                                handleClose();
                            });
                        } else {
                            toast.error("Vyplňte všechna pole");
                        }
                    },
                }}
            >
                <DialogTitle>Přiřadit členství</DialogTitle>
                <DialogContent className="dialog-content">
                    <FormControl fullWidth>
                        <InputLabel id="membership-label">Členství</InputLabel>
                        <Select
                            required
                            margin="dense"
                            id="membership"
                            labelId="membership-label"
                            name="membership"
                            label="Členství"
                            fullWidth
                            variant="standard"
                        >
                            {memberships.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <DateTimePicker required name={"validFrom"} label="Platné od" />
                    </FormControl>
                    <FormControl fullWidth>
                        <DateTimePicker required name={"validTo"} label="Platné do" />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Přidat</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const formatDate = (date) => {
    return new Date(date).toLocaleString('cs-CS').replaceAll('. ', '.');
}

const ValueLogger = ({values}) => {
    console.log(values);
    return null;
}

export default UserDetail;