import {BASE_API_URL} from "../const";
import {createEntity, getAllFiltered, updateEntity} from "./commonActions";
import {get} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/membership`;

export const getMemberships = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const createMembership = (values) => {
    const formData = courseBody(values);
    return createEntity(BASE_URL, formData);
}

const courseBody = (values) => {
    return {
        name: values.name,
        discordRole: values.discordRole,
        description: values.description,
        discountPercent: values.discountPercent,
        discountAmount: values.discountAmount,
    };
}

export const updateMembership = (values, id) => {
    const formData = courseBody(values);
    return updateEntity(BASE_URL, formData, id)
}

export const getMembershipById = (id) => {
    return get(`${BASE_URL}/${id}`);
}