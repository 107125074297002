import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../utils/auth";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import '../styles/Login.scss';
import logo from '../assets/img/logo_pruhledny.png';
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@mui/material";
import LanguageSwitcher from "../components/languageSwitcher";
import {useCart} from "react-use-cart";
import KaHead from "./public/head";
import {changePassword, getPasswordToken} from "../actions/publicActions";

const ChangePasswordPage = (props) => {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState("");
    const [tokenValid, setTokenValid] = useState(true);
    const [saved, setSaved] = useState(false);
    const navigate = useNavigate();
    const intl = useIntl();

    const {token} = useParams();

    useEffect(() => {
        if (!saved) {
            if (!token) {
                setError("Invalid token");
                setTokenValid(false);
            }
            getPasswordToken(token).then(res=>{
                if (!res) {
                    setError("Invalid token");
                    setTokenValid(false);
                }
            });
        }
    }, []);

    const validate = (password, passwordConfirm) => {
        return !!password && !!passwordConfirm;
    };

    const validateEquals = (password, passwordConfirm) => {
        return password == passwordConfirm;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!validate(password, passwordConfirm)) {
            setError("Hesla jsou povinná");
            return;
        }
        if (!validateEquals(password, passwordConfirm)) {
            setError("Hesla se neshodují");
            return;
        }
        changePassword(token, password).then(res=>{
            if (!res) {
                setError("Nelze změnit heslo. Kontaktujte administrátora.");
            } else {
                setTokenValid(false);
                setError("Heslo úspešně změněno");
                setSaved(true);
            }
        });
        /*auth.signin(email, password, (success) => {
            if (success) {
                emptyCart();
                navigate(from, { replace: true });
            } else {
                setError("login.invalidCredentials");
            }
        });*/
    };

    return (<>
        <KaHead page={"Přihlásit se"} />
        <div className="login">
            <div className="logo"><a href={"/"}><img alt={"Login Logo"} src={logo} /></a></div>
        {/*<LanguageSwitcher locale={locale} setLocale={setLocale} />*/}
        <form onSubmit={onSubmit}>
            {!!error && <div className="error-panel">{intl.formatMessage({id:error})}</div>}
            {tokenValid && <>
            <div className="login-component">
                <label htmlFor="password"><FormattedMessage id={"changePassword.password"}/></label>
                <input value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       type="password"
                       name="password"
                       id="password"/>
            </div>
            <div className="login-component">
                <label htmlFor="passwordConfirm"><FormattedMessage id={"changePassword.passwordConfirm"}/></label>
                <input value={passwordConfirm}
                       onChange={(e) => setPasswordConfirm(e.target.value)}
                       type="password"
                       name="passwordConfirm"
                       id="passwordConfirm"/>
            </div>
            <div className="submit">
                <Button type="submit" color="red" variant="contained">
                    <FormattedMessage id={"changePassword.submit"} />
                </Button>
            </div></>}
        </form>
    </div></>);
}

export default ChangePasswordPage;