import {Button} from "@mui/material";

export const idColumn = (intl) => {
    return {field: 'id', headerName: intl({id: "common.id"}), type: 'number'};
}

export const auditColumns = (intl) => {
    return [{field: 'created', headerName: intl({id: "common.created"})},
        {field: 'updated', headerName: intl({id: "common.updated"})}];
}

export const buttonColumn = (title, onClick) => {
    return {
        field: '',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{}}
                    onClick={() => onClick(params.row.id)}
                >
                    {title}
                </Button>
        ),
    };
}

export const detailColumn = (intl, onClick) => {
    return buttonColumn(intl({id: 'common.detail'}), onClick);
}