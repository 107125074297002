import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row} from "react-bootstrap";
import {useIntl} from "react-intl";

import premiumVavrin from "../../assets/img/premium_vavrin.png";
import vipVavrin from "../../assets/img/vip_vavrin.png";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";
import KaHead from "./head";
import {getPublicProductMemberships} from "../../actions/publicActions";
import {useNavigate} from "react-router-dom";

const MembershipPage = (props) => {
    const auth = useAuth();
    const loggedIn = !!auth.token && !!auth?.user;
    const intl = useIntl();

    const [memberships,setMemberships] = useState([]);

    useEffect(()=>{
        getPublicProductMemberships().then(res=>setMemberships(res));
    }, []);

    return (
        <>
            <KaHead page={"Členství"}/>
            <PublicHeader titleComp={<h1 className="ka-title">Začni obchodovat<br/><span>jako master!</span></h1>}>
                <Row>
                    <Col xs={12} lg={{span: 4, offset: 1}}>
                        <MembershipItem name={"VIP"} photo={vipVavrin} buttonClass={"blue"} price={"od 416 Kč / měsíc"} priceEur={16} membership={memberships.find(m=>m.name.toLowerCase() == "vip")}
                                        features={["30 kurzů", "stream 1x týdně", "Denní shrnutí trhu", "Chat pro VIP", "24h podpora na chatu", "x", "x", "x", "x", "až 10% sleva na produkty", "Sleva na TRADINGVIEW", "x"]}/>
                    </Col>
                    <Col xs={12} lg={{span: 4, offset: 2}}>
                        <MembershipItem name={"PREMIUM"} photo={premiumVavrin} buttonClass={"green"} price={"od 1 249 Kč / měsíc"} priceEur={50} membership={memberships.find(m=>m.name.toLowerCase() == "premium")}
                                        features={["41 kurzů", "stream 2x týdně", "Denní shrnutí trhu", "Chat pro VIP i Premium", "24h podpora na chatu", "Daily plán", "Kurzy Exocharts / Templates", "1vs1 Trénink", "Livetrading", "až 30% sleva na produkty","Sleva na TRADINGVIEW", "EXOCHARTS zdarma"]}/>
                    </Col>
                </Row>
            </PublicHeader>
            {/*<ReviewsSection/>*/}
            <PartnersSection/>
            <FooterSection />
        </>
    )
}

const FooterInfo = ({value, title}) => {
    if (title) {
        return <p>{title}: {value}</p>
    } else {
        return <p>{value}</p>
    }
}

const MembershipItem = ({name, photo, features, price, priceEur, buttonClass, membership}) => {
    const navigate = useNavigate();
    return <div className="ka-box">
        <div className="membership-item">
            <div className="membership-header">
                <img src={photo} loading={"lazy"}/>
                <h4>{name}</h4>
            </div>
            <div className={"membership-features"}>
                <ul>
                    {features.map(d => <li>{d}</li>)}
                </ul>
            </div>
            <div className={"membership-price"}>
                <p className={buttonClass}>{price}</p>
                <p className={`${buttonClass} euro`}>{priceEur} &euro;</p>
            </div>
            <div className={"membership-buy"}>
                <button onClick={()=>{
                    if(membership) {
                        navigate("/products/" + membership.id);
                    }
                }} className={buttonClass}>Koupit {name}</button>
            </div>
        </div>
    </div>
}

export default MembershipPage;