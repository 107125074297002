import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ProSidebar, SidebarContent, SidebarFooter, SidebarHeader,} from 'react-pro-sidebar';
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaSignOutAlt} from 'react-icons/fa';
import {useLocation} from "react-router-dom";
import {useAuth} from "../../utils/auth";
import logo from "../../assets/img/logo_pruhledny.png";
import LanguageSwitcher from "../languageSwitcher";
import PMenu from "./Menu";

const shouldOpen = ( path, location ) => location.startsWith(path);


const Sidebar = ({ collapsed, setCollapsed, toggled, handleToggleSidebar, setLocale, locale }) => {
    let location = useLocation();
    const auth = useAuth();
    const { pathname } = location;

    return (
        <ProSidebar
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="md"
            onToggle={handleToggleSidebar}
        >
            <SidebarHeader>
                <div className="sidebar-logo">
                    {!collapsed && <a href="/"><img alt={"Kryptoanalyzy Logo"} src={logo} /></a>}
                </div>
                <div className="collapse-controller">
                    {collapsed && <FaAngleDoubleRight onClick={() => setCollapsed(false)} />}
                    {!collapsed && <FaAngleDoubleLeft onClick={() => setCollapsed(true)} />}
                </div>
                <LanguageSwitcher locale={locale} setLocale={setLocale} />

            </SidebarHeader>

            <SidebarContent>
                <PMenu
                    shouldOpen={shouldOpen}
                    pathname={pathname}
                />
            </SidebarContent>

            <SidebarFooter style={{ textAlign: 'center' }}>
                <div
                    className="sidebar-btn-wrapper"
                    style={{
                        padding: '20px 24px',
                    }}
                >
                    <button
                        onClick={() => auth.signout()}
                        className="sidebar-btn"
                        rel="noopener noreferrer"
                    >
                        <FaSignOutAlt />
                        <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <FormattedMessage id={"menu.logout"} />
                        </span>
                    </button>
                </div>
            </SidebarFooter>
        </ProSidebar>
    );
};

export default Sidebar;