import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";
import KaHead from "./head";
import {getStreamsToUi} from "../../actions/streamsActions";
import {getUserByEmail} from "../../actions/userActions";

const StreamsPage = (props) => {
    const [streams, setStreams] = useState([]);
    const [userDetail, setUserDetail] = useState();
    const {user} = useAuth();

    useEffect(() => {
        if (user) {
            getUserByEmail(user).then(detail => {
                if (detail) {
                    setUserDetail(detail);
                    getStreamsToUi()
                        .then(res => {
                            setStreams(res);
                        });
                } else {
                    throw new Error("No user found");
                }
            }).catch(err => {
                console.error("Error", err);
            });
        }
    }, []);

    return userDetail && userDetail.activeMembership ? <>
            <KaHead page={"Streamy"}/>
            <PublicHeader titleComp={<h1 className="ka-title">Krypto<br/><span>živě!</span>
            </h1>}>
            </PublicHeader>
            <section className={"courses bg-dark-green"}>
                <StreamsPanel streams={streams} />
            </section>
            {/*<ReviewsSection/>*/}
            <PartnersSection/>
            <FooterSection/>
        </> : <>
            <KaHead page={"Streamy"} />
            <PublicHeader titleComp={<h1 className="ka-title">Nemáte aktivní<br/><span>členství!</span></h1>}></PublicHeader>
            <PartnersSection/>
            <FooterSection/>
        </>;
}

const StreamsPanel = ({streams}) => {
    return <Container>
        <Row>
            {streams.map(c => {
                return <Col key={`stream-${c.id}`} xs={12} sm={6} md={4}>
                    <iframe width="100%" src={c.url}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    <div className={"course-video-name"}><h6>{c.name}</h6><p>{new Date(c.created).toDateString()}</p></div>
                </Col>
            })}
        </Row>
    </Container>
}

export default StreamsPage;