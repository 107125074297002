import React from 'react';

import PTable from "../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../components/table/columns";
import {ActionPanel, addNewAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import {DASHBOARD, REVIEW_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {getReviews} from "../../actions/reviewsActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "review.name"})},
        {field: 'text', headerName: intl({id: "review.text"}), valueGetter: (params)=>{
            return !!params.row.text ? params.row.text.substring(0, Math.min(30, params.row.text.length)) : "";
        }},
        ...auditColumns(intl),
        detailColumn(intl, (id) => navigate(`${PATH.REVIEW.LIST}/${id}`)),
    ];
}

const ReviewScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, REVIEW_LIST]);

    const actions = [
        addNewAction(PATH.REVIEW.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions}/>
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'created', sort: 'desc'}}
                getData={getReviews}
            />
        </div>
    )
}

export default ReviewScreen;