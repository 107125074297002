import React, {useEffect, useState} from 'react';
import KaHead from "../head";
import PublicHeader from "../../../components/publicHeader";
import ReviewsSection from "../reviews";
import PartnersSection from "../partners";
import FooterSection from "../footer";
import {getUserByEmail} from "../../../actions/userActions";
import {useAuth} from "../../../utils/auth";
import * as Yup from "yup";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";
import {IconButton, Stack, TextField} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {toast} from "react-toastify";

const ProfileReferalPage = () => {
    const {user} = useAuth();
    const intl = useIntl();

    const [userDetail, setUserDetail] = useState();

    useEffect(() => {
        getUserByEmail(user).then(detail => {
            if (detail) {
                setUserDetail(detail);
            } else {
                throw new Error("No user found");
            }
        }).catch(err => {
            console.error("Error", err);
        });
    }, []);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        lastname: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        country: Yup.string().required(intl.formatMessage({id: "validation.required"})),
        street: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        zip: Yup.string()
            .min(5, intl.formatMessage({id: "validation.tooShort"}))
            .max(6, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        phone: Yup.string()
            .min(9, intl.formatMessage({id: "validation.tooShort"}))
            .max(15, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    //console.log("UserDetail", userDetail);

    return userDetail ? <>
        <KaHead page={"Referálové"}/>
        <PublicHeader
            type={"small"}
            titleComp={<h1 className="ka-title">{`Referálové`}</h1>}></PublicHeader>
        <section className={"profile bg-dark-green"}>
            <ReferalLink user={userDetail}/>
        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </> : <><KaHead page={"Profil"}/></>

}

const RETURN_URL = process.env.REACT_APP_RETURN_URL;

const ReferalLink = ({user}) => {
    if (!user) {
        return <></>;
    }
    return <Container className={"referal-panel"}>
        <Row>
            <Col xs={12}>
                <Stack className={"referal-stack"} direction={"horizontal"}>
                    <label>
                        <FormattedMessage id={"profile.referal"}/>
                    </label>
                    <TextField
                        type="text"
                        disabled={true}
                        value={RETURN_URL + "/?ref=" + user.id}
                    />
                    <IconButton className={"button-green"} edge="end" aria-label="copy" onClick={() => {
                        insertToClipboard(`${RETURN_URL}/?ref=${user.id}`)
                    }
                    }>
                        <CopyAllIcon/>
                        <span>Kopírovat</span>
                    </IconButton>
                </Stack>
            </Col>
        </Row>
    </Container>
}
const insertToClipboard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
        toast.success(`Referal link uložen do schránky`);
    }, () => {
        /* clipboard write failed */
    });
}

export default ProfileReferalPage;