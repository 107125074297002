import {Container, Row} from "react-bootstrap";
import bybitLogo from "../../assets/img/partners/bybit.png";
import bybitLogoGreen from "../../assets/img/partners/bybit-green.png";
import exoLogo from "../../assets/img/partners/exocharts.png";
import exoLogoGreen from "../../assets/img/partners/exocharts-green.png";
import tvLogo from "../../assets/img/partners/tradingview.png";
import tvLogoGreen from "../../assets/img/partners/tradingview-green.png";
import trezorLogo from "../../assets/img/partners/trezor.png";
import trezorLogoGreen from "../../assets/img/partners/trezor-green.png";
import React from "react";
import Image from "../../components/HoverImage";

const PartnersSection = (props) => <section className="partners bg-dark-green">
    <Container>
        <Row className={"partners-row"}>
            <PartnerDetail src={bybitLogo} hoverSrc={bybitLogoGreen} link={"https://partner.bybit.com/b/63398"} features={["partner", "deposit bonus"]}/>
            <PartnerDetail src={exoLogo} hoverSrc={exoLogoGreen} link={"https://exocharts.com/"} features={["partner","měsíc zdarma pro PREMIUM"]}/>
            <PartnerDetail src={tvLogo} hoverSrc={tvLogoGreen} link={"https://www.tradingview.com/?aff_id=120595"} features={["partner", "bonus 15 USDT"]} />
            <PartnerDetail src={trezorLogo} hoverSrc={trezorLogoGreen} link={"https://kryptoanalyzy.cz/products/121"} features={["partner"]}/>
        </Row>
    </Container>
</section>

const PartnerDetail = ({src, hoverSrc, link, features}) => {
    return <div>
        <Image src={src} hoverSrc={hoverSrc} link={link}/>
        <ul>
            {features && features.map((f, i) => {
                return <li key={`link-${i}`}>{f}</li>
            })}
        </ul>
    </div>
}

export default PartnersSection;