import React from 'react';
import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {DASHBOARD, COUPON_LIST, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {getCoupons} from "../../../actions/couponActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'code', headerName: intl({id: "coupon.code"})},
        {field: 'validFrom', headerName: intl({id: "coupon.validFrom"})},
        {field: 'validTo', headerName: intl({id: "coupon.validTo"})},
        {field: 'amount', headerName: intl({id: "coupon.amount"})},
        {field: 'amountType', headerName: intl({id: "coupon.amountType"})},
        {field: 'couponType', headerName: intl({id: "coupon.type"})},
        {field: 'usage', headerName: intl({id: "coupon.usage"})},
        {field: 'usageRemain', headerName: intl({id: "coupon.usageRemain"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.COUPONS.LIST}/${id}`)),
    ];
}

const CouponsScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, COUPON_LIST]);

    const actions = [
        addNewAction(PATH.COUPONS.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'code', sort: 'asc' }}
                getData={getCoupons}
            />
        </div>
    )
}

export default CouponsScreen;