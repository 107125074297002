import React, {useEffect, useState} from 'react';
import KaHead from "../head";
import PublicHeader from "../../../components/publicHeader";
import {Col, Container, Row} from "react-bootstrap";
import ReviewsSection from "../reviews";
import PartnersSection from "../partners";
import FooterSection from "../footer";
import {getUserByEmail, updateUser} from "../../../actions/userActions";
import {useAuth} from "../../../utils/auth";
import {Formik} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {MyCheckbox, MySelect, MyTextInput} from "../../../components/form/components";
import {joinDiscord} from "../../../actions/discordActions";
import {Button} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {COUNTRIES} from "../../../const";

const ProfilePage = () => {
    const {user} = useAuth();
    const intl = useIntl();

    const [refreshed, setRefreshed] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const success = searchParams.get("success");

    const [userDetail, setUserDetail] = useState({country: ""});

    useEffect(() => {
        console.log("Success", success, refreshed);
        /*if (success && success == "Y" && !refreshed) {
            toast.success("Uživatel úspěšně uložen");
            window.history.replaceState("", document.title, window.location.pathname);
            setRefreshed(true);
        }*/
        getUserByEmail(user).then(detail => {
            if (detail) {
                setUserDetail({
                    ...detail, country: detail.countryId
                });
            } else {
                throw new Error("No user found");
            }
        }).catch(err => {
            console.error("Error", err);
        });
    }, []);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        lastName: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        country: Yup.number().required(intl.formatMessage({id: "validation.required"})),
        street: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        zip: Yup.string()
            .min(5, intl.formatMessage({id: "validation.tooShort"}))
            .max(6, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        phone: Yup.string()
            .min(9, intl.formatMessage({id: "validation.tooShort"}))
            .max(15, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    return userDetail ? <>
        <KaHead page={"Profil"}/>
        <PublicHeader
            type={"small"}
            titleComp={<h1
                className="ka-title">{`${userDetail.firstName}`}<span> {`${userDetail.lastName}`}</span>
            </h1>}></PublicHeader>
        <section className={"profile bg-dark-green"}>
            <Formik

                enableReinitialize={true}
                //validateOnChange={true}
                onSubmit={(values) => {
                    updateUser(values).then(res=>{
                        toast.success("Uživatel úspěšně uložen");
                        window.location.href = "/profile?success=Y";
                    }).catch(err=>{
                        console.log("ERR", err);
                        toast.error("Něco se pokazilo");
                    });
                }}
                validationSchema={validationSchema}
                initialValues={userDetail}
            >
                {({values, setFieldValue, submitForm, handleSubmit, errors, isSubmitting}) => (
                    <form onSubmit={handleSubmit} className={"payment-form"}>
                        <FormFields intl={intl} user={user} errors={errors}/>
                        <Container>
                            <Row>
                                <Col xs={12} className={"text-center text-lg-start"}>
                                    <Button className={"button-green"} type={"submit"}>Uložit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                )}
            </Formik>

        </section>
        {/*<ReviewsSection/> */}
        <PartnersSection/>
        <FooterSection/>
    </> : <><KaHead page={"Profil"}/></>

}

const FormFields = ({intl, submitted, errors}) => {
    return <Container className={"profile-form"}>
        <Row>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12} md={6}>
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.firstname"})}
                            name="firstName"
                            type="text"
                            disabled={submitted}
                            placeholder={intl.formatMessage({id: "payment.firstname"})}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.lastname"})}
                            name="lastName"
                            type="text"
                            disabled={submitted}
                            placeholder={intl.formatMessage({id: "payment.lastname"})}
                        />
                    </Col>
                </Row>
                <MyTextInput
                    label={intl.formatMessage({id: "payment.company"})}
                    name="company"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.company"})}
                />
                <MySelect
                    label={intl.formatMessage({id: "payment.country"})}
                    name="country"
                    disabled={submitted}
                    options={COUNTRIES}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.city"})}
                    name="city"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.city"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.street"})}
                    name="street"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.street"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.zip"})}
                    name="zip"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.zip"})}
                />
            </Col>
            <Col xs={12} md={6}>
                <MyTextInput
                    label={intl.formatMessage({id: "payment.email"})}
                    name="email"
                    type="email"
                    disabled={true}
                    placeholder={intl.formatMessage({id: "payment.email"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.phone"})}
                    name="phone"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.phone"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "profile.password"})}
                    name="password"
                    type="password"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "profile.password"})}
                />
                <MyCheckbox
                    name="autoRenewal"
                    label={intl.formatMessage({id: "profile.autoRenewal"})}
                />
            </Col>
        </Row>
    </Container>
}

export default ProfilePage;