const messages = {
    title: 'Kryptoanalyzy',
    sidebarTitle: 'Kryptoanalyzy',
    common: {
        id: "ID",
        created: "Vytvořeno",
        updated: "Upraveno",
        deleted: "Smazáno",
        addNew: "Přidat nový",
        edit: "Upravit",
        detail: "Detail",
        back: "Zpět",
        save: "Uložit",
        fileUpload: "Přesuňte soubor zde nebo klikněte pro výběr souboru",
    },
    login: {
        submit: "Přihlásit se",
        submitReset: "Obnovit heslo",
        email: "Uživatelské jméno (email)",
        password: "Heslo",
        invalidCredentials: "Neplatné údaje",
        passwordReset: "Zapomenuté heslo",
        backToLogin: "Zpět na přihlášení",
    },
    menu: {
        dashboard: 'Nástěnka',
        settings: 'Nastavení',
        courses: 'Kurzy',
        membership: 'Členství',
        referalDiscounts: 'Referal',
        orders: 'Objednávky',
        tmpOrders: 'Objednávky (záloha)',
        products: 'Produkty',
        options: 'Varianty',
        categories: 'Kategorie',
        users: 'Uživatelé',
        reviews: 'Recenze',
        coupons: 'Kupóny',
        gifts: 'Dárky',
        streams: 'Streamy',
        eshop: 'E-Shop',
        logout: 'Odhlásit',
    },
    breadcrumbs: {
        dashboard: 'Nástěnka',
        eshop: 'E-Shop',
        new: 'Nový',
        detail: 'Detail',
        products: "Produkty",
        options: 'Varianty',
        categories: 'Kategorie',
        courses: 'Kurzy',
        coupons: 'Kupóny',
        memberships: 'Členství',
        referalDiscounts: 'Referal',
        users: 'Uživatelé',
        reviews: 'Recenze',
        gifts: 'Dárky',
        streams: 'Streamy',
        orders: 'Objednávky',
        tmpOrders: 'Objednávky (záloha)',
    },
    product: {
        newTitle: 'Nový Produkt',
        detailTitle: 'Detail Produktu',
        name: "Název",
        price: "Cena",
        priceLegacy: "Cena (pro stávající členy)",
        description: "Popis",
        image: "Obrázek",
        gallery: "Galerie",
        type: 'Typ',
        membership: 'Členství',
        virtual: 'Virtuální',
        discountExcluded: 'Bez slev',
        variant: {
            title: 'Varianty Produktu',
            missingOption: 'Vyplňte alespoň jeden parametr!',
        },
        options: {
            title: 'Parametry Produktu'
        },
        discount: {
            title: 'Sleva',
        }
    },
    referalDiscount: {
        newTitle: 'Nová úroveň referalu',
        detailTitle: 'Detail úrovně referalu',
        level: "Úroveň",
        discountPercent: 'Sleva %',
        discountAmount: 'Sleva Kč',
    },
    option: {
        newTitle: 'Nová Varianta',
        detailTitle: 'Detail Varianty',
        name: 'Název',
        values: {
            title: 'Hodnoty',
            value: 'Hodnota',
        },
    },
    category: {
        name: 'Název'
    },
    course: {
        newTitle: 'Nový kurz',
        detailTitle: 'Detail kurzu',
        name: 'Název',
        order: 'Pořadí',
        description: 'Popis',
        link: 'Link',
        freeLink: 'Ukázka link',
        thumbnail: 'Náhledový obrázek',
        membership: 'Členství',
        detail: {
            order: {
                title: 'Pořadí'
            },
        }
    },
    review: {
        newTitle: 'Nová recenze',
        detailTitle: 'Detail recenze',
        name: 'Jméno',
        text: 'Text',
    },
    membership: {
        newTitle: 'Nové členství',
        detailTitle: 'Detail členství',
        name: 'Název',
        discordRole: 'Discord Role',
        discountPercent: 'Sleva %',
        discountAmount: 'Sleva Kč',
        description: 'Popis',
    },
    coupon: {
        newTitle: 'Nový kupón',
        detailTitle: 'Detail kupónu',
        code: 'Kód',
        validFrom: 'Platný od',
        validTo: 'Platný do',
        amount: 'Hodnota',
        amountType: 'Typ',
        usage: "Počet použití",
        usageRemain: "Počet uplatněných",
        type: 'Typ',
    },
    user: {
        newTitle: 'Nový uživatel',
        detailTitle: 'Detail uživatele',
        firstname: 'Jméno',
        firstName: 'Jméno',
        lastname: 'Příjmení',
        lastName: 'Příjmení',
        email: 'Email',
        sendInvitationEmail: 'Poslat uvítací email',
        leaveDiscord: 'Opustit Discord',
        discordId: 'Discord ID',
        discordNickname: 'Discord Nickname',
        showAll: 'Zobrazit vše',
        showActiveOnly: 'Zobrazit aktivní',
        autoRenewal: 'Automatické prodlužování'
    },
    gift: {
        newTitle: 'Nový Dárek',
        detailTitle: 'Detail Dárku',
        name: 'Název',
        image: 'Obrázek'
    },
    stream: {
        newTitle: 'Nový Stream',
        detailTitle: 'Detail Streamu',
        name: 'Název',
        url: 'URL',
        tags: 'Tagy',
    },
    order: {
        detailTitle: 'Detail Objednávky',
        orderId: '# Objednávky',
        user: 'Uživatel',
        status: 'Status',
        email: 'Email',
        shipping: 'Doprava',
        gift: 'Dárek',
        coupon: 'Kupón',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        company: "Název firmy",
        country: "Země / Region",
        street: "Ulice a č.p.",
        city: "Město",
        zip: "PSČ",
        phone: "Telefon",
        total: "Celkem",
        notes: "Poznámky",
        numberOfProducts: "Počet produktů",
        processPayment: "Zprocesovat platbu",
        paymentProcessed: "Platba zpracována",
        paymentProcessingFailed: "Zpracování platby selhalo",
        userDetail: 'Detail uživatele',
        downloadInvoices: 'Stáhnout faktury',
        downloadInvoice: 'Stáhnout fakturu',
        convertToOrder: 'Převést na objednávku',
    },
    validation: {
        tooShort: "Příliš krátké",
        tooLong: "Příliš dlouhé",
        required: "Povinné",
        passwordEquals: "Hesla musí být stejná"
    },
    homepage: {
        footer: {
            text1: "Jakékoliv analýzy, novinky, cenové odhady, vstupy, cenová rozhraní nebo jiné informace sdílené na této webové stránce, jsou poskytovány jako obecný komentář a sdíleny prostřednictvím webových stránek www.kryptoanalyzy.cz a aplikace Discord s množstvím klientů, proto se nedají považovat za investiční poradenství nebo investiční výzkum či rady. Nejsme obchodní ani finanční poradci.",
            text2: "Obchodování na kryptoměnách, forexu, futures marketech, opcích, akciích nebo jakýkoliv dalši druh marketu Trading je riskantní a NEJSOU žádné garantované způsoby jak profitovat v tradu. Pokud se budete řídit jakýmikoli nastaveními, tak to děláte na vlastní riziko a berete na sebe plnou zodpovědnost. Vždy zde je a bude určitá možnost, že o své vklady můžete přijít. Naše webové stránky a informace, které zde sdílíme by se neměly slepě následovat a nahrazovat tak nezávislý odborný výzkum provedený před nutnou investicí či obchodním rozhodnutím. V žádném případě nejsme zodpovědni za jakoukoliv ztrátu na Vašem účtu na základě našich produktů. Pro skutečné finanční rady kontaktujte svého finančního poradce.",
            text3: "Rozdílové smlouvy jsou komplexní nástroje a v důsledku použití finanční páky jsou spojeny s vysokým rizikem rychlého vzniku finanční ztráty. U 74 až 89% účtů retailových investorů došlo při obchodování s rozdílovými smlouvami ke vzniku ztráty. Měli byste zvážit, zda rozumíte tomu, jak rozdílové smlouvy fungují, a zda si můžete dovolit vysoké riziko ztráty svých finančních prostředů.",
            links: {
                gdpr: "GDPR",
                termsConditions: "Obchodní podmínky"
            },
            company: {
                name: "K & T analysis s.r.o",
                icTitle: "IČ",
                ic: "11863561",
                address: "Adresa",
                city: "Praha - Vinohrady",
                street: "Korunní 2569/108",
                zip: "PSČ 101 00",
                emailTitle: "email",
                phoneTitle: "tel",
                email: "info@kryptoanalyzy.cz"
            }
        },
        krystof: {
            description: "Mám za sebou několik let zkušeností s obchodováním. Už jako sedmnáctiletý jsem objevil svět obchodování a okamžitě jsem věděl, že to je má jediná cesta vpřed. Plán B pro mě nikdy neexistoval. Mé ambice byly jasné a neúnavně jsem se jich držel. Na své cestě jsem potkal inspirativní jednotlivce, kteří mě vždy vedli správným směrem. Tito lidé jsou nyní součástí naší skupiny a společně s nimi ti předáme naše cenné zkušenosti. Naše skupina není jen komunitou, je to pro mě rodina. Místo, kde si vzájemně pomáháme a sdílíme své znalosti."
        },
        zinda: {
            description: "Do světa obchodování jsem se dostal náhodou díky reklamě na Facebooku. Zaujalo mě, jak lze relativně snadno, ale přesto náročně, vydělávat peníze. Rozhodl jsem se více se do této problematiky ponořit, ale během prvních tří měsíců jsem se stále propadal do ztrát. V čtvrtém měsíci svého zájmu o kryptoměny jsem narazil na skupinu Krypto Analýz, což mi otevřelo nový směr. Od té doby věnuji veškerý volný čas učení, a to v průměru deset hodin denně. Během víkendů jsem skoro nepřetržitě zasazen do analýzy grafů a dalšího studia. I přes spoustu chyb, které jsem udělal, jsem z nich získal cenné lekce, které se snažím s nadšením předávat dál. Nemám striktně vymezenou strategii ani pevně daný druh technické analýzy, kterou uplatňuji. Při vstupu do obchodu či při formulaci delších předpovědí se opírám o celou škálu dostupných nástrojů, abych zvýšil přesnost svých rozhodnutí, a přesto si stále udržuji důkladně zpracované znalosti v hlavě.",
        },
        sylvestr: {
            description: "Moje cesta tradingem byla na začátku trnitá a klikatá asi jako většiny z nás. Investoval jsem mnoho času a nemálo prostředků do vzdělání. Prošel jsem několik drahých kurzů, na jejichž konci jsem nevěděl o moc víc než na začátku. Navoněná bída. Už jsem nad tradingem téměř zlomil hůl…\n" +
                "Ale jsem pořád tady. Začal jsem hledat informace sám, zkoušel jsem různé cesty jak na to. Studoval jsem graf, využíval kvalitních zdrojů, především ze zahraničí. Čím dál více jsem viděl pod povrch. A proto mi začalo hodně vadit, jak to chodí v různých skupinách. Nelíbily se mi líbivý slogany o rychlém a jednoduchém zbohatnutí bez obsahu.\n" +
                "Sám už jsem si prošel tuhle cestu a věděl jsem, že takhle to nefunguje.\n" +
                "A tehdy jsme se potkali s Kryštofem, kterej to viděl stejně.\n" +
                "Hlavním důvodem proč jsem se přidal ke KA, byla změna. Pojďme to udělat jinak. Pojďme lidi opravdu vzdělávat, předávat jim kvalitní informace a naše zkušenosti. Nevytvářet zase jen nějakou signální skupinu, která bude profitovat primárně z lidí. Líbí se mi, že naši členové vidí ten rozdíl. Jsme jako jedna velká rodina. Super je taky to, že část peněz jde vždy zpět do skupiny. Ať už formou soutěží, webu, srazu atd…"
        },
        zabijak: {
            description: "Přestože nejsem ve skupině tolik vidět, zastávám pozici CEO této společnosti a mezi mé odpovědnosti patří vedení společnosti, marketingová strategie, ale i poskytování technické podpory. Jsem pro Vás kontaktní osoba v případě, že narazíte na problémy či dotazy. V rámci mé role se také aktivně zapojuji do sledování grafu, trendů a celosvětových projektů. Proto se na mě můžete obracet i v tomto odvětví."
        }
    },
    payment: {
        firstname: "Křestní jméno",
        lastname: "Příjmení",
        company: "Název firmy",
        country: "Země / Region",
        street: "Ulice a č.p.",
        city: "Město",
        zip: "PSČ",
        phone: "Telefon",
        email: "E-mailová adresa",
        password: "Vytvořit heslo k účtu",
        passwordConfirm: "Heslo pro potvrzení",
        differentDelivery: "Doručit na jinou adresu?",
        notes: "Poznámky k objednávce",
        paymentType: "Způsob platby",
        referal: "Referal",
    },
    profile: {
        referal: "Referal link",
        password: "Změnit heslo",
        autoRenewal: "Automatické prodloužení členství"
    },
    changePassword: {
        password: "Heslo",
        passwordConfirm: "Heslo pro potvrzení",
        submit: "Změnit heslo"
    }
};
export default messages;