import React, {useCallback, useEffect, useState} from 'react';
import {useAuth, useUser} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";

import KaHead from "./head";
import {getPublicProducts} from "../../actions/publicActions";
import {getProducts, getProductsForPublic} from "../../actions/productActions";
import {useCart} from "react-use-cart";
import {PRODUCT_TYPE} from "../../const";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Button} from "@mui/material";
import {Oval} from "react-loader-spinner";
import {ToastWithLink} from "../../utils/utils";

const ProductsPage = (props) => {
    const auth = useAuth();
    const intl = useIntl();
    const navigate = useNavigate();
    const user = useUser();

    const {addItem, items, inCart, removeItem} = useCart();

    const [products, setProducts] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        setLoading(true);
        try {
            if (user) {
                getProductsForPublic([{field: "name", sort: "asc"}]).then((res) => {
                    setProducts(res);
                });
            } else {
                getPublicProducts().then((res) => {
                    setProducts(res);
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [user]);

    const onAddToCart = (item) => {
        if (item.productType == PRODUCT_TYPE.SIMPLE) {
            const withoutVariants = {...item};
            delete withoutVariants.variants;
            addItem({...item.variants[0], product: withoutVariants});
        } else {

        }
        //toast.success(`Produkt ${item.name} přidán do košíku`);
        toast.success(<ToastWithLink onClick={()=>navigate("/cart")} text={`Produkt ${item.name} přidán do košíku`} />);
    }

    const removeFromCart = (item) => {
        removeItem(item);
        toast.error(`Product removed to Cart`);
    }

    return (
        <>
            <KaHead page={"Produkty"}/>
            <PublicHeader titleComp={<h1 className="ka-title">Profituj <span>ve stylu</span></h1>}>
            </PublicHeader>
            <section className="bg-dark-green">
                <Container>
                    <Row className={"product-grid"}>
                        <ProductList loading={loading} products={products} onAddToCart={onAddToCart} inCart={inCart} removeFromCart={removeFromCart} navigate={navigate} user={user} />
                    </Row>
                </Container>
            </section>
            {/*<ReviewsSection/>*/}
            <PartnersSection/>
            <FooterSection />
        </>
    )
}

const ProductList = (props) => {
    const { products = [], user, loading } = props;
    return products.map(p=>{
        return <Product loading={loading} key={p.id} product={p} onAddToCart={props.onAddToCart} inCart={props.inCart} removeFromCart={props.removeFromCart} navigate={props.navigate} user={user} />
    })
}

const LoadingIcon = () => <Oval
    height={30}
    width={30}
    color="white"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#dddddd"
    strokeWidth={2}
    strokeWidthSecondary={2}
/>

const Product = (props) => {
    const { onAddToCart, product, inCart, removeFromCart, navigate, user, loading } = props;
    const {id, name, price, productType, image} = product;
    const productInCart = inCart(id);
    const navigateToDetail = useCallback((id)=>{
        navigate(`/products/${id}`);
    }, [id]);
    return <div className={"product-item"}>
        <div className={"product-image"}>
            {image && <a href={`/products/${id}`}><img src={process.env.PUBLIC_URL + "/upload/" + image.fullPath} loading={"lazy"} /></a>}
        </div>
        <div className={"product-name"}>
            <p><a href={`/products/${id}`}>{name}</a></p>
        </div>
        <div className={"product-price"}>
            {loading ? <LoadingIcon/> : <p>{price && price.toFixed(0)} Kč</p>}
        </div>
        <div className={"product-button"}>
            { //TODO removeFrom Cart in case of VARIANTS
                productInCart ? <Button disabled={loading} endIcon={<ShoppingCartIcon/>} onClick={() => removeFromCart(id)}>
                        Odebrat z košíku
                    </Button> :
                    productType === PRODUCT_TYPE.SIMPLE ? <Button disabled={loading} endIcon={<ShoppingCartIcon/>} onClick={() => onAddToCart(product)}>
                            Přidat do košíku
                        </Button> :
                        <button disabled={loading} onClick={() => navigateToDetail(id)}>Výběr možností</button>
            }

        </div>
    </div>
}

export default ProductsPage;