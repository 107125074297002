import React from 'react';

import {getProducts} from "../../../actions/productActions";
import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {PRODUCT_LIST, DASHBOARD, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "product.name"})},
        {field: 'price', headerName: intl({id: "product.price"})},
        {field: 'priceLegacy', headerName: intl({id: "product.priceLegacy"})},
        {field: 'productType', headerName: intl({id: "product.type"})},
       /* {field: 'company.name', headerName: intl({id: "banner.company"}),
        valueGetter: (params)=>{
            return params.row.company?.name;
        }},*/
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.PRODUCTS.LIST}/${id}`)),
    ];
}

const OrdersScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, PRODUCT_LIST]);

    const actions = [
        addNewAction(PATH.PRODUCTS.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'name', sort: 'asc' }}
                getData={getProducts}
            />
        </div>
    )
}

export default OrdersScreen;