import React from 'react';

import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {TMP_ORDER_LIST, DASHBOARD, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {toast} from "react-toastify";
import {getTmpOrders} from "../../../actions/tmpOrderActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'user', width: 300, headerName: intl({id: "order.user"}), valueGetter: (params)=>{
                return params.row.user.email;
            }},
        {field: 'numberOfProducts', headerName: intl({id: "order.numberOfProducts"}), sortable: false, disableColumnMenu: true,},
        {field: 'total', headerName: intl({id: "order.total"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.TMP_ORDERS.LIST}/${id}`)),
    ];
}

const TmpOrdersScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, TMP_ORDER_LIST]);

    const actions = [

    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'id', sort: 'desc' }}
                getData={getTmpOrders}
            />
        </div>
    )
}

export default TmpOrdersScreen;