import jscookie from 'js-cookie';
import {post} from "./api";
import {Base64} from "js-base64";
import {AUTH_API_URL} from "../const";

export const COOKIE_KEY = "kryptoAnalyzyToken"
export const COOKIE_REFRESH_KEY = "kryptoAnalyzyRefreshToken"

const LOGIN_URL = AUTH_API_URL + "/api/auth/login"
const REFRESH_URL = AUTH_API_URL + "/api/auth/refreshtoken"

export const BASIC_AUTH = `Basic ${Base64.encode("krypto:krypto")}`;

const COOKIES_SECURED = false;

const authenticated = (authProvider, response) => {
    authProvider.isAuthenticated = true;
    jscookie.set(COOKIE_KEY, response.token, {expires: 7, secure: COOKIES_SECURED});
    jscookie.set(COOKIE_REFRESH_KEY, response.refreshToken, {expires: 7, secure: COOKIES_SECURED});
}

const notAuthenticated = (authProvider) => {
    authProvider.isAuthenticated = false;
    jscookie.remove(COOKIE_KEY);
}

export const authProvider = {
    isAuthenticated: false,
    async signin(email, password) {
        const res = await post(LOGIN_URL,{email, password},
            {"Authorization": BASIC_AUTH})
            .then(response=>{
                if (response.token) {
                    authenticated(authProvider, response);
                    return response;
                } else {
                    notAuthenticated(authProvider);
                    return null;
                }
            })
            .catch(err=>{
                console.warn("Error", err);
                notAuthenticated(authProvider)
                return null;
            });
        return res;
    },
    async refresh() {
        const response = await post(REFRESH_URL,{refreshToken: jscookie.get(COOKIE_REFRESH_KEY)},
            {"Authorization": BASIC_AUTH}); // TODO
        if (response && response.token) {
            authProvider.isAuthenticated = true;
            jscookie.set(COOKIE_KEY, response.token, {expires: 7, secure: COOKIES_SECURED});
            jscookie.set(COOKIE_REFRESH_KEY, response.refreshToken, {expires: 7, secure: COOKIES_SECURED});
            return response.token;
        } else {
            authProvider.isAuthenticated = false;
            jscookie.remove(COOKIE_KEY);
            jscookie.remove(COOKIE_REFRESH_KEY);
            return null;
        }
    },
    signout(callback) {
        authProvider.isAuthenticated = false;
        jscookie.remove(COOKIE_KEY);
        jscookie.remove(COOKIE_REFRESH_KEY);
        callback();
    }
};