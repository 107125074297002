export const BASE_API_URL = process.env.REACT_APP_FE_API;
export const PAYMENT_API_URL = process.env.REACT_APP_PAYMENT_API;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API;

export const KA_ADMIN = "/ka-admin"

export const PATH = {
    DASHBOARD: KA_ADMIN,
    ORDERS: {
        LIST: `${KA_ADMIN}/eshop/orders`,
        NEW: `${KA_ADMIN}/eshop/orders/new`,
        ID: `${KA_ADMIN}/eshop/orders/:id`,
    },
    TMP_ORDERS: {
        LIST: `${KA_ADMIN}/eshop/tmpOrders`,
        ID: `${KA_ADMIN}/eshop/tmpOrders/:id`,
    },
    PRODUCTS: {
        LIST: `${KA_ADMIN}/eshop/products`,
        NEW: `${KA_ADMIN}/eshop/products/new`,
        ID: `${KA_ADMIN}/eshop/products/:id`,
    },
    OPTIONS: {
        LIST: `${KA_ADMIN}/eshop/options`,
        NEW: `${KA_ADMIN}/eshop/options/new`,
        ID: `${KA_ADMIN}/eshop/options/:id`,
    },
    COUPONS: {
        LIST: `${KA_ADMIN}/eshop/coupons`,
        NEW: `${KA_ADMIN}/eshop/coupons/new`,
        ID: `${KA_ADMIN}/eshop/coupons/:id`,
    },
    CATEGORIES: {
        LIST: `${KA_ADMIN}/eshop/categories`,
    },
    COURSES: {
        LIST: `${KA_ADMIN}/courses`,
        NEW: `${KA_ADMIN}/courses/new`,
        ID: `${KA_ADMIN}/courses/:id`,
    },
    STREAMS: {
        LIST: `${KA_ADMIN}/streams`,
        NEW: `${KA_ADMIN}/streams/new`,
        ID: `${KA_ADMIN}/streams/:id`,
    },
    MEMBERSHIPS: {
        LIST: `${KA_ADMIN}/memberships`,
        NEW: `${KA_ADMIN}/memberships/new`,
        ID: `${KA_ADMIN}/memberships/:id`,
    },
    REFERAL_DISCOUNT: {
        LIST: `${KA_ADMIN}/referalDiscount`,
        NEW: `${KA_ADMIN}/referalDiscount/new`,
        ID: `${KA_ADMIN}/referalDiscount/:id`,
    },
    REVIEW: {
        LIST: `${KA_ADMIN}/reviews`,
        NEW: `${KA_ADMIN}/reviews/new`,
        ID: `${KA_ADMIN}/reviews/:id`,
    },
    USERS: {
        LIST: `${KA_ADMIN}/users`,
        NEW: `${KA_ADMIN}/users/new`,
        ID: `${KA_ADMIN}/users/:id`,
    },
    GIFTS: {
        LIST: `${KA_ADMIN}/eshop/gifts`,
        NEW: `${KA_ADMIN}/eshop/gifts/new`,
        ID: `${KA_ADMIN}/eshop/gifts/:id`,
    },
}

export const PRODUCT_TYPE = {
    SIMPLE: "SIMPLE",
    VARIANTS: "VARIANTS",
}

export const EMAIL_REGEX = `/^(([^<>()\[\]\\.,;:\s@"]+(.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/`;

export const COUNTRIES = [
    {id: 1, name: "Česká republika"},
    {id: 2, name: "Slovenská republika"},
    {id: 3, name: "Německo"},
    {id: 4, name: "Slovinsko"},
];

export const SHIPPING_PRICE = 89;