import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {BASE_API_URL} from "../const";

const BASE_URL = `${BASE_API_URL}/api/coupon`;

export const getCoupons = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const createCoupon = (values) => {
    return createEntity(BASE_URL, values);
}

export const updateCoupon = (values, id) => {
    return updateEntity(BASE_URL, values, id);
}

export const getCouponById = (id) => {
    return getById(BASE_URL, id);
}