import {Formik, Field} from 'formik';
import {
    Button, Checkbox,
    FormControl, FormControlLabel, FormGroup,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {MyCheckbox, MyFileUpload, MySelect, MyTextArea, MyTextInput} from "../../../components/form/components";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../../components/actionPanel";
import {createProduct, getProductById, updateProduct} from "../../../actions/productActions";
import {PATH, PRODUCT_TYPE} from "../../../const";
import TitledForm from "../../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, PRODUCT_LIST, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {isValidId} from "../../../utils/utils";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {getOptions} from "../../../actions/optionActions";
import {v4 as uuidv4} from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import {getCategories} from "../../../actions/categoryActions";
import {getMemberships} from "../../../actions/membershipsActions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, PRODUCT_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [options, setOptions] = useState();
    const [categories, setCategories] = useState();
    const [memberships, setMemberships] = useState([]);
    const [data, setData] = useState({
        name: "",
        description: "",
        price: "",
        priceLegacy: "",
        productType: PRODUCT_TYPE.SIMPLE,
        options: [],
        variants: [],
        categories: [],
        membership: null,
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateProduct(values, id)
                .then(response => {
                    navigate(PATH.PRODUCTS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createProduct(values)
                .then(response => {
                    navigate(PATH.PRODUCTS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const productSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        getOptions([{field: "name", sort: "asc"}])
            .then(res => setOptions(res.data));
        getCategories([{field: "name", sort: "asc"}])
            .then(res => setCategories(res.data));
        getMemberships([{field: "name", sort: "asc"}])
            .then(res => setMemberships(res.data));
    }, []);

    const initData = useCallback(data => {
        const newData = {...data};
        if (data.image) {
            const img = data.image;
            newData.image = {id: img.id, name: img.filename, preview: `${process.env.PUBLIC_URL + "/upload/" + img.fullPath}`};
        }
        if (data.gallery) {
            newData.gallery = data.gallery.map(g=>({id: g.id, name: g.filename, preview: `${process.env.PUBLIC_URL + "/upload/" + g.fullPath}`}));
        }
        setData(newData);
    }, []);

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getProductById(id)
                .then(res => {
                    if (mounted) {
                        initData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.PRODUCTS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={productSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ValueLogger values={values}/>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "product.detailTitle" : "product.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MySelect
                                        label={intl.formatMessage({id: "product.type"})}
                                        name="productType"
                                        options={[{id: 'SIMPLE', name: 'Simple Product'}, {
                                            id: 'VARIANTS',
                                            name: 'Product with Variants'
                                        }]}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "product.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "product.name"})}
                                    />
                                    {/*<MyTextArea
                                        label={intl.formatMessage({id: "product.description"})}
                                        name="description"
                                        placeholder={intl.formatMessage({id: "product.description"})}
                                    />*/}
                                    <MyTextInput
                                        label={intl.formatMessage({id: "product.price"})}
                                        name="price"
                                        type="number"
                                        placeholder={intl.formatMessage({id: "product.price"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "product.priceLegacy"})}
                                        name="priceLegacy"
                                        type="number"
                                        placeholder={intl.formatMessage({id: "product.priceLegacy"})}
                                    />
                                    <MySelect
                                        label={intl.formatMessage({id: "product.membership"})}
                                        name="membership"
                                        options={[{id: '', name: "Žádné"}, ...memberships.map(m=>({id: m.id, name: m.name}))]}
                                    />
                                    <MyCheckbox
                                        name="virtual"
                                        label={intl.formatMessage({id: "product.virtual"})}
                                    />
                                    <MyCheckbox
                                        name="discountExcluded"
                                        label={intl.formatMessage({id: "product.discountExcluded"})}
                                    />
                                </Stack>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyFileUpload
                                        label={intl.formatMessage({id: "product.image"})}
                                        name="image"
                                        multiple={false}
                                    />
                                    <MyFileUpload
                                        label={intl.formatMessage({id: "product.gallery"})}
                                        name="gallery"
                                        multiple={true}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <label><FormattedMessage id={"product.description"} /></label>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={values.description || "<p></p>"}
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        //console.log( { event, editor, data } );
                                        setFieldValue("description", data);
                                    } }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup className={"checkbox-inline"}>
                                    {categories && categories.map((c)=>{
                                        return <FormControlLabel key={`category-${c.id}`}
                                            control={
                                                <Checkbox checked={values.categories.includes(c.id)} onChange={() => {
                                                    const current = values.categories;
                                                    if (current.includes(c.id)) {
                                                        setFieldValue("categories", current.filter(cur=>cur != c.id));
                                                    } else {
                                                        setFieldValue("categories", [...current, c.id]);
                                                    }
                                                }} name={c.name} />
                                            }
                                            label={c.name}
                                        />
                                    })}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {values.productType == 'VARIANTS' &&
                                <Accordion className={"ka-accordion"}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><FormattedMessage
                                            id={"product.variant.title"}/></Accordion.Header>
                                        <Accordion.Body>
                                            <VariantDetail values={values} intl={intl} setFieldValue={setFieldValue}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FormattedMessage
                                            id={"product.options.title"}/></Accordion.Header>
                                        <Accordion.Body>
                                            <OptionDetail allOptions={options} intl={intl}
                                                          options={values.options || []} setFieldValue={setFieldValue}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FormattedMessage
                                            id={"product.discount.title"}/></Accordion.Header>
                                        <Accordion.Body>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            }
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

const ValueLogger = ({values}) => {
    console.log(values);
    return null;
}

const OptionDetail = ({allOptions, intl, options, setFieldValue}) => {
    const [value, setValue] = useState();
    const [filteredOptions, setFilteredOptions] = useState([]);
    const label = intl.formatMessage({id: "option.values.value"});

    useEffect(() => {
        const filter = allOptions.filter(ao => options.findIndex(o => o.optionId == ao.id) < 0);
        setFilteredOptions(filter);
    }, [options]);

    return <Stack spacing={10}>
        <Stack direction={"row"}>
            <FormControl fullWidth>
                <InputLabel id={"option_value_id"}>{label}</InputLabel>
                <Select
                    labelId={"option_value_id"}
                    value={value}
                    label={label}
                    onChange={(v) => setValue(v.target.value)}
                >
                    {filteredOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button color={"success"} variant={"contained"} onClick={() => {
                if (!value) {
                    return;
                }
                const current = options || [];
                const selected = filteredOptions.find(o => o.id == value);
                setFieldValue("options", [...current, {
                    name: selected.name,
                    id: uuidv4(),
                    optionId: selected.id,
                    values: selected.values
                }]);
                setValue(null);
            }
            }><FormattedMessage id={"common.addNew"}/></Button>
        </Stack>
        <List dense={false} className={"ka-list"}>
            {options.map(v => {
                return <ListItem
                    key={v.id}
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={() => {
                                const afterRemove = options.filter(o => o.id != v.id);
                                setFieldValue("options", afterRemove);
                            }}/>
                        </IconButton>
                    }
                >
                    <ListItemText
                        primary={v.name}
                    />
                </ListItem>
            })}
        </List>
    </Stack>
}

const VariantDetail = ({values, setFieldValue, intl}) => {
    const {options, variants} = values;
    const [newVariant, setNewVariant] = useState({});

    const removeItem = useCallback((index, values) => {
        const filtered = [...values.variants];
        filtered.splice(index, 1)
        setFieldValue("variants", filtered);
    }, []);

    if (!options.length) {
        return <div><FormattedMessage id={"product.variant.missingOption"}/></div>
    }

    return <Stack spacing={10}>
        <div className={"form-stack-horizontal"}>
            <Stack direction={"row"} spacing={5}>
                {options.map(o => {
                    return <FormControl key={o.optionId} fullWidth>
                        <InputLabel id={`option_value_id_${o.optionId}`}>{o.name}</InputLabel>
                        <Select
                            labelId={`option_value_id_${o.optionId}`}
                            value={newVariant[o.optionId]}
                            label={o.name}
                            onChange={(v) => {
                                const value = o.values.find(oo => oo.id == v.target.value);
                                setNewVariant({...newVariant, [o.optionId]: value});
                            }}
                        >
                            {o.values.map((v) => (
                                <MenuItem key={v.id} value={v.id}>
                                    {v.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                })}
                <Button color={"success"} variant={"contained"} onClick={() => {
                    let valid = true;
                    const selected = Object.keys(newVariant).length;
                    for (const [_, val] of Object.entries(newVariant)) {
                        if (!val) {
                            valid = false;
                        }
                    }
                    if (!valid || selected != options.length) {
                        return;
                    }
                    const current = variants || [];
                    // TODO check combinations
                    const optionValues = [];
                    Object.entries(newVariant).forEach(entry => {
                        const [key, value] = entry;
                        optionValues.push({id: uuidv4(), optionId: key, valueId: value.id, valueName: value.value});
                    });
                    setFieldValue("variants", [...current, {id: uuidv4(), optionValues: optionValues, price: values.price}]);
                    setNewVariant({});
                }
                }><FormattedMessage id={"common.addNew"}/></Button>
            </Stack>

        </div>
        <Accordion className={"ka-accordion"} defaultActiveKey="0">
            {(variants || []).map((v, index) =>
                <Accordion.Item eventKey={index}>
                    <Accordion.Header>{v.optionValues.sort((a,b) => a.optionId - b.optionId).map(ov => ov.valueName).join(" - ")}</Accordion.Header>
                    <Accordion.Body>
                        <VariantDetailSingle variant={v} intl={intl} setFieldValue={setFieldValue} index={index} onDelete={(index) => removeItem(index, values)} />
                    </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    </Stack>
}

const VariantDetailSingle = ({variant, intl, setFieldValue, index, onDelete}) => {
    return <div className={"product-variant-single"}>
        {/*<TextField
            label={intl.formatMessage({id: "product.price"})}
            type="number"
            name="price"
            value={variant.price}
            placeholder={intl.formatMessage({id: "product.price"})}
            onChange={e => setFieldValue(`variants[${index}].price`, e.target.value)}
        />*/}
        <TextField
            label={intl.formatMessage({id: "product.priceLegacy"})}
            type="number"
            name="priceLegacy"
            value={variant.priceLegacy}
            placeholder={intl.formatMessage({id: "product.priceLegacy"})}
            onChange={e => setFieldValue(`variants[${index}].priceLegacy`, e.target.value)}
        />
        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(index)}>
            <DeleteIcon/>
        </IconButton>
    </div>
}

export default ProductDetail;