import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import logo from "../../assets/img/logo_pruhledny.png";
import {FormattedMessage, useIntl} from "react-intl";
import {getFileSrc} from "../../utils/utils";

const FooterSection = (props) => {
    const intl = useIntl();
    return <footer className="bg-dark-black">
        <Container fluid>
            <Row>
                <Col>
                    <img src={logo} loading={"lazy"}/>
                    <div>
                        <p><a href={getFileSrc("Obchodni-podminky.docx")}><FormattedMessage
                            id={"homepage.footer.links.termsConditions"}/></a></p>
                    </div>
                    <div>
                        <p><a href={getFileSrc("GDPR.docx")}><FormattedMessage id={"homepage.footer.links.gdpr"}/></a>
                        </p>
                    </div>
                </Col>
                <Col md={7} className={"footer-disclaimer"}>
                    <p><FormattedMessage id={"homepage.footer.text1"}/></p>
                    <p><FormattedMessage id={"homepage.footer.text2"}/></p>
                    <p><FormattedMessage id={"homepage.footer.text3"}/></p>
                </Col>
                <Col className={"footer-address"}>
                    <div className={"footer-address-wrapper"}>
                        <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.name"})}/>
                        <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.ic"})}
                                    title={intl.formatMessage({id: "homepage.footer.company.icTitle"})}/>
                    </div>
                    <div className={"footer-address-wrapper"}>
                        <FooterInfo value={""} title={intl.formatMessage({id: "homepage.footer.company.address"})}/>
                        <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.city"})}/>
                        <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.street"})}/>
                        <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.zip"})}/>
                    </div>
                    <FooterInfo value={intl.formatMessage({id: "homepage.footer.company.email"})}
                                href={"mailto:info@kryptoanalyzy.cz"}
                                title={intl.formatMessage({id: "homepage.footer.company.emailTitle"})}/>
                    <FooterInfo value={"+420 605 409 256"}
                                href={"tel:605409256"}
                                title={intl.formatMessage({id: "homepage.footer.company.phoneTitle"})}/>
                </Col>
            </Row>
        </Container>
    </footer>
}

const FooterInfo = ({value, title, href}) => {
    if (title) {
        if (href) {
            return <p className={"cursive"}>{title}: <a href={href}>{value}</a></p>
        }
        return <p className={"cursive"}>{title}: {value}</p>
    } else if (href) {
        return <a className={"cursive"} href={href}>{value}</a>
    } else {
        return <p className={"cursive"}>{value}</p>
    }
}

export default FooterSection;