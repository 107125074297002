import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MyTextArea, MyTextInput} from "../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, REVIEW_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {createReview, getReviewById, updateReview} from "../../actions/reviewsActions";


const ReviewDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, REVIEW_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        text: "",
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateReview(values, id)
                .then(response => {
                    navigate(PATH.REVIEW.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createReview(values)
                .then(response => {
                    navigate(PATH.REVIEW.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const reviewSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, intl.formatMessage({id: "validation.tooShort"}))
            .max(100, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        text: Yup.string()
            .min(1, intl.formatMessage({id: "validation.tooShort"}))
            .max(2000, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getReviewById(id)
                .then(res => {
                    if (mounted) {
                        setData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.REVIEW.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={reviewSchema}
            initialValues={data}
        >
            {({values}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "review.detailTitle" : "review.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "review.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "review.name"})}
                                    />
                                    <MyTextArea
                                        label={intl.formatMessage({id: "review.text"})}
                                        name="text"
                                        placeholder={intl.formatMessage({id: "review.text"})}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

export default ReviewDetail;