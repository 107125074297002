import {BASE_API_URL} from "../const";
import {createEntity, getAllFiltered, updateEntity} from "./commonActions";
import {get} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/referalDiscount`;

export const getReferalDiscounts = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const createReferalDiscount = (values) => {
    const formData = courseBody(values);
    return createEntity(BASE_URL, formData);
}

const courseBody = (values) => {
    return {
        level: values.level,
        discounts: values.discounts,
    };
}

export const updateReferalDiscount = (values, id) => {
    const formData = courseBody(values);
    return updateEntity(BASE_URL, formData, id)
}

export const getReferalDiscountById = (id) => {
    return get(`${BASE_URL}/${id}`);
}