import React, {useCallback, useEffect, useState} from 'react';

import {useCart} from "react-use-cart";
import KaHead from "./head";
import PublicHeader from "../../components/publicHeader";
import {Col, Container, Row, Stack} from "react-bootstrap";
import ReviewsSection from "./reviews";
import PartnersSection from "./partners";
import FooterSection from "./footer";
import {computeCouponPrice, formatCurrency, getFileSrc} from "../../utils/utils";
import {Button, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PRODUCT_TYPE, SHIPPING_PRICE} from "../../const";
import {checkCoupon, getPublicGifts} from "../../actions/publicActions";
import {toast} from "react-toastify";
import {getOptions} from "../../actions/optionActions";
import {useAuth} from "../../utils/auth";
import {Packeta} from "../../zasilkovna2";
import packetaLogo from "../../assets/img/packeta-symbol.png";

const CartPage = () => {
    const auth = useAuth();
    const loggedIn = !!auth.token && !!auth?.user;
    const {items, isEmpty, removeItem, emptyCart, addItem} = useCart();
    const referal = localStorage.getItem("referal");

    return <>
        <KaHead page={"Košík"}/>
        <PublicHeader titleComp={<h1 className="ka-title">Košík</h1>}></PublicHeader>
        <section className={"cart bg-dark-green"}>
            <Container>
                {isEmpty ? <Row className={"cart-empty"}>
                    <Col xs={12} className={"text-center"}>
                        <h3 className={"white"}>Košík je prázdný</h3>
                        <a className={"button-green"} href={"/products"}>Zpět do obchodu</a>
                    </Col>
                </Row> : <CartPanel referal={referal} cartRemoveItem={removeItem} loggedIn={loggedIn} items={items}
                                    removeItem={(id) => {
                                        removeItem(id);
                                        if (!items.filter(i => !i.type && i.id != id).length) {
                                            emptyCart();
                                        }
                                    }} addItem={addItem}/>
                }
            </Container>
        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </>

}

const GiftPanel = ({addItem}) => {

    const [gifts, setGifts] = useState([]);

    useEffect(() => {
        getPublicGifts()
            .then(res => setGifts(res));
    }, [])

    return <>
        <h4>Vyberte svůj dárek k objednávce</h4>
        <Stack className={"gift-stack"} direction={"horizontal"}>
            {
                gifts.map(g => {
                    return <div className={"gift-item"} key={`gift-${g.id}`}>
                        <img src={process.env.PUBLIC_URL + "/upload/" + g.image.fullPath}/>
                        <p>{g.name}</p>
                        <Button
                            className={"button-green"}
                            onClick={() => {
                                addItem({...g, type: 'GIFT', price: 0})
                            }}>Přidat do košíku
                        </Button>
                    </div>
                })
            }
        </Stack>
    </>
}

const CartPanel = (props) => {
    const {items, removeItem, addItem, referal, loggedIn, cartRemoveItem} = props;
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [ref, setRef] = useState(referal);
    const [loading, setLoading] = useState(false);
    const appliedCoupon = items.find(i => i.type == "COUPON");
    const appliedGift = items.find(i => i.type == "GIFT");
    const appliedShipping = items.find(i => i.type == "SHIPPING");
    const productWithoutMembership = items.find(i => !i.type && !i.product?.membershipDto);
    const productWithMembership = items.find(i => !i.type && i.product?.membershipDto);

    const containsNotVirtual = items.filter(i=>!i.type).some(i => !i?.product?.virtual);

    const calculateTotalPrice = useCallback((items, coupon) => {
        const containsNotVirtual = items.filter(i=>!i.type).some(i => !i?.product?.virtual);
        const containsShipping = items.some(i => i.type == "SHIPPING");
        let total = items.filter(i => !i.type).reduce((all, current) => {
            return all + (current.price * current.quantity);
        }, 0.0);

        if (containsNotVirtual && containsShipping) {
            total += SHIPPING_PRICE;
        }
        if (coupon) {
            /*if (coupon.amountType == "AMOUNT") {
                return total - coupon.amount;
            } else {
                return total * (1 - (coupon.amount / 100));
            }*/
            const c = computeCouponPrice(items.filter(i=>!i.type), coupon);
            return total - c;
        }
        return total;
    }, [appliedCoupon]);

    return <>
        <Row>
            <div className={"d-none d-lg-block"}>
                <ProductTable removeItem={removeItem} items={items} appliedGift={appliedGift}
                              containsNotVirtual={containsNotVirtual} appliedCoupon={appliedCoupon}
                              appliedShipping={appliedShipping} calculateTotalPrice={calculateTotalPrice}/>
            </div>
            <div className={"d-lg-none"}>
                <ProductMobileTable removeItem={removeItem} items={items} appliedGift={appliedGift}
                                    containsNotVirtual={containsNotVirtual} appliedCoupon={appliedCoupon}
                                    calculateTotalPrice={calculateTotalPrice}/>
            </div>
        </Row>
        <Row style={{marginTop: "20px"}}>
            <Col xs={12} lg={6}>
                {productWithoutMembership && <Stack direction={"horizontal"} className={"public-form"}>
                    <ZasilkovnaPanel addItem={addItem} removeItem={cartRemoveItem} selected={appliedShipping}/>
                </Stack>}
                {!appliedCoupon && (
                    <Stack direction={"horizontal"} className={"public-form"}>
                        <TextField
                            label={"Slevový kód"}
                            type="text"
                            disabled={loading}
                            onChange={(e) => setCoupon(e.target.value)}
                        />
                        <button disabled={!coupon.length || loading} className={"button-secondary"} onClick={async () => {
                            setLoading(true);
                            checkCoupon(coupon).then(res => {
                                if (res.couponType == 'PRODUCT' && !productWithoutMembership) {
                                    toast.error("Kupón lze použít pouze na produkty");
                                    return;
                                }
                                if (res.couponType == 'MEMBERSHIPS' && !productWithMembership) {
                                    toast.error("Kupón lze použít pouze na členství");
                                    return;
                                }
                                addItem({...res, price: computeCouponPrice(items.filter(i=>!i.type), res), type: "COUPON"});
                                setCoupon("");
                                toast.success(`Kód ${coupon} byl aktivován`);
                            }).catch(err => {
                                toast.error(`Kód ${coupon} není platný`);
                            }).finally(() => {
                                setLoading(false);
                            })
                        }
                        }>Vložit kód
                        </button>
                    </Stack>
                )}
                {!loggedIn && <Stack direction={"horizontal"} className={"public-form referal-field"}>
                    <TextField
                        value={ref}
                        label={"Referal"}
                        type="number"
                        disabled={loggedIn}
                        onChange={(e) => {
                            if (!!e.target.value) {
                                setRef(Number(e.target.value));
                            } else {
                                setRef(null);
                            }
                        }}
                    />
                </Stack>}
            </Col>
            <Col xs={12} lg={6}>
                {!appliedGift && productWithoutMembership && <GiftPanel addItem={addItem}/>}
            </Col>
        </Row>
        <Row>
            <Col className={"text-center text-lg start"}>
        <Button className={"button-green"}
                onClick={() => {
                    if (containsNotVirtual && !appliedShipping) {
                        toast.error("Vyberte dopravu");
                        return;
                    }
                    if (ref) {
                        localStorage.setItem("referal", ref);
                    }
                    navigate("/payment", {state: {fromCart: true}});
                }}><span>Přejít k pokladně</span></Button>
            </Col>
        </Row>
    </>
}

const ZasilkovnaPanel = ({selected, addItem, removeItem}) => {
    const packetaApiKey = 'd29db0a865731987';
    const packetaOptions = {
        valueFormat: "\"Packeta\",id,carrierId,carrierPickupPointId,name,city,street"
    };

    const showSelectedPickupPoint = (point) => {
        if (point) {
            //console.log("Selected point", point);
            //setSelectedPickup(point);
            removeItem(99999);
            addItem({id: 99999, name: point, type: 'SHIPPING', price: SHIPPING_PRICE})
        }
    }

    return <Stack direction={"horizontal"} style={{marginTop: "69px"}}>
        <div style={{marginRight: "20px"}}>
            <p className={"white"}>Doprava: Zásilkovna 89,- Kč</p>
            {selected &&
                <p className="packeta-selector-value white">{selected.name.name}</p>
            }
        </div>
        <button type={"button"} className="packeta-selector-open"
                onClick={() => {
                    Packeta.Widget.pick(packetaApiKey, showSelectedPickupPoint, packetaOptions);
                }}>
            <img src={packetaLogo}/>
            <p>Vyberte místo vyzvednutí</p>
        </button>
    </Stack>
}

const ProductMobileTable = ({items, removeItem, appliedGift, appliedCoupon, calculateTotalPrice}) => {
    return <div className={"cart-table-mobile"}>
        {
            items.filter(i => !i.type).map(i => <CartMobileItem key={`cart-item-${i.id}`} {...i}
                                                                removeItem={removeItem}/>)
        }
    </div>
}

const ProductTable = ({
                          items,
                          removeItem,
                          appliedGift,
                          appliedCoupon,
                          appliedShipping,
                          calculateTotalPrice,
                          containsNotVirtual
                      }) => {
    return <table className={"white"}>
        <thead>
        <tr>
            <th></th>
            <th></th>
            <th>Produkt</th>
            <th>Cena</th>
            <th>Množství</th>
            <th>Mezisoučet</th>
        </tr>
        </thead>
        <tbody>
        {
            items.filter(i => !i.type).map(i => <CartItem key={`cart-item-${i.id}`} {...i}
                                                          removeItem={removeItem}/>)
        }
        {appliedGift && <tr className={"cart-item-row"}>
            <td>
                <button type={"button"} className={"remove-item"} onClick={() => {
                    removeItem(appliedGift.id);
                }}>x
                </button>
            </td>
            <td className={"cart-item-img"}>
                {appliedGift.image && <img src={getFileSrc(appliedGift.image.fullPath)}/>}
            </td>
            <td>{appliedGift.name} (Dárek)</td>
            <td className={"text-right"}>zdarma</td>
            <td>1</td>
            <td className={"text-right"}>zdarma</td>
        </tr>
        }
        {
            appliedCoupon && <tr className={"cart-item-row"}>
                <td>
                    <button type={"button"} className={"remove-item"} onClick={() => {
                        removeItem(appliedCoupon.id);
                    }}>x
                    </button>
                </td>
                <td></td>
                <td>Slevový kód {appliedCoupon.code}</td>
                <td className={"text-right"}>-{appliedCoupon.amount} {appliedCoupon.amountType == "AMOUNT" ? "Kč" : "%"}</td>
                <td></td>
                <td className={"text-right"}>{formatCurrency(computeCouponPrice(items.filter(i=>!i.type), appliedCoupon))}</td>
            </tr>
        }
        {containsNotVirtual && appliedShipping && (
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Doprava</td>
                <td className={"text-right"}>{formatCurrency(SHIPPING_PRICE)}</td>
            </tr>
        )}
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Celkem</td>
            <td className={"text-right"}>{formatCurrency(calculateTotalPrice(items, appliedCoupon))}</td>
        </tr>
        </tbody>
    </table>
}

const CartMobileItem = (props) => {
    const {id, product, price, quantity} = props;
    const {image} = product;
    return (
        <div className={"cart-item-mobile-row"} key={id}>
            <div>
                <button type={"button"} className={"remove-item"} onClick={() => props.removeItem(id)}>x</button>
            </div>
            <div className={"cart-item-img"}>
                {image && <img src={getFileSrc(image.fullPath)}/>}
            </div>
            <div>
                {resolveVariantName(props)}
            </div>
            <div>
                {formatCurrency(price)}
            </div>
            <div>
                {quantity}
            </div>
            <div>
                {formatCurrency(quantity * price)}
            </div>
        </div>
    )
}

const CartItem = (props) => {
    const {id, product, price, quantity} = props;
    const {image} = product;
    return (
        <tr className={"cart-item-row"} key={id}>
            <td>
                <button type={"button"} className={"remove-item"} onClick={() => props.removeItem(id)}>x</button>
            </td>
            <td className={"cart-item-img"}>
                {image && <img src={getFileSrc(image.fullPath)}/>}
            </td>
            <td>
                {resolveVariantName(props)}
            </td>
            <td className={"text-right"}>
                {formatCurrency(price)}
            </td>
            <td>
                {quantity}
            </td>
            <td className={"text-right"}>
                {formatCurrency(quantity * price)}
            </td>
        </tr>
    )
}

const resolveVariantName = (variant) => {
    if (variant.product.productType == PRODUCT_TYPE.SIMPLE) {
        return variant.product.name;
    }
    return `${variant.product.name} - ${variant.optionValues.sort((a,b) => a.optionId - b.optionId).map(ov => ov.valueName).join(", ")}`;
}

export default CartPage;