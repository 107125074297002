import jscookie from "js-cookie";
import {authProvider, COOKIE_KEY} from "./authProvider";

const getBearerToken = (token) => {
    return `Bearer ${token}`;
}

const getBearerAuthorization = (token) => {
    return {"Authorization": getBearerToken(token)}
}

export const get = async (url, headers = {}) => {
    return await api(url, "GET", null, headers);
}

export const getBlob = async (url, headers = {}) => {
    return await api(url, "GET", null, headers, true);
}

export const getBlobResponse = async (url, headers = {}) => {
    return await api(url, "GET", null, headers, true, true);
}

export const post = async(url, body, headers = {}) => {
    return await api(url, "POST", body, headers);
}

export const patch = async(url, body, headers = {}) => {
    return await api(url, "PATCH", body, headers);
}

export const put = async(url, body, headers = {}) => {
    return await api(url, "PUT", body, headers);
}

export const publicGet = async (url, headers = {}) => {
    return await apiPublic(url, "GET", null, headers);
}

export const publicPost = async (url, body, headers = {}) => {
    return await apiPublic(url, "POST", body, headers);
}

const apiPublic = async (url, method = "GET", body = {}, header = {}) => {
    const options = {
        method,
        headers: {
            "Accept": 'application/json',
            "Content-Type": "application/json",
            ...header,
        },
    };
    if (!!body) {
        if (body instanceof FormData) {
            options.body = body;
            delete options.headers["Content-Type"];
        } else {
            options.body = JSON.stringify(body);
        }
    }
    try {
        const response = await fetch(url, options);
        console.log("Status", response.status);
        if (response.status === 200) {
            return response.json();
        }
    } catch (e) {
        console.log("Catch PUBLIC API", e);
        return false;
    }
}

const api = async (url, method = "GET", body = {}, header = {}, isBlob = false, returnResponse = false) => {
    let isBasic = false;
    if (!header["Authorization"]) {
        const token = jscookie.get(COOKIE_KEY);
        header = {...header, ...getBearerAuthorization(token)}
    } else {
        isBasic = true;
    }
    const options = {
        method,
        headers: {
            "Accept": 'application/json',
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            ...header,
        },
    };
   /* console.log(">> Api Call:", url, method, header);
    console.log(">> Options:", options);*/
    if (!!body) {
        if (body instanceof FormData) {
            options.body = body;
            delete options.headers["Content-Type"];
        } else {
            options.body = JSON.stringify(body);
        }
    }
    try {
        const response = await fetch(url, options);
        console.log("Status", response.status);
        if (response.status === 200 || response.status === 206) {
            if (isBlob) {
                if (returnResponse) {
                    return response;
                }
                return response.blob();
            }
            return response.json();
        } else if (response.status === 401 && !isBasic) {
            const auth = options.headers["Authorization"];
            if (auth?.startsWith("Bearer")) {
                const newToken = await authProvider.refresh();
                if (!!newToken) {
                    options.headers["Authorization"] = getBearerToken(newToken);
                    const response = await fetch(url, options);
                    if (isBlob) {
                        if (returnResponse) {
                            return response;
                        }
                        return response.blob();
                    }
                    return response.json();
                }
            }
            window.location.href = "/login?sessionExpired";
            return;
        } else {
            console.warn("Not authorized", response.status);
            return false;
        }
    } catch (e) {
        console.log("Catch API", e);
        return false;
    }
}

export class ForbiddenError extends Error {

    constructor() {
        super();
    }

}