import {Formik} from 'formik';
import {IconButton, Stack, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {MyCheckbox, MySelect, MyTextArea, MyTextInput} from "../../components/form/components";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {COURSES_LIST, DASHBOARD, DETAIL, NEW, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {createCourse, getCourseById, updateCourse} from "../../actions/coursesActions";
import SortableList from "./sortable/Sortable";
import {v4 as uuidv4} from "uuid";
import {arrayMoveImmutable} from "array-move";
import DeleteIcon from "@mui/icons-material/Delete";
import {getMemberships} from "../../actions/membershipsActions";
import {toast} from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CourseDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, COURSES_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        description: "",
        videos: [],
        memberships: []
    });
    const [memberships, setMemberships] = useState([]);

    const onSubmit = (values) => {
        if (!values.videos.length) {
            toast.error("Přidejte alespoň jedno video");
            return;
        }

        if (isValidId(id)) {
            updateCourse(values, id)
                .then(response => {
                    navigate(PATH.COURSES.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createCourse(values)
                .then(response => {
                    navigate(PATH.COURSES.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const removeItem = useCallback((index, values, setFieldValue) => {
        const filtered = [...values.videos];
        filtered.splice(index, 1)
        setFieldValue("videos", filtered);
    }, []);

    const courseSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        description: Yup.string()
            .max(1024, intl.formatMessage({id: "validation.tooLong"})),
        order: Yup.number()
            .required(intl.formatMessage({id: "validation.required"})),
        videos: Yup.array()
            .required(intl.formatMessage({id: "validation.empty"}))
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getCourseById(id)
                .then(res => {
                    if (mounted) {
                        setData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    useEffect(() => {
        getMemberships([{field: "name", sort: "asc"}])
            .then(res => setMemberships(res.data));
    }, [])

    const actions = (values) => [
        addBackAction(PATH.COURSES.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={courseSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ValueLogger values={values}/>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "course.detailTitle" : "course.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "course.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "course.name"})}
                                    />
                                    <>
                                        <label><FormattedMessage id={"course.description"}/></label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={values.description || "<p></p>"}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                //console.log( { event, editor, data } );
                                                setFieldValue("description", data);
                                            }}
                                        />
                                    </>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "course.order"})}
                                        name="order"
                                        type="number"
                                        placeholder={intl.formatMessage({id: "course.order"})}
                                    />
                                    <MySelect
                                        multiple
                                        label={intl.formatMessage({id: "course.membership"})}
                                        name={`memberships`}
                                        options={memberships.map(m => ({id: m.id, name: m.name}))}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <NewVideoForm intl={intl} setFieldValue={setFieldValue} videos={values.videos}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Accordion className={"ka-accordion"}>
                                    {values.videos.map((v, index) => {
                                        return <Accordion.Item eventKey={`${index}`}>
                                            <Accordion.Header>{v.name}</Accordion.Header>
                                            <Accordion.Body>
                                                <VideoSingle intl={intl} setFieldValue={setFieldValue} video={v}
                                                             index={index}
                                                             onDelete={(index) => removeItem(index, values, setFieldValue)}/>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    })}

                                    {values.videos.length ? <Accordion.Item eventKey={values.videos.length}>
                                        <Accordion.Header><FormattedMessage
                                            id={"course.detail.order.title"}/></Accordion.Header>
                                        <Accordion.Body>
                                            <SortableList items={values.videos} dragEnded={({source, destination}) => {
                                                setFieldValue("videos", arrayMoveImmutable(values.videos, source.index, destination.index))
                                            }}/>
                                        </Accordion.Body>
                                    </Accordion.Item> : <></>}
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

const NewVideoForm = ({intl, setFieldValue, videos}) => {
    const [name, setName] = useState();
    const [link, setLink] = useState();

    return <div className={"form-stack-horizontal"}>
        <h3>Přidat video</h3>
        <Stack direction={"row"}>
            <TextField
                label={intl.formatMessage({id: "course.name"})}
                type="text"
                name="newName"
                value={name}
                placeholder={intl.formatMessage({id: "course.name"})}
                onChange={e => setName(e.target.value)}
            />
            <TextField
                label={intl.formatMessage({id: "course.link"})}
                type="url"
                name="newUrl"
                value={link}
                placeholder={intl.formatMessage({id: "course.link"})}
                onChange={e => setLink(e.target.value)}
            />
            <button className={"button-green"} type={"button"} onClick={() => {
                if (!name || !link) {
                    return;
                }
                const current = videos || [];
                const existing = current.find(c => c.name == name);
                if (existing) {
                    return;
                }
                setFieldValue("videos", [...current, {id: uuidv4(), name, link}]);
                setName("");
                setLink("");
            }
            }><FormattedMessage id={"common.addNew"}/></button>
        </Stack>
    </div>
}

const VideoSingle = ({video, intl, setFieldValue, index, onDelete}) => {
    return <div className={"product-variant-single"}>
        <Stack spacing={10}>
            <TextField
                label={intl.formatMessage({id: "course.link"})}
                type="text"
                name={`videos[${index}].link`}
                value={video.link}
                placeholder={intl.formatMessage({id: "course.link"})}
                onChange={e => setFieldValue(`videos[${index}].link`, e.target.value)}
            />
            <TextField
                label={intl.formatMessage({id: "course.freeLink"})}
                type="text"
                name={`videos[${index}].freeLink`}
                value={video.freeLink}
                placeholder={intl.formatMessage({id: "course.freeLink"})}
                onChange={e => setFieldValue(`videos[${index}].freeLink`, e.target.value)}
            />
            <TextField
                label={intl.formatMessage({id: "course.thumbnail"})}
                type="text"
                name={`videos[${index}].thumbnail`}
                value={video.thumbnail}
                placeholder={intl.formatMessage({id: "course.thumbnail"})}
                onChange={e => setFieldValue(`videos[${index}].thumbnail`, e.target.value)}
            />
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(index)}>
                <DeleteIcon/>
            </IconButton>
        </Stack>
    </div>
}

const ValueLogger = ({values}) => {
    console.log(values);
    return null;
}

export default CourseDetail;