import React, {useCallback, useEffect, useState} from 'react';
import KaHead from "../head";
import PublicHeader from "../../../components/publicHeader";
import ReviewsSection from "../reviews";
import PartnersSection from "../partners";
import FooterSection from "../footer";
import {getUserByEmail} from "../../../actions/userActions";
import {useAuth} from "../../../utils/auth";
import {downloadInvoice, getUserOrders} from "../../../actions/orderActions";
import {Col, Container, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ProfileOrdersPage = () => {
    const {user} = useAuth();

    const [userDetail, setUserDetail] = useState();
    const [orders, setOrders] = useState();

    useEffect(() => {
        getUserByEmail(user).then(detail => {
            if (detail) {
                setUserDetail(detail);
            } else {
                throw new Error("No user found");
            }
        }).catch(err => {
            console.error("Error", err);
        }).then(getUserOrders).then(o => {
            setOrders(o)
        });
    }, []);

    const printInvoice = useCallback((id, orderId) => {
        downloadInvoice(id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `faktura_${orderId}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            toast.error("Nelze stáhnout fakturu");
        })
    }, []);

    return userDetail ? <>
        <KaHead page={"Objednávky"}/>
        <PublicHeader
            type={"small"}
            titleComp={<h1 className="ka-title">{`Objednávky`}</h1>}></PublicHeader>
        <section className={"profile-orders bg-dark-green"}>
            {orders?.length ? <>
                <div className={"d-none d-lg-block"}>
                    <OrderList orders={orders} printInvoice={(id, orderId) => printInvoice(id, orderId)}/>
                </div>
                <div className={"d-lg-none"}>
                    <OrderListMobile  orders={orders} printInvoice={(id, orderId) => printInvoice(id, orderId)} />
                </div>
                </> :
                <Container>
                    <Row>
                        <Col>
                            <h3>Nemáte žádné objednávky</h3>
                        </Col>
                    </Row>
                </Container>}
        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </> : <><KaHead page={"Objednávky"}/></>

}

const translateStatus = (orderStatus) => {
    switch (orderStatus) {
        case 'NEW':
            return 'Nová';
        case 'PAID':
            return 'Zaplacená';
        case 'PROCESSED':
            return 'Dokončená';
    }
    return '';
}

const OrderList = ({orders, printInvoice}) => {
    const navigate = useNavigate();
    return <Container className={"user-order-table"}>
        <Row>
            <Col>
                <table>
                    <thead>
                        <td>Číslo objednávky</td>
                        <td>Datum</td>
                        <td>Stav</td>
                        <td>Cena celkem</td>
                        <td></td>
                    </thead>
                    <tbody>
                    {orders.map(o => {
                        const date = new Date(o.created);
                        const formatted = date.toLocaleString('cs-CS').replaceAll('. ', '.');
                        return <tr key={`order-${o.id}`}>
                            <td>{o.id}</td>
                            <td>{formatted}</td>
                            <td>{translateStatus(o.orderStatus)}</td>
                            <td>{o.total} Kč</td>
                            <td>
                                <a className={"button-green"} onClick={() => {
                                    navigate("/profile/orders/" + o.id)
                                }}>Detail</a>
                                <a className={"button-green"} onClick={() => printInvoice(o.id, o.orderId)}>Tisk faktury</a>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </Col>
        </Row>
    </Container>
}

const OrderListMobile = ({orders, printInvoice}) => {
    const navigate = useNavigate();
    return <Container className={"user-order-table-mobile"}>
        <Row>
            <Col>
                <table>
                    <thead>
                        <td>Číslo objednávky</td>
                        <td>Datum</td>
                        <td>Stav</td>
                        <td>Cena celkem</td>
                    </thead>
                    <tbody>
                    {orders.map(o => {
                        const date = new Date(o.created);
                        const formatted = date.toLocaleString('cs-CS').replaceAll('. ', '.');
                        return <tr key={`order-m-${o.id}`} className={"outer"}>
                            <td colSpan={4}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>{o.id}</td>
                                        <td>{formatted}</td>
                                        <td>{translateStatus(o.orderStatus)}</td>
                                        <td>{o.total} Kč</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <a className={"button-green"} onClick={() => {
                                                navigate("/profile/orders/" + o.id)
                                            }}>Detail</a>
                                            <a className={"button-green"} onClick={() => printInvoice(o.id, o.orderId)}>Tisk faktury</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </Col>
        </Row>
    </Container>
}


export default ProfileOrdersPage;