import React, {useCallback, useEffect, useState} from 'react';
import {getFreeVideoToken, getVideoToken} from "../../actions/streamActions";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import {IconButton} from "@mui/material";
import {BASE_API_URL} from "../../const";

const API_URL = `${BASE_API_URL}/api/stream/video/`;
const API_URL_PUB = `${BASE_API_URL}/api/stream/video/pub/`;
const CHUNK_SIZE = 5 * 1024 * 1024; // 1 MB chunk size for partial content requests

export const VideoPlayerNew = ({videoId, freeLink, loggedIn, thumbnail}) => {
    const [token, setToken] = useState();
    const [validTo, setValidTo] = useState();
    const [error, setError] = useState(false);

    const getToken = useCallback(() => {
        getVideoToken(videoId).then(res => {
            setToken(res.token);
        }).catch(err => {
            setError(true);
        });
    }, []);

    if (!token && loggedIn) {
        return <div className={"video-button"} style={{backgroundImage: thumbnail ? (`url(${process.env.PUBLIC_URL + "/kurzy/" + thumbnail})`) : null }}>
            <IconButton className={"button-green"} edge="end" onClick={() => getToken()}>
                <PlayCircleFilledWhiteIcon/>
            </IconButton>
        </div>
    }

    return <video
        width={'100%'}
        poster={thumbnail && process.env.PUBLIC_URL + "/kurzy/" + thumbnail}
        playsInline controls preload="none" autoPlay={!!token}>
        {token && loggedIn && <source src={API_URL + token} type="video/mp4"/>}
        {/*{!loggedIn && <source src={process.env.PUBLIC_URL + "/kurzy/" + freeLink} type="video/mp4"/>}*/}
        {!loggedIn && <source src={API_URL_PUB + videoId} type="video/mp4"/>}
    </video>
};
