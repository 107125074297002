import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider, RequireAuth} from "./utils/auth";
import {IntlProvider} from "react-intl";
import LoginPage from "./screens/LoginPage";
import React, {useEffect, useState} from "react";

import messageProvider from './messages/provider';
import Dashboard from "./screens/Dashboard";
import Layout from "./Layout";

import './styles/App.scss'
import {SnackbarProvider} from "material-ui-snackbar-provider";
import OrdersScreen from "./screens/eshop/orders/OrdersScreen";
import OrderDetail from "./screens/eshop/orders/OrderDetail";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {KA_ADMIN, PATH} from "./const";
import {ThemeProvider} from "@mui/material";
import theme from "./styles/theme";
import {BreadcrumbsProvider} from "./utils/breadcrumbs";
import HomePage from "./screens/public/HomePage";
import Page404 from "./screens/404";
import CartPage from "./screens/public/CartPage";
import ProductsScreen from "./screens/eshop/products/ProductsScreen";
import ProductDetail from "./screens/eshop/products/ProductDetail";
import MembershipPage from "./screens/public/MembershipPage";
import AboutPage from "./screens/public/AboutPage";
import ProductsPage from "./screens/public/ProductsPage";
import {CartProvider} from "react-use-cart";
import PaymentPage from "./screens/public/PaymentPage";
import ProductDetailPage from "./screens/public/ProductDetailPage";
import LogoutPage from "./screens/LogoutPage";
import CategoriesScreen from "./screens/eshop/categories/CategoriesScreen";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CoursesScreen from "./screens/course/CoursesScreen";
import CourseDetail from "./screens/course/CoursesDetail";
import MembershipScreen from "./screens/membership/MembershipScreen";
import MembershipDetail from "./screens/membership/MembershipDetail";
import CoursePage from "./screens/public/CoursesPage";
import CouponsScreen from "./screens/eshop/coupons/CouponsScreen";
import CouponDetail from "./screens/eshop/coupons/CouponDetail";
import OptionsScreen from "./screens/eshop/options/OptionsScreen";
import OptionDetail from "./screens/eshop/options/OptionDetail";
import UserScreen from "./screens/user/UserScreen";
import GiftScreen from "./screens/eshop/gifts/GiftScreen";
import GiftDetail from "./screens/eshop/gifts/GiftDetail";
import ProfilePage from "./screens/public/profile/ProfilePage";
import ProfileMembershipPage from "./screens/public/profile/ProfileMembershipPage";
import ProfileReferalPage from "./screens/public/profile/ProfileReferalPage";
import ReferalDiscountScreen from "./screens/referalDiscount/ReferalDiscountScreen";
import ReferalDiscountDetail from "./screens/referalDiscount/ReferalDiscountDetail";
import ProfileOrdersPage from "./screens/public/profile/ProfileOrdersPage";
import ScrollToTop from "./components/scroll";
import StreamsScreen from "./screens/stream/StreamsScreen";
import StreamDetail from "./screens/stream/StreamsDetail";
import StreamsPage from "./screens/public/StreamsPage";
import ReviewScreen from "./screens/review/ReviewScreen";
import ReviewDetail from "./screens/review/ReviewDetail";
import ProfileOrderDetailPage from "./screens/public/profile/ProfileOrderDetailPage";
import ChangePasswordPage from "./screens/ChangePasswordPage";
import ReactGA from 'react-ga4';
import TahakyPage from "./screens/public/TahakyPage";
import UserDetail from "./screens/user/UserDetail";
import {CookieConsent, getCookieConsentValue} from "react-cookie-consent";
import TmpOrdersScreen from "./screens/eshop/tmpOrders/TmpOrdersScreen";
import TmpOrderDetail from "./screens/eshop/tmpOrders/TmpOrderDetail";

const TRACKING_ID = "G-LC8TFHLH6B"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    const [locale, setLocale] = useState('cs');

    const commonProps = {setLocale, locale};
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        const cookieValue = getCookieConsentValue();
        //console.log("cookieValue", cookieValue);
        if (cookieValue) {
            ReactGA.send("pageview");
        }
        //ReactGA.send("pageview"); correct
        //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Custom Title" });
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }, [accepted]);

    return (
        <React.StrictMode>
            <BrowserRouter>
                <ScrollToTop>
                    <ThemeProvider theme={theme}>
                        <IntlProvider locale={locale} messages={flattenMessages(messageProvider(locale))}>
                            <BreadcrumbsProvider>
                                <SnackbarProvider SnackbarProps={{autoHideDuration: 4000}}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <CartProvider>
                                            <AuthProvider>
                                                {getRoutes(commonProps)}
                                            </AuthProvider>
                                        </CartProvider>
                                    </LocalizationProvider>
                                </SnackbarProvider>
                            </BreadcrumbsProvider>
                        </IntlProvider>
                    </ThemeProvider>
                </ScrollToTop>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <CookieConsent
                flipButtons
                location={"bottom"}
                buttonClasses="btn btn-primary"
                containerClasses="ka-cookies col-lg-12"
                //contentClasses="text-capitalize"
                style={{ background: "black" }}
                declineButtonStyle={{ background: "#3585ca", color: "white", borderRadius: "5px", padding: "10px 15px" }}
                buttonStyle={{ background: "#0eff28", color: "black", borderRadius: "5px", padding: "10px 15px", fontWeight: 500 }}
                enableDeclineButton
                declineButtonText={"Odmítnout"}
                buttonText={"Příjmout"}
                onAccept={() => {
                    setAccepted(true);
                }}
            >
                Abychom poskytli co nejlepší služby, používáme k ukládání a/nebo přístupu k informacím o zařízení, technologie jako jsou soubory cookies. Souhlas s těmito technologiemi nám umožní zpracovávat údaje, jako je chování při procházení nebo jedinečná ID na tomto webu. Nesouhlas nebo odvolání souhlasu může nepříznivě ovlivnit určité vlastnosti a funkce.
            </CookieConsent>
        </React.StrictMode>
    );
}

const getRoutes = (commonProps) => {
    return <Routes>
        <Route path="/" element={<HomePage {...commonProps}/>} exact/>
        <Route path="/about" element={<AboutPage {...commonProps}/>}/>
        <Route path="/membership" element={<MembershipPage {...commonProps}/>}/>
        <Route path="/products" element={<ProductsPage {...commonProps}/>}/>
        <Route path="/products/:id" element={<ProductDetailPage {...commonProps}/>}/>
        <Route path="/cart" element={<CartPage {...commonProps}/>}/>
        <Route path="/payment" element={<PaymentPage {...commonProps}/>}/>
        <Route path="/courses" element={<CoursePage {...commonProps}/>}/>
        <Route path="/changePassword/:token" element={<ChangePasswordPage {...commonProps}/>}/>
        <Route path="/streams" element={<RequireAuth><StreamsPage {...commonProps}/></RequireAuth>}/>
        <Route path="/tahaky" element={<RequireAuth><TahakyPage {...commonProps}/></RequireAuth>}/>
        <Route path="/profile" element={<RequireAuth><ProfilePage {...commonProps}/></RequireAuth>}/>
        <Route path="/profile/referal" element={<RequireAuth><ProfileReferalPage {...commonProps}/></RequireAuth>}/>
        <Route path="/profile/membership"
               element={<RequireAuth><ProfileMembershipPage {...commonProps}/></RequireAuth>}/>
        <Route path="/profile/orders" element={<RequireAuth><ProfileOrdersPage {...commonProps}/></RequireAuth>}/>
        <Route path="/profile/orders/:id" element={<RequireAuth><ProfileOrderDetailPage {...commonProps}/></RequireAuth>}/>
        <Route path="login" element={<LoginPage {...commonProps}/>}/>
        <Route path="logout" element={<LogoutPage {...commonProps}/>}/>
        <Route path={KA_ADMIN}
               element={<AuthScreen
                   commonProps={commonProps}><Dashboard/></AuthScreen>}/>
        <Route path={PATH.ORDERS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><OrdersScreen/></AuthScreen>}/>
        <Route path={PATH.ORDERS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><OrderDetail/></AuthScreen>}/>
        <Route path={PATH.TMP_ORDERS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><TmpOrdersScreen/></AuthScreen>}/>
        <Route path={PATH.TMP_ORDERS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><TmpOrderDetail/></AuthScreen>}/>
        <Route path={PATH.PRODUCTS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><ProductsScreen/></AuthScreen>}/>
        <Route path={PATH.PRODUCTS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><ProductDetail/></AuthScreen>}/>
        <Route path={PATH.OPTIONS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><OptionsScreen/></AuthScreen>}/>
        <Route path={PATH.OPTIONS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><OptionDetail/></AuthScreen>}/>
        <Route path={PATH.CATEGORIES.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><CategoriesScreen/></AuthScreen>}/>
        <Route path={PATH.COURSES.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><CoursesScreen/></AuthScreen>}/>
        <Route path={PATH.COURSES.ID}
               element={<AuthScreen
                   commonProps={commonProps}><CourseDetail/></AuthScreen>}/>
        <Route path={PATH.MEMBERSHIPS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><MembershipScreen/></AuthScreen>}/>
        <Route path={PATH.MEMBERSHIPS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><MembershipDetail/></AuthScreen>}/>
        <Route path={PATH.REFERAL_DISCOUNT.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><ReferalDiscountScreen/></AuthScreen>}/>
        <Route path={PATH.REFERAL_DISCOUNT.ID}
               element={<AuthScreen
                   commonProps={commonProps}><ReferalDiscountDetail/></AuthScreen>}/>
        <Route path={PATH.COUPONS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><CouponsScreen/></AuthScreen>}/>
        <Route path={PATH.COUPONS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><CouponDetail/></AuthScreen>}/>
        <Route path={PATH.USERS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><UserScreen/></AuthScreen>}/>
        <Route path={PATH.USERS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><UserDetail/></AuthScreen>}/>
        <Route path={PATH.GIFTS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><GiftScreen/></AuthScreen>}/>
        <Route path={PATH.GIFTS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><GiftDetail/></AuthScreen>}/>
        <Route path={PATH.STREAMS.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><StreamsScreen/></AuthScreen>}/>
        <Route path={PATH.STREAMS.ID}
               element={<AuthScreen
                   commonProps={commonProps}><StreamDetail/></AuthScreen>}/>
        <Route path={PATH.REVIEW.LIST}
               element={<AuthScreen
                   commonProps={commonProps}><ReviewScreen/></AuthScreen>}/>
        <Route path={PATH.REVIEW.ID}
               element={<AuthScreen
                   commonProps={commonProps}><ReviewDetail/></AuthScreen>}/>
        <Route path="*" element={<Page404 {...commonProps} />}/>
    </Routes>
}

const AuthScreen = ({children, commonProps}) => {
    return <RequireAuth>
        <Layout {...commonProps}>{children}</Layout>
    </RequireAuth>
}

const flattenMessages = ((nestedMessages, prefix = '') => {
    if (nestedMessages === null) {
        return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key]
        const prefixedKey = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'string') {
            Object.assign(messages, {[prefixedKey]: value})
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey))
        }

        return messages
    }, {})
})

export default App;
