import React, {useEffect, useState} from 'react';
import Sidebar from './components/Sidebar/sidebar';
import {Avatar} from "@mui/material";
import {BreadcrumbsRender} from "./utils/breadcrumbs";
import {useAuth} from "./utils/auth";
import {getUserByEmail} from "./actions/userActions";
import {useNavigate} from "react-router-dom";

const getAvatarInitials = (firstName, lastName) => {
    const fn = !!firstName ? firstName.substring(0,1) : "";
    const ln = !!lastName ? lastName.substring(0,1) : "";
    return `${fn}${ln}`;
}

function Layout({ setLocale, locale, children }) {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [userDetail, setUserDetail] = useState();
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        if (user) {
            const getUser = async () => {
                const u = await getUserByEmail(user);
                if (u.role != "ADMIN") {
                    navigate("/");
                    return;
                }
                setUserDetail(u);
            }
            getUser();
        }
    }, [])

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return userDetail ? (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                setLocale={setLocale}
                locale={locale}
            />
            <div className={"main"}>
                <div className={"main-header"}>
                    <div className={"breadcrumbs"}>
                        <BreadcrumbsRender />
                    </div>
                    <Avatar>
                        {getAvatarInitials(userDetail.firstName, userDetail.lastName)}
                    </Avatar>
                </div>
                <div className={"main-content"}>
                    {children}
                </div>
            </div>
        </div>
    ) : <></>;
}

export default Layout;
