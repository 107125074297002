import React, {useEffect, useState} from "react";
import {PATH} from "../const";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";

const BreadcrumbsContext = React.createContext(null);

export const DASHBOARD = {link: PATH.DASHBOARD, label: "breadcrumbs.dashboard"};
export const ORDER_LIST = {link: PATH.ORDERS.LIST, label: "breadcrumbs.orders"};
export const TMP_ORDER_LIST = {link: PATH.ORDERS.LIST, label: "breadcrumbs.tmpOrders"};
export const PRODUCT_LIST = {link: PATH.PRODUCTS.LIST, label: "breadcrumbs.products"};
export const OPTION_LIST = {link: PATH.OPTIONS.LIST, label: "breadcrumbs.options"};
export const CATEGORIES_LIST = {link: PATH.CATEGORIES.LIST, label: "breadcrumbs.categories"};
export const COURSES_LIST = {link: PATH.COURSES.LIST, label: "breadcrumbs.courses"};
export const STREAMS_LIST = {link: PATH.STREAMS.LIST, label: "breadcrumbs.streams"};
export const MEMBERSHIP_LIST = {link: PATH.MEMBERSHIPS.LIST, label: "breadcrumbs.memberships"};
export const REFERAL_DISCOUNT_LIST = {link: PATH.REFERAL_DISCOUNT.LIST, label: "breadcrumbs.referalDiscounts"};
export const USER_LIST = {link: PATH.USERS.LIST, label: "breadcrumbs.users"};
export const GIFT_LIST = {link: PATH.MEMBERSHIPS.LIST, label: "breadcrumbs.gifts"};
export const COUPON_LIST = {link: PATH.COUPONS.LIST, label: "breadcrumbs.coupons"};
export const REVIEW_LIST = {link: PATH.REVIEW.LIST, label: "breadcrumbs.reviews"};
export const NEW = {label: "breadcrumbs.new"};
export const DETAIL = {label: "breadcrumbs.detail"};

export const BreadcrumbsProvider = ({ children }) => {
    const [state, setState] = useState([DASHBOARD]);

    const reset = () => {
        setState([DASHBOARD]);
    }

    return <BreadcrumbsContext.Provider value={{state, setState, reset}}>{children}</BreadcrumbsContext.Provider>;
}

export const useBreadcrumbs = () => {
    return React.useContext(BreadcrumbsContext);
}

export const useBreadcrumbsComponent = (list) => {
    const {setState} = useBreadcrumbs();
    useEffect(() => {
        setState(list);
    }, []);
}

export const BreadcrumbsRender = () => {
    const {state} = useBreadcrumbs();

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {state.map((item, i)=>{
                if (item.link && i !== state.length - 1) {
                    return <Link key={`breadcrumb_${i}`} underline="hover" color="inherit" href={item.link}>
                        <FormattedMessage id={item.label} />
                    </Link>
                } else {
                    return <Typography key={`breadcrumb_${i}`} color="text.primary">
                        <FormattedMessage id={item.label}/>
                    </Typography>
                }
            })}
        </Breadcrumbs>
    );
}