import {Formik} from 'formik';
import {Grid, Stack} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {
    MyDateTimePicker,
    MyFileUpload,
    MySelect,
    MyTextArea,
    MyTextInput,
    SubmitButton
} from "../../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction} from "../../../components/actionPanel";
import {
    createOrder,
    downloadAllInvoice, downloadInvoice, downloadInvoiceAdmin,
    getOrderById,
    updateOrder,
    updateOrderPayment
} from "../../../actions/orderActions";
import {COUNTRIES, PATH} from "../../../const";
import TitledForm from "../../../components/form/Form";
import {ORDER_LIST, DASHBOARD, DETAIL, NEW, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {isValidId} from "../../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {toast} from "react-toastify";

const OrderDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, ORDER_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        orderId: "",
        image: undefined,
        company: null,
        orderStatus: '',
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateOrder(values, id)
                .then(response => {
                    navigate(PATH.ORDERS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createOrder(values)
                .then(response => {
                    navigate(PATH.ORDERS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const orderSchema = Yup.object().shape({
        orderId: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    const initData = useCallback(data => {
        if (data.image) {
            setData({
                ...data,
                image: {name: data.filename, preview: `data:${data.contentType};base64,${data.image}`}
            });
        } else {
            setData(data);
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getOrderById(id)
                .then(res => {
                    //const d = res.data[0];
                    if (mounted) {
                        initData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = [
        addBackAction(PATH.ORDERS.LIST),
        {
            color: "secondary",
            variant: "contained",
            title: 'order.downloadInvoice',
            onClick: () => {
                downloadInvoiceAdmin(id).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `faktura_${data.orderId}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }).catch(err => {
                    toast.error("Nelze stáhnout fakturu");
                })
            }
        },{
            color: "secondary",
            variant: "contained",
            title: 'order.userDetail',
            onClick: () => {
                navigate(`${PATH.USERS.LIST}/${data.user.id}`);
            }
        }
    ];

    if (data && data.paymentType == 'TRANSFER' && data.orderStatus == 'NEW') {
        const paymentBody = {
            paymentId: "TRANSFER",
            amount: data.total,
            provider: "Transfer",
            status: "payment_received",
        };
        actions.push({
            color: "secondary",
            variant: "contained",
            title: 'order.processPayment',
            onClick: () => {
                updateOrderPayment(data.orderId, paymentBody)
                    .then(res=>{
                        toast.success(intl.formatMessage({id: "order.paymentProcessed"}));
                        getOrderById(id)
                            .then(res => {
                                initData(res);
                            });
                    }).catch(e=>{
                        toast.error(intl.formatMessage({id: "order.paymentProcessingFailed"}));
                })
            }
        }, {
            color: "secondary",
            variant: "contained",
            title: 'order.userDetail',
            onClick: () => {
                const userId = data.user.id;
                navigate(`${PATH.USERS.LIST}/${userId}`);
            }
        });
    }

    return <div>
        <ActionPanel actions={actions}/>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={orderSchema}
            initialValues={data}
        >
            <TitledForm title={intl.formatMessage({id: isValidId(id) ? "order.detailTitle" : "order.newTitle"})}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Stack spacing={10}>
                                <MyTextInput
                                    label={intl.formatMessage({id: "order.orderId"})}
                                    name="orderId"
                                    type="text"
                                    placeholder={intl.formatMessage({id: "order.orderId"})}
                                    disabled
                                />
                                <MyTextInput
                                    name="created"
                                    type="text"
                                    label={intl.formatMessage({id: "common.created"})}
                                    placeholder={intl.formatMessage({id: "common.created"})}
                                    disabled
                                />
                                <MySelect
                                    label={intl.formatMessage({id: "order.status"})}
                                    name="orderStatus"
                                    options={[{id: "NEW", name: "Nová"}, {
                                        id: "PAID",
                                        name: "Zaplacená"
                                    }, {id: "PROCESSED", name: "Dokončená"}]}
                                />
                                <MyTextInput
                                    label={intl.formatMessage({id: "order.shipping"})}
                                    name="shipping"
                                    type="text"
                                    placeholder={intl.formatMessage({id: "order.shipping"})}
                                    disabled
                                />
                                <MyTextInput
                                    label={intl.formatMessage({id: "order.total"})}
                                    name="total"
                                    type="text"
                                    placeholder={intl.formatMessage({id: "order.total"})}
                                    disabled
                                />
                                <MyTextArea
                                    label={intl.formatMessage({id: "order.notes"})}
                                    name="notes"
                                    placeholder={intl.formatMessage({id: "order.notes"})}
                                    disabled
                                />
                            </Stack>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Stack spacing={10}>
                                <Row>
                                    <Col xs={6}>
                                        <MyTextInput
                                            label={intl.formatMessage({id: "order.firstName"})}
                                            name="user.firstName"
                                            type="text"
                                            placeholder={intl.formatMessage({id: "order.firstName"})}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <MyTextInput
                                            label={intl.formatMessage({id: "order.lastName"})}
                                            name="user.lastName"
                                            type="text"
                                            placeholder={intl.formatMessage({id: "order.lastName"})}
                                            disabled
                                        />
                                    </Col>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.email"})}
                                        name="user.email"
                                        type="email"
                                        placeholder={intl.formatMessage({id: "order.email"})}
                                        disabled
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.company"})}
                                        name="user.company"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "order.company"})}
                                        disabled
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.street"})}
                                        name="user.street"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "order.street"})}
                                        disabled
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.city"})}
                                        name="user.city"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "order.city"})}
                                        disabled
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.zip"})}
                                        name="user.zip"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "order.zip"})}
                                        disabled
                                    />
                                    <MySelect
                                        label={intl.formatMessage({id: "order.country"})}
                                        name="user.countryId"
                                        options={COUNTRIES}
                                        disabled
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "order.phone"})}
                                        name="user.phone"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "order.phone"})}
                                        disabled
                                    />
                                </Row>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <table className="order-table">
                                <thead>
                                    <tr>
                                        <th>ID Produktu</th>
                                        <th>Název Produktu</th>
                                        <th>Varianta</th>
                                        <th>Množství</th>
                                        <th>Cena za kus</th>
                                        <th>Cena Celkem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    data?.products && data.products.map(p=>{
                                        return <tr key={`order-product-${p.id}`}>
                                            <td>{p.productVariantDto.id}</td>
                                            <td>{p.product.name}</td>
                                            <td>{p.productVariantDto.optionValues.map(ov=>ov.valueName).join(" - ")}</td>
                                            <td>{p.count}</td>
                                            <td>{p.price}</td>
                                            <td>{p.price * p.count}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </TitledForm>
        </Formik>
    </div>
}

export default OrderDetail;