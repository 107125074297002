import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {MySelect, MySelectChipCreatable, MyTextArea, MyTextInput} from "../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import TitledForm from "../../components/form/Form";
import {DASHBOARD, DETAIL, NEW, STREAMS_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {isValidId} from "../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {createStream, getStreamById, updateStream} from "../../actions/streamsActions";
import {getTags, getTagsToUi} from "../../actions/tagActions";


const StreamDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, STREAMS_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        url: "",
        tags: [],
    });
    const [tags, setTags] = useState([]);

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateStream(values, id)
                .then(response => {
                    navigate(PATH.STREAMS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createStream(values)
                .then(response => {
                    navigate(PATH.STREAMS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const removeItem = useCallback((index, values, setFieldValue) => {
        const filtered = [...values.videos];
        filtered.splice(index, 1)
        setFieldValue("videos", filtered);
    }, []);

    const streamSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        url: Yup.string()
            .max(1024, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getStreamById(id)
                .then(res => {
                    if (mounted) {
                        setData(res);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    useEffect(() => {
        getTags([{field: "name", sort: "asc"}])
            .then(res => setTags(res.data));
    }, [])

    const actions = (values) => [
        addBackAction(PATH.STREAMS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={streamSchema}
            initialValues={data}
        >
            {({values, setFieldValue}) => (<>
                <ValueLogger values={values}/>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "stream.detailTitle" : "stream.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "stream.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "stream.name"})}
                                    />
                                    <MyTextInput
                                        label={intl.formatMessage({id: "stream.url"})}
                                        name="url"
                                        type="url"
                                        placeholder={intl.formatMessage({id: "stream.url"})}
                                    />
                                    <MySelectChipCreatable
                                        label={intl.formatMessage({id: "stream.tags"})}
                                        name={`tags`}
                                        options={tags}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

const ValueLogger = ({values}) => {
    console.log(values);
    return null;
}

export default StreamDetail;