import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import TrustBox from "../../components/trustpilot/trustpilot";
import {TextSlider} from "../../components/Slider/imageSlider";
import {getPublicReviews} from "../../actions/publicActions";

const ReviewsSection = (props) => {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        getPublicReviews().then(res => setReviews(res));
    }, []);
    return <section className="reviews bg-dark-black">
        <Container>
            <Row>
                <Col xs={12} lg={6} className={"d-flex align-items-center"}>
                    <h3 className="ka-title">Netvrdíme o sobě, že jsme nejlepší,<br/><span>mluví za nás výsledky!</span>
                    </h3>
                </Col>
                <Col xs={12} lg={6}>
                    <div>
                        {reviews.length && <TextSlider className={"reviews-list"} slides={reviews}/>}
                    </div>
                    <TrustBox/>
                </Col>
            </Row>
        </Container>
    </section>
}

export default ReviewsSection;