import React from 'react';
import KaHead from "./public/head";

const Page404 = (props) => {
    return (<>
        <KaHead page={""} />
        <div>
            <h1>Page not found</h1>
        </div>
        </>
    )
}

export default Page404;