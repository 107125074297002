import {Container, Navbar, NavDropdown} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import React from "react";

import kaLogo from "../../assets/img/ka_menu_logo.png"
import {useCart} from "react-use-cart";

const KaNavbar = (props) => {
    const {items} = useCart();
    const {loggedIn, user} = props;
    return <>
        <Navbar className={"ka-menu"} collapseOnSelect expand={"lg"} variant={"dark"}>
            <Container>
                <Navbar.Brand href="/"><div className={'brand-wrapper'}><img src={kaLogo} /></div></Navbar.Brand>
                <Navbar.Toggle aria-controls={"navbarScroll"} data-bs-target={"#navbar-scroll"} />
                <Navbar.Collapse id={"navbar-scroll"} className="justify-content-end">
                    <Nav.Item>
                        <Nav.Link href="/about">O nás</Nav.Link>
                    </Nav.Item>
                    <Separator />
                    <Nav.Item>
                        <Nav.Link href="/membership">Členství</Nav.Link>
                    </Nav.Item>
                    <Separator />
                    {loggedIn && <>
                        <NavDropdown title={"Obsah"} id={"nav-courses"}>
                            <NavDropdown.Item href={"/courses"}>Kurzy</NavDropdown.Item>
                            <NavDropdown.Item href={"/streams"}>Stream</NavDropdown.Item>
                            <NavDropdown.Item href={"/tahaky"}>Taháky</NavDropdown.Item>
                            {/*<NavDropdown.Item>Slovník</NavDropdown.Item>*/}
                        </NavDropdown>
                        <Separator />
                    </> }
                    <Nav.Item>
                        <Nav.Link href="/products">Produkty</Nav.Link>
                    </Nav.Item>
                    <Separator />
                    <Nav.Item>
                        <Nav.Link href="/cart">{`Košík${items.length ? ` (${items.filter(i=>!i.type || i.type == "GIFT").length})` : ''}`}</Nav.Link>
                    </Nav.Item>
                    <Separator />
                    {loggedIn ? (
                        <>
                            <NavDropdown title={"Profil"} id={"nav-profile"}>
                                <NavDropdown.Item href={"/profile"}>Profil</NavDropdown.Item>
                                <NavDropdown.Item href={"/profile/orders"}>Objednávky</NavDropdown.Item>
                                <NavDropdown.Item href={"/profile/membership"}>Členství</NavDropdown.Item>
                                <NavDropdown.Item href={"/profile/referal"}>Referallové</NavDropdown.Item>
                            </NavDropdown>
                            <Separator />
                            <Nav.Item>
                                <Nav.Link href="/logout">Odhlásit se</Nav.Link>
                            </Nav.Item>
                            {/*<Navbar.Text>
                                Signed in as: <a href="#">{user}</a>
                            </Navbar.Text>*/}
                        </>
                    ) : (
                        <Nav.Item>
                            <Nav.Link href="/login">Přihlásit se</Nav.Link>
                        </Nav.Item>)
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}

const Separator = (props) => <div className="separator">/</div>;

export default KaNavbar;