import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../utils/auth";
import {useLocation, useNavigate} from "react-router-dom";

import '../styles/Login.scss';
import logo from '../assets/img/logo_pruhledny.png';
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@mui/material";
import LanguageSwitcher from "../components/languageSwitcher";
import {useCart} from "react-use-cart";
import KaHead from "./public/head";
import {createPublicPasswordToken} from "../actions/publicActions";
import {toast} from "react-toastify";

const LoginPage = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const location = useLocation();
    const auth = useAuth();
    const navigate = useNavigate();
    const intl = useIntl();
    const {emptyCart} = useCart();

    const [forgot, setForgot] = useState(false);

    const {locale, setLocale} = props;

    const expired = useRef((location.search || "").includes("sessionExpired"));

    useEffect(() => {
        auth.signout();
        localStorage.removeItem("referal")
        if (expired.current) {
            setError("Session expired");
        }
    }, []);

    let from = location.state?.from?.pathname || "/";

    const validate = (email, password) => {
        return !!email && !!password;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (forgot) {
            if (!email) {
                return;
            }
            const res = createPublicPasswordToken(email);
            toast.success("Email na obnovu hesla poslán");
            setForgot(false);
            setEmail("");
            setPassword("");
            return;
        }

        if (!validate(email, password)) {
            return;
        }
        auth.signin(email, password, (success) => {
            if (success) {
                emptyCart();
                navigate(from, {replace: true});
            } else {
                setError("login.invalidCredentials");
            }
        });
    };

    return (<>
        <KaHead page={"Přihlásit se"}/>
        <div className="login">
            <div className="logo"><a href={"/"}><img alt={"Login Logo"} src={logo}/></a></div>
            <LanguageSwitcher locale={locale} setLocale={setLocale}/>
            <form onSubmit={onSubmit}>
                {!!error && <div className="error-panel">{intl.formatMessage({id: error})}</div>}
                <div className="login-component">
                    <label htmlFor="login"><FormattedMessage id={"login.email"}/></label>
                    <input
                        //placeholder={intl.formatMessage({id: "login.email"})}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        name="login"
                        id="login"
                        maxLength={40}
                    />
                </div>
                {!forgot && (
                    <div className="login-component">
                        <label htmlFor="password"><FormattedMessage id={"login.password"}/></label>
                        <input value={password}
                               onChange={(e) => setPassword(e.target.value)}
                               type="password"
                               name="password"
                               id="password"/>
                    </div>
                )}
                <div className="submit">
                    <Button type="submit" color="red" variant="contained">
                        <FormattedMessage id={!forgot ? "login.submit" : "login.submitReset"}/>
                    </Button>
                </div>
                {!forgot && <div className="reset text-center">
                    <Button type="button" color="blue" variant="contained" onClick={() => {
                        setForgot(true);
                    }}>
                        <FormattedMessage id={"login.passwordReset"}/>
                    </Button>
                </div>}
                {forgot && <div className="reset text-center">
                    <Button type="button" color="blue" variant="contained" onClick={() => {
                        setForgot(false);
                    }}>
                        <FormattedMessage id={"login.backToLogin"}/>
                    </Button>
                </div>}
            </form>
        </div>
    </>);
}

export default LoginPage;