import {BASE_API_URL} from "../const";
import {getAllFiltered} from "./commonActions";
import {post, patch, get} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/user`;

export const getUsers = (sortModel, filterModel, pageNumber, pageSize, fulltext) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize, fulltext);
}

export const getUsersActive = (sortModel, filterModel, pageNumber, pageSize, fulltext) => {
    return post(`${BASE_URL}/filter/active`, {sortModel, filterModel, pageNumber, pageSize, fulltext});
}

export const getUserByEmail = (email) => {
    return post(`${BASE_URL}/detail`, {email});
    //return getById(BASE_URL, id);
}

export const updateUser = (body) => {
    return patch(`${BASE_URL}/detail`, body);
}
export const createPasswordToken = (email) => {
    return post(`${BASE_URL}/passwordReset`, {email});
}

export const sendInvitationEmail = () => {
    return post(`${BASE_URL}/invitation`);
}

export const sendWelcomeEmail = (email) => {
    return post(`${BASE_URL}/welcome`, {email});
}

export const getUserById = (id) => {
    return get(`${BASE_URL}/${id}`);
}

export const getUserByIdWithMemberships = (id) => {
    return get(`${BASE_URL}/${id}/memberships`);
}
export const kickUserFromDiscord = (id) => {
    return post(`${BASE_URL}/${id}/leaveDiscord`);
}

export const updateUserDetail = (values, id) => {
    const body = {
        discordId: values.discordId,
        membershipValidTo: values.membershipValidTo,
        membershipId: values.membershipId,
        autoRenewal: values.autoRenewal
    };
    return patch(`${BASE_URL}/${id}/detail`, body);
}

export const addUserMembership = (values, id) => {
    const body = {
        membershipId: values.membership,
        validFrom: values.validFrom,
        validTo: values.validTo,
    };
    return post(`${BASE_URL}/${id}/addMembership`, body);
}