import React, {useCallback, useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import {TextField} from "@mui/material";

const SearchPanel = ({onChange}) => {
    return <div className={"search-panel"}>
        <TextField id="search-panel" label="Hledej..." variant="standard" onChange={onChange}/>
    </div>
}

const PTable = ({createColumns, getData, defaultSort, fulltextEnabled = false, reload = 0}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [rowCount, setRowCount] = React.useState(0);
    const [rel, setRel] = React.useState(0);
    const [sortModel, setSortModel] = useState([
        defaultSort,
    ]);
    const [filterModel, setFilterModel] = useState();
    const [loading, setLoading] = useState(false);
    const [fulltext, setFulltext] = useState();

    const debouncedSearch = useCallback(_.debounce((term) => {
        console.log(`Searching for: ${term}`);
        setPage(0);
        setRel(prevState => prevState + 1);
    }, 500), [createColumns]);

    const handleInputChange = (event) => {
        const {value} = event.target;
        setFulltext(value);
        debouncedSearch(value);
    };

    useEffect(() => {
        //fetchData
        setLoading(true);
        let mounted = true;
        getData(sortModel, filterModel, page, pageSize, fulltext).then(res => {
            if (mounted) {
                setData(res.data || []);
                setRowCount(res.total);
            }
            setLoading(false);
        });
        return () => {
            setLoading(false);
            mounted = false;
        };
    }, [sortModel, filterModel, page, pageSize, fulltextEnabled, reload, rel]);

    const onFilterChange = React.useCallback((filterModel) => {
        setFilterModel(filterModel.items[0]);
    }, []);

    return (
        <>
            {fulltextEnabled && <SearchPanel onChange={handleInputChange} />}
            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <DataGrid
                        autoHeight
                        sortingMode={"server"}
                        onSortModelChange={setSortModel}
                        onFilterModelChange={onFilterChange}
                        paginationMode="server"
                        filterMode="server"
                        onPageChange={setPage}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        rowCount={rowCount}
                        rowsPerPageOptions={[10, 20, 50]}
                        columns={createColumns(intl.formatMessage, navigate)}
                        rows={data}
                        loading={loading}
                    />
                </div>
            </div>
        </>
    );
}

export default PTable;