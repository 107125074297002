import React from 'react';

import {downloadAllInvoice, downloadInvoice, getOrders} from "../../../actions/orderActions";
import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {ORDER_LIST, DASHBOARD, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {toast} from "react-toastify";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'orderId', headerName: intl({id: "order.orderId"})},
        {field: 'user', headerName: intl({id: "order.user"}), valueGetter: (params)=>{
                return params.row.user?.firstName + ' ' + params.row.user?.lastName;
            }},
        {field: 'orderStatus', headerName: intl({id: "order.status"})},
        {field: 'numberOfProducts', headerName: intl({id: "order.numberOfProducts"}), sortable: false, disableColumnMenu: true,},
        {field: 'total', headerName: intl({id: "order.total"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.ORDERS.LIST}/${id}`)),
    ];
}

const OrdersScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, ORDER_LIST]);

    const actions = [
        {
            color: "secondary",
            variant: "contained",
            title: 'order.downloadInvoices',
            onClick: () => {
                downloadAllInvoice(2023).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `faktury_2023.zip`);
                    document.body.appendChild(link);
                    link.click();
                }).catch(err => {
                    toast.error("Nelze stáhnout fakturu");
                })
            }
        }
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'id', sort: 'desc' }}
                getData={getOrders}
            />
        </div>
    )
}

export default OrdersScreen;