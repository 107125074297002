import {BASE_API_URL, PRODUCT_TYPE} from "../const";
import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {get, post} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/course`;

export const getCourses = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const getCoursesToUi = () => {
    return get(BASE_URL + "/all");
}

export const getFreeCoursesToUi = () => {
    return get(`${BASE_API_URL}/api/public/course/all`);
}

export const createCourse = (values) => {
    const formData = courseBody(values);
    return createEntity(BASE_URL, formData);
}

/*const convertTimeToInt = (value) => {
    if (!value) {
        return null;
    }
    const parts = value.split(":");
    if (parts.length == 1) {
        //only seconds
        const seconds = Number(parts[0]);
        return typeof seconds == "number" && seconds >= 0 && seconds < 60;
    } else if (parts.length == 2) {
        // minutes:seconds
        const minutes = Number(parts[0]);
        const seconds = Number(parts[1]);
        return typeof seconds == "number" && typeof minutes == "number" && seconds >= 0 && seconds < 60 && minutes >= 0 && minutes < 60;
    } else if (parts.length == 3) {
        // hours:minutes:seconds
        const hours = Number(parts[0]);
        const minutes = Number(parts[1]);
        const seconds = Number(parts[2]);
        return typeof seconds == "number" && typeof minutes == "number" && typeof hours == "number"
            && seconds >= 0 && seconds < 60 && minutes >= 0 && minutes < 60 && hours >= 0;
    }
    return false;
}*/

const courseBody = (values) => {
    return {
        name: values.name,
        description: values.description,
        order: values.order,
        memberships: values.memberships,
        videos: values.videos.map((v, index)=>{
            const video = {
                name: v.name,
                link: v.link,
                freeLink: v.freeLink,
                thumbnail: v.thumbnail,
                order: index + 1,
            }
            if (v.id && typeof v.id == "number") {
                video.id = v.id;
            }
            return video;
        })
    };
}

export const updateCourse = (values, id) => {
    const formData = courseBody(values);
    return updateEntity(BASE_URL, formData, id)
}

export const getCourseById = (id) => {
    return get(`${BASE_URL}/${id}`);
}