import React, {useEffect, useState} from 'react';

import {useCart} from "react-use-cart";
import KaHead from "./head";
import PublicHeader from "../../components/publicHeader";
import {Col, Container, Row} from "react-bootstrap";
import ReviewsSection from "./reviews";
import PartnersSection from "./partners";
import FooterSection from "./footer";
import getStripe from "../../utils/stripe";
import {Elements, ElementsConsumer, PaymentElement} from "@stripe/react-stripe-js";
import {
    createCryptoPayment,
    createCryptoTotal,
    createPaymentIntent,
    updateUserPaymentDetail
} from "../../actions/paymentActions";
import {useLocation, useSearchParams} from "react-router-dom";

import {Formik} from "formik";
import {MyRadioGroup, MySelect, MyTextArea, MyTextInput} from "../../components/form/components";
import {useIntl} from "react-intl";
import * as Yup from "yup";
import {formatCurrency, resolveVariantName} from "../../utils/utils";
import {useAuth} from "../../utils/auth";
import {
    checkUserByEmail,
    createAndPayOrder,
    createOrder,
    createTmpOrder,
    registerUser
} from "../../actions/publicActions";
import {toast} from "react-toastify";
import {getUserByEmail} from "../../actions/userActions";
import {updateOrderPayment} from "../../actions/orderActions";
import {checkCryptoPayment} from "../../actions/cryptoActions";
import {Cryptobox} from "../../components/cryptobox";
import {Button} from "@mui/material";
import {COUNTRIES, SHIPPING_PRICE} from "../../const";

const PaymentPage = (props) => {
    const location = useLocation();
    const fromCart = location.state?.fromCart;
    const intl = useIntl();
    const {signin, user, token} = useAuth();
    const loggedIn = !!token && !!user;
    const {items, emptyCart} = useCart();
    const [searchParams, setSearchParams] = useSearchParams();
    const referal = localStorage.getItem("referal");
    const finishedPayment = searchParams.get("payment_intent_client_secret");

    const [clientSecret, setClientSecret] = useState();
    const [total, setTotal] = useState(0);
    const [totalUsd, setTotalUsd] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [uuid, setUuid] = useState(0);
    const [loading, setLoading] = useState(false);

    const selectedPickup = items.find(i => i.type == 'SHIPPING')?.name;

    const [submitted, setSubmitted] = useState(false);

    //console.log("FINISHED", searchParams, finishedPayment);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        lastname: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        country: Yup.string().required(intl.formatMessage({id: "validation.required"})),
        street: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        city: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        zip: Yup.string()
            .min(5, intl.formatMessage({id: "validation.tooShort"}))
            .max(6, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        phone: Yup.string()
            .min(9, intl.formatMessage({id: "validation.tooShort"}))
            .max(15, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
        email: Yup.string()
            .email()
            .required(intl.formatMessage({id: "validation.required"})),
        paymentType: Yup.string().required(intl.formatMessage({id: "validation.required"})),
        isLoggedIn: Yup.boolean(),
        password: Yup.string()
            .when("isLoggedIn", {
                is: false,
                then: Yup.string()
                    .min(6, intl.formatMessage({id: "validation.tooShort"}))
                    .required(intl.formatMessage({id: "validation.required"}))
            }),
        passwordConfirm: Yup.string()
            .when("isLoggedIn", {
                is: false,
                then: Yup.string()
                    .min(6, intl.formatMessage({id: "validation.tooShort"}))
                    .oneOf([Yup.ref('password'), null], intl.formatMessage({id: 'validation.passwordEquals'}))
                    .required(intl.formatMessage({id: "validation.required"}))
            }),

    });

    //console.log("Client Secret", clientSecret);

    const [data, setData] = useState({isLoggedIn: loggedIn, country: '', paymentType: 'CARD', referal});

    useEffect(() => {
        //console.log("ACTIVE REFERAL:",localStorage.getItem("referal"));
        if (loggedIn) {
            getUserByEmail(user).then(detail => {
                if (detail) {
                    //console.log("Detail", detail);
                    setData({
                        ...data,
                        firstname: detail.firstName,
                        lastname: detail.lastName,
                        city: detail.city,
                        zip: detail.zip,
                        street: detail.street,
                        country: detail.countryId,
                        phone: detail.phone,
                        email: detail.email,
                    });
                }
            }).catch(err => {
                console.error("Error", err);
            });
        }
    }, []);

    if (!fromCart && !finishedPayment) {
        return <>
            <KaHead page={"Pokladna"}/>
            <PublicHeader titleComp={<h1 className="ka-title">Pokladna</h1>} type={"small"}></PublicHeader>
        </>
    }

    //console.log("Submitted", submitted, data.paymentType, finishedPayment);

    return <>
        <KaHead page={"Pokladna"}/>
        <PublicHeader titleComp={<h1 className="ka-title">Pokladna</h1>} type={"small"}></PublicHeader>
        <section className={"cart bg-dark-green"}>

            {items.length && <Formik

                enableReinitialize={true}
                //validateOnChange={true}
                onSubmit={async (values) => {
                    if (!loggedIn) {
                        const res = await checkUserByEmail(values.email);
                        if (res) {
                            toast.error("Uživatel se zadaným emailem již existuje! Prosím přihlašte se");
                            return;
                        }
                    }
                    setLoading(true);
                    const {email, firstname, lastname} = values;
                    let userEmail;
                    const userForm = {
                        ...values
                    }

                    let stripeCustomerId;
                    let totalTmp;
                    let couponDiscount;
                    if (values.paymentType == "CARD") {
                        const res = await createPaymentIntent(items.filter(i => i.type != "SHIPPING"), firstname, lastname, email);
                        if (!res) {
                            toast.error("Něco se pokazilo, zkuste prosím obnovit stránku a zopakovat postup");
                            console.error("No info from payment service...");
                            setLoading(false);
                            return;
                        }

                        setClientSecret(res.clientSecret);
                        setTotal(res.total);
                        totalTmp = res.total / 100;
                        couponDiscount = res.couponDiscount / 100;
                        setCustomerId(res.customerId);
                        stripeCustomerId = res.customerId;
                        localStorage.setItem("stripeCustomerId", res.customerId);
                        setUuid(res.uuid);

                    } else if (values.paymentType == "CRYPTO" || values.paymentType == "TRANSFER") {
                        const res = await createCryptoTotal(items.filter(i => i.type != "SHIPPING"), email);
                        if (!res) {
                            toast.error("Něco se pokazilo, zkuste prosím obnovit stránku a zopakovat postup");
                            console.error("No info from payment service...");
                            setLoading(false);
                            return;
                        }
                        setTotal(res.total);
                        totalTmp = res.total / 100;
                        couponDiscount = res.couponDiscount / 100;
                    }
                    setSubmitted(true);
                    if (!loggedIn) {
                        //const referal = localStorage.getItem("referal");
                        const referal = values.referal;
                        userForm.customerId = stripeCustomerId;
                        if (referal) {
                            userForm.referal = referal;
                        }
                        values.customerId = stripeCustomerId;
                        const userId = await registerUser(values);
                        if (!userId) {
                            toast.error("Nelze vytvořit nového uživatele! Kontaktujte správce.");
                            setLoading(false);
                            setSubmitted(false);
                            return;
                        }
                        localStorage.removeItem("referal");
                        //localStorage.removeItem("stripeCustomerId");
                        //console.log("Created UserId", userId);
                        userEmail = userForm.email;

                        signin(userEmail, userForm.password, (success) => {
                            if (success) {
                                console.log("Prihlasen");
                            } else {

                            }
                        });
                    } else {
                        // get user from cookie
                        userEmail = user;
                    }

                    const orderObject = {
                        userEmail,
                        customerId,
                        total: totalTmp,
                        couponPrice: couponDiscount,
                        orderPaymentType: values.paymentType,
                        notes: values.notes,
                        products: items.filter(i => !i.type).map(i => ({
                            productVariantId: i.id,
                            quantity: i.quantity,
                            membership: i.product?.membershipDto?.id,
                            price: i.price,
                        })),
                        shipping: selectedPickup ? selectedPickup.name : null,
                        giftId: items.find(i => i.type === 'GIFT')?.id,
                        couponId: items.find(i => i.type === 'COUPON')?.id,
                    };

                    localStorage.setItem("userEmail", userEmail);
                    if (values.paymentType == "TRANSFER" || values.paymentType == "CRYPTO") {
                        const order = await createOrder(orderObject);
                        if (!order) {
                            toast.error("Něco se pokazilo");
                            setSubmitted(false);
                            setLoading(false);
                            return;
                        }
                        localStorage.setItem("orderId", order.orderId);

                        if (values.paymentType == "CRYPTO") {
                            const res = await createCryptoPayment(items.filter(i => i.type != "SHIPPING"), email, "litecoin", order.orderId);
                            if (!res) {
                                toast.error("Něco se pokazilo, zkuste prosím obnovit stránku a zopakovat postup");
                                console.error("No info from payment service...");
                                setLoading(false);
                                return;
                            }
                            setTotalUsd(res.total);
                            setClientSecret(res.clientSecret);
                        }
                    } else {
                        const id = await createTmpOrder(orderObject);
                        localStorage.setItem("orderObject", JSON.stringify(orderObject));
                        localStorage.setItem("tmpOrderId", id);
                        sessionStorage.setItem("orderObject", JSON.stringify(orderObject));
                        /*const fakeOrderId = `O${new Date().getTime() / 1000}`;
                        localStorage.setItem("fakeOrderId", fakeOrderId);*/
                    }
                    setData({...data, paymentType: values.paymentType});
                    emptyCart();
                    setLoading(false);
                }}
                validationSchema={validationSchema}
                initialValues={data}
            >
                {({values, setFieldValue, submitForm, handleSubmit, errors, isSubmitting}) => (
                    <form onSubmit={handleSubmit} className={"payment-form"}>
                        {!finishedPayment && <>
                            <FormFields intl={intl} submitted={submitted} loggedIn={loggedIn} user={user}/>
                            <SummaryDelivery errors={errors} total={total} selectedPickup={selectedPickup}/>
                            <Container>
                                <Row>
                                    <Col>
                                        <MyRadioGroup
                                            label={intl.formatMessage({id: "payment.paymentType"})}
                                            defaultValue={"CARD"}
                                            name="paymentType"
                                            disabled={submitted}
                                            onChange={(value) => {
                                                //setData({...data, paymentType: value});
                                                setFieldValue("paymentType", value);
                                            }}
                                            options={[
                                                {id: "CARD", name: "Kartou (Stripe)"},
                                                {id: "TRANSFER", name: "Bankovním převodem"}
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            {!submitted && (
                                <Container>
                                    <Row className={"cart-empty"}>
                                        <Col xs={12}>
                                            <Button className={"button-green"} type={"submit"}>Pokračovat</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </>}
                    </form>
                )}
            </Formik>}
            {!items.length && !submitted && !finishedPayment && <Container>
                <Row>
                    <Col xs={12} className={"text-center"}>
                        <h3 className={"white"}>Košík je prázdný</h3>
                        <a className={"button-green"} href={"/products"}>Zpět do obchodu</a>
                    </Col>
                </Row>
            </Container>}
        </section>
        {(submitted && data.paymentType == "TRANSFER") && <section className={"bg-dark-black"}>
            <TransferPayment total={total / 100}/>
        </section>}
        {(submitted && data.paymentType == "CRYPTO") && <section>
            <CryptoPayment total={totalUsd}/>
        </section>}
        {((submitted && data.paymentType == "CARD") || finishedPayment) && <section className={"bg-dark-black"}>
            {(clientSecret || finishedPayment) && (
                <Elements stripe={getStripe()} options={{
                    clientSecret, appearance: {
                        theme: 'stripe',
                    }
                }}>
                    <ElementsConsumer>
                        {({stripe, elements}) => (<>
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <>
                                            {clientSecret && !finishedPayment && (<>
                                                    <CheckoutPanel stripe={stripe} elements={elements}
                                                                   loading={loading}/>
                                                    {/*<BitcoinPanel />*/}
                                                </>
                                            )}
                                            {finishedPayment &&
                                                <PaymentDonePanel stripe={stripe}
                                                                  clientSecret={finishedPayment}
                                                                  emptyCart={emptyCart}
                                                                  total={total}
                                                                  items={items}
                                                                  customerId={customerId}
                                                                  loggedIn={loggedIn}
                                                                  selectedPickup={selectedPickup}
                                                />}
                                        </>
                                    </Col>
                                </Row>
                            </Container>
                        </>)}
                    </ElementsConsumer>
                </Elements>
            )}
        </section>
        }
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </>

}

const TransferPayment = ({total}) => {
    const orderId = localStorage.getItem("orderId");

    return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <p className={"white"}>Děkujeme za objednávku. Členství se Vám aktivuje/zboží odešleme hned, jak od Vás obdržíme platbu nebo potvrzení o zaplacení.</p>
                        <p className={"white"}>Celkovou částku prosím odešlete na:</p>
                        <p className={"white"}>číslo účtu: 5809690002/5500<br />
                            BIC/SWIFT: RZBCCZPP<br />
                            IBAN: CZ53 5500 0000 0058 0969 0002<br />
                            variabilní symbol: {orderId}<br />
                            K úhradě: {total.toFixed(2)} Kč</p>
                    </Col>
                </Row>
            </Container>
    )
}

const CryptoPayment = ({total}) => {
    const orderId = localStorage.getItem("orderId");
    const email = localStorage.getItem("userEmail");

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('Checking payment...');
            checkCryptoPayment(orderId)
                .then(res => {
                    if (res && res.status && res.status == "payment_received") {
                        console.log("Platba uspesna");

                    }
                })
                .catch(e => console.error("Err", e));
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    return (<Container>
        <Row>
            <Col xs={12} style={{minHeight: "300px"}}>
                <Cryptobox
                    width={500}
                    height={300}
                    amount={0}
                    amountUSD={total}
                    coinName={"litecoin"}
                    boxID={"65701"}
                    public_key={"65701AALy53NLitecoin77LTCPUBdu6pri47nfPupOVXCvT6uN"}
                    language={"cs"}
                    userFormat={"SESSION"}
                    userID={email}
                    //userID={10}
                    orderID={orderId}
                    cookieName={"KA_LTC"}
                    iframeID={"ka_box"}
                />
            </Col>
        </Row>
    </Container>);
}

const FormFields = ({intl, loggedIn, user, submitted}) => {
    return <Container>
        <Row>
            <Col xs={12} lg={6}>
                <h3>Fakturační údaje</h3>
                <Row>
                    <Col xs={12} md={6}>
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.firstname"})}
                            name="firstname"
                            type="text"
                            disabled={submitted}
                            placeholder={intl.formatMessage({id: "payment.firstname"})}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.lastname"})}
                            name="lastname"
                            type="text"
                            disabled={submitted}
                            placeholder={intl.formatMessage({id: "payment.lastname"})}
                        />
                    </Col>
                </Row>
                {/*<MyTextInput
                    label={intl.formatMessage({id: "payment.company"})}
                    name="company"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.company"})}
                />*/}
                <MySelect
                    label={intl.formatMessage({id: "payment.country"})}
                    name="country"
                    disabled={submitted}
                    options={COUNTRIES}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.city"})}
                    name="city"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.city"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.street"})}
                    name="street"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.street"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.zip"})}
                    name="zip"
                    type="text"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.zip"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.phone"})}
                    name="phone"
                    disabled={submitted}
                    placeholder={intl.formatMessage({id: "payment.phone"})}
                />
                <MyTextInput
                    label={intl.formatMessage({id: "payment.email"})}
                    name="email"
                    type="email"
                    disabled={submitted || loggedIn}
                    placeholder={intl.formatMessage({id: "payment.email"})}
                />
                {!loggedIn && (
                    <>
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.password"})}
                            name="password"
                            type="password"
                            disabled={submitted || loggedIn}
                            placeholder={intl.formatMessage({id: "payment.password"})}
                        />
                        <MyTextInput
                            label={intl.formatMessage({id: "payment.passwordConfirm"})}
                            name="passwordConfirm"
                            type="password"
                            disabled={submitted || loggedIn}
                            placeholder={intl.formatMessage({id: "payment.passwordConfirm"})}
                        />
                    </>
                )}
            </Col>
            <Col xs={12} lg={6}>
                {/*<MyCheckbox
                    name="differentDelivery"
                    disabled={submitted}
                    label={intl.formatMessage({id: "payment.differentDelivery"})}
                />*/}
                <MyTextArea
                    label={intl.formatMessage({id: "payment.notes"})}
                    name="notes"
                    maxLength={1024}
                    disabled={submitted}
                />
                {!loggedIn && <MyTextInput
                    label={intl.formatMessage({id: "payment.referal"})}
                    name="referal"
                    type="number"
                    disabled={submitted || loggedIn}
                    placeholder={intl.formatMessage({id: "payment.referal"})}
                />}
            </Col>
        </Row>
    </Container>
}

const SummaryDelivery = ({total, errors, selectedPickup}) => {
    // console.log("Errors", errors);
    const {items} = useCart();
    const containsNotVirtualProduct = items.filter(i=>!i.type).some(i => !i?.product?.virtual);
    return <Container>
        <Row>
            <Col xs={12}>
                <h3>Vaše objednávka</h3>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <table className={"payment-summary-table"}>
                    <tbody>
                    <tr>
                        <th>Produkt</th>
                        <th>Mezisoučet</th>
                    </tr>
                    {items.filter(i => !i.type).map(variant => {
                        return <tr key={variant.id}>
                            <td>{resolveVariantName(variant)}</td>
                            <td>{formatCurrency(variant.price)}</td>
                        </tr>
                    })}
                    {items.filter(i => i.type == "COUPON").map(c => {
                        return <tr key={"coupon" + c.id}>
                            <td>Slevový kód {c.code}</td>
                            <td>{formatCurrency(-c.price)}</td>
                        </tr>
                    })}
                    {containsNotVirtualProduct &&
                        <>
                            {/*<tr className={"shipping-row"}>
                            <th>Doprava</th>
                            <th>
                                <ZasilkovnaPanel selectedPickup={selectedPickup} setSelectedPickup={setSelectedPickup}/>
                            </th>
                        </tr>*/}
                            <tr>
                                <td>Doprava</td>
                                <td>{formatCurrency(SHIPPING_PRICE)}</td>
                            </tr>
                        </>
                    }
                    {total ? <tr>
                        <th>Cena celkem</th>
                        <th>{formatCurrency(total / 100)}</th>
                    </tr> : <></>}
                    </tbody>
                </table>
            </Col>
        </Row>
    </Container>
}

const PaymentDonePanel = ({stripe, clientSecret, emptyCart, orderId}) => {
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    //console.log("PaymentDone", stripe, clientSecret);

    useEffect(() => {
        setLoading(true);
        if (stripe) {
            stripe.retrievePaymentIntent(clientSecret).then(async ({paymentIntent}) => {

                //console.log("PAYMENT INTENT", paymentIntent);

                switch (paymentIntent.status) {
                    case "succeeded":
                        //console.log("START PROCESSING");

                        const orderObject = localStorage.getItem("orderObject") || sessionStorage.getItem("orderObject");
                        //console.log("orderObject", orderObject);
                        if (!orderObject) {
                            const tmpOrderId = localStorage.getItem("tmpOrderId");
                            if (!tmpOrderId) {
                                toast.error("Vaši platbu jsme obdrželi, ale nepodařilo se vytvořit objednávku. Kontaktujte prosím administrátora.");
                                return;
                            }
                            // TODO get from API
                        }

                        const custId = localStorage.getItem("stripeCustomerId");

                        const paymentBody = {
                            paymentId: paymentIntent.id,
                            amount: paymentIntent.amount,
                            provider: "Stripe",
                            status: paymentIntent.status,
                        };
                        const orderBody = JSON.parse(localStorage.getItem("orderObject"));
                        //const order = await createOrder(orderBody);
                        const order = await createAndPayOrder(orderBody, paymentBody);
                        if (!order) {
                            toast.error("Něco se pokazilo. Kontaktuje administrátora.");
                            return;
                        }

                        await updateUserPaymentDetail(custId, paymentIntent.payment_method);

                        setMessage("Platba proběhla úspěšně! Děkujeme za nákup.");

                        // update order status
                        localStorage.removeItem("referal");
                        localStorage.removeItem("stripeCustomerId");
                        localStorage.removeItem("orderObject");
                        sessionStorage.removeItem("orderObject")
                        emptyCart();
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        // TODO redirect to try again
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }

                window.history.replaceState("", document.title, window.location.pathname);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [stripe]);

    return <h4 className={"white text-center"}>{loading ? "Platba se zpracovává, počkejte prosím..." : message}</h4>
}

const RETURN_URL = process.env.REACT_APP_RETURN_URL;

const CheckoutPanel = ({stripe, elements, loading}) => {

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${RETURN_URL}/payment`,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    const paymentElementOptions = {
        layout: "tabs",
    }

    return (
        <form id={"payment-form"} onSubmit={handleSubmit}>
            <PaymentElement options={paymentElementOptions}/>
            <button type={"submit"} className={"button-green"}
                    disabled={loading || !stripe || !elements}>Vytvořit objednávku
            </button>
        </form>
    );
}


export default PaymentPage;