import React from "react";
import {Helmet} from "react-helmet";

const KaHead = ({page, children}) => {
    const p = !!page ? `| ${page}` : '';
    const title = `Krypto Analýzy ${p}`;
    return <Helmet>
        <meta charSet="utf-8"/>
        <title>{title}</title>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" defer></script>
        <script type="text/javascript" src="https://code.jquery.com/jquery-3.7.0.min.js"></script>
        <link rel="stylesheet" href="https://use.typekit.net/nyt2oqx.css"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LC8TFHLH6B"></script>
        <meta property="og:title" content="Kryptoanalyzy.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kryptoanalyzy.cz" />
        <meta property="og:description" content="Už nás nebavilo sledovat nefunkční strategie na internetu, desítky indikátorů na grafu začátečníků a nejhorší jsou snad skupiny, které neučí základní věci jako risk-management. Rozhodli jsme se jít vlastní cestou, proto jsme pro Vás vybrali spolehlivé nástroje technické analýzy a footprintu a zpracovali na ně desítky kurzů. Toto není schéma rychlého zbohatnutí, obchodování není sprint, ale maraton. Pokud se důkladně učíte, je možné vydělat peníze, musíte ale překonat svá očekávání a řídit svá rizika." />
        <meta property="og:image" content="https://kryptoanalyzy.cz/static/media/logo_pruhledny.bcbbb606667bb6b7281b.png" />
        {/*<script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-LC8TFHLH6B');
        </script>*/}
        {children}
    </Helmet>
}

export default KaHead;