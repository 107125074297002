import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";
import KaHead from "./head";
import {getCoursesToUi, getFreeCoursesToUi} from "../../actions/coursesActions";
import {VideoPlayerNew} from "../../components/video/Video2";
import premiumVavrin from "../../assets/img/premium_vavrin.png";
import vipVavrin from "../../assets/img/vip_vavrin.png";
import {getUserByEmail} from "../../actions/userActions";
import {getStreamsToUi} from "../../actions/streamsActions";

const CoursePage = (props) => {
    const auth = useAuth();
    const loggedIn = !!auth.token && !!auth?.user;
    const intl = useIntl();

    const [courses, setCourses] = useState([]);
    const [userDetail, setUserDetail] = useState();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (loggedIn) {
            getCoursesToUi()
                .then(res => {
                    if (!res) {
                        getFreeCoursesToUi()
                            .then(res => setCourses(res));
                        setErrorMessage("Nemáte oprávnění na tuto stránku");
                    } else {
                        setCourses(res)
                    }
                });
            getUserByEmail(auth.user).then(detail => {
                if (detail) {
                    setUserDetail(detail);
                } else {
                    throw new Error("No user found");
                }
            }).catch(err => {
                console.error("Error", err);
            });
        } else {
            getFreeCoursesToUi()
                .then(res => setCourses(res));
        }
    }, []);

    return (
        <>
            <KaHead page={"Kurzy"}/>
            {userDetail && userDetail.activeMembership ? <PublicHeader
                    titleComp={<h1 className="ka-title">Vaše cesta<br/><span>k úspěchu</span></h1>}></PublicHeader>
                : <PublicHeader
                    titleComp={<h1 className="ka-title">Nemáte aktivní<br/><span>členství!</span></h1>}></PublicHeader>}
            <section className={"courses bg-dark-green"}>
                {/*{errorMessage && <Container><Row><Col><h2 className={"white text-center"}>{errorMessage}</h2></Col></Row></Container>}*/}
                <CoursesPanel courses={courses} loggedIn={loggedIn && !errorMessage}/>
            </section>
            {/*<ReviewsSection/>*/}
            <PartnersSection/>
            <FooterSection/>
        </>
    )
}

const CoursesPanel = ({courses, loggedIn}) => {
    const vip = courses.filter(c => c.membershipNames.includes("VIP"));
    const premium = courses.filter(c => !vip.includes(c));

    return <>
        {vip.length && <MembershipCourses courses={vip} MembershipComponent={<VIP/>} loggedIn={loggedIn}/>}
        {premium.length && <MembershipCourses courses={premium} MembershipComponent={<PREMIUM/>} loggedIn={loggedIn}/>}
    </>

}

const MembershipCourses = ({MembershipComponent, courses, loggedIn}) => {
    return <Container>
        <Row>
            <Col xs={12} lg={6}>
                {MembershipComponent}
            </Col>
        </Row>
        {courses.map(c => {
            const editorDescription = c.description && c.description.startsWith("<");
            return <div key={`course-${c.id}`}>
                <Row className={'course-title'}>
                    <Col xs={12} md={2}>
                        <h4>{c.name}</h4>
                    </Col>
                    <Col xs={12} md={10}>
                        {editorDescription ? <div className={"product-description-value"}
                                                  dangerouslySetInnerHTML={{__html: c.description}}></div> :
                            <p>{c.description}</p>}
                    </Col>
                </Row>
                <Row>
                    {(c.videos || []).sort((v1, v2) => v1 - v2).map(v => {
                        return <Col key={`course-video-${v.id}`} xs={12} sm={6} md={4}>
                            <VideoPlayerNew loggedIn={loggedIn} freeLink={v.freeLink} videoId={v.id}
                                            thumbnail={v.thumbnail}/>
                            <div className={"course-video-name"}><h6>{v.name}</h6></div>
                        </Col>
                    })}
                </Row>
            </div>
        })}
    </Container>
}

const VIP = (props) => {
    return <div className={"course-membership-info"}>
        <div className={"course-membership-info-img"}>
            <img src={vipVavrin}/>
            <h3>VIP</h3>
        </div>
        <div className={"course-membership-info-text"}>
            <h4 className={"ka-title"}>CO NAJDEŠ V <span>VIP KURZU</span></h4>
            <p>Naše VIP kurzy jsou pro začátečníky i pokročilé obchodníky. Získáte komplexní výuku technické analýzy, včetně psychologie, riskování a správy kapitálu. Naučíme vás
                hledat propojení mezi nástroji a konfluence, abyste lépe rozuměli trhu. Všechny nástroje a dovednosti můžete ihned aplikovat v praxi.
                Staňte se obchodníkem, který rozumí trhům a dokáže úspěšně využívat nástroje pro dosažení konzistentních výsledků. 🚀📊💰</p>
        </div>
    </div>
}

const PREMIUM = (props) => {
    return <div className={"course-membership-info"}>
        <div className={"course-membership-info-img"}>
            <img src={premiumVavrin}/>
            <h3>PREMIUM</h3>
        </div>
        <div className={"course-membership-info-text"}>
            <h4 className={"ka-title"}>CO NAJDEŠ V <span>PREMIUM KURZU</span></h4>
            <p>🌟 Pro ty, kdo hledají ještě hlubší porozumění. (Doporučujeme při úrovni pokročilý) Kromě výuky technické
                analýzy, psychologie a správy kapitálu se naučíte pracovat s exkluzivními nástroji, jako jsou Elliott
                Waves, Footprint a naše vlastní inovace.</p>
        </div>
    </div>
}

export default CoursePage;