import Flags from "country-flag-icons/react/3x2";
import React from "react";

const LanguageSwitcher = ({locale, setLocale}) => {
    return (
        <div
            className="sidebar-language"
            onClick={() => locale === 'en' ? setLocale("cs") : setLocale("en")}>
            {
                locale === 'en' ? <Flags.CZ title="Čeština"/>
                    : <Flags.GB title="English" />
            }
        </div>
    );
}

export default LanguageSwitcher;