import {AUTH_API_URL, PAYMENT_API_URL} from "../const";
import {post} from "../utils/api";
import {BASIC_AUTH} from "../utils/authProvider";

const BASE_URL = `${PAYMENT_API_URL}/api/pay`;

export const createPaymentIntent = (items, firstname, lastname, email, customerId = null) => {
    const body = {
        items,
        firstName: firstname,
        lastName: lastname,
        email,
        customerId
    }
    const intent = post(`${BASE_URL}/create-payment-intent`, body);
    return intent;
}

export const createCryptoPayment = (items, email, coinName, orderId) => {
    const body = {
        items,
        email,
        coinName,
        orderId,
    }
    const intent = post(`${BASE_URL}/create-payment-intent-crypto`, body);
    return intent;
}

export const createCryptoTotal = (items, email) => {
    const body = {
        items,
        email
    }
    const intent = post(`${BASE_URL}/compute-total`, body);
    return intent;
}

export const updateUserPaymentDetail = (customerId, paymentMethodId) => {
    const body = {
        customerId,
        paymentMethodId
    }
    const intent = post(`${BASE_URL}/update-customer`, body);
    return intent;
}
