import React, {useEffect, useState} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import FooterSection from "./footer";
import KaHead from "./head";
import {getStreamsToUi} from "../../actions/streamsActions";

import divergence from '../../assets/img/tahaky/Divergence.png';
import rising from '../../assets/img/tahaky/rising.png';
import Patterny01 from '../../assets/img/tahaky/Patterny_01.png';
import Patterny02 from '../../assets/img/tahaky/Patterny_02.png';
import Patterny03 from '../../assets/img/tahaky/Patterny_03.png';
import Patterny04 from '../../assets/img/tahaky/Patterny_04.png';
import Patterny05 from '../../assets/img/tahaky/Patterny_05.png';
import Patterny06 from '../../assets/img/tahaky/Patterny_06.png';
import Patterny07 from '../../assets/img/tahaky/Patterny_07.png';
import Patterny08 from '../../assets/img/tahaky/Patterny_08.png';
import Patterny09 from '../../assets/img/tahaky/Patterny_09.png';
import Patterny10 from '../../assets/img/tahaky/Patterny_10.png';
import Patterny11 from '../../assets/img/tahaky/Patterny_11.png';
import Patterny12 from '../../assets/img/tahaky/Patterny_12.png';
import Patterny13 from '../../assets/img/tahaky/Patterny_13.png';
import Patterny14 from '../../assets/img/tahaky/Patterny_14.png';
import {getUserByEmail} from "../../actions/userActions";
import {useAuth} from "../../utils/auth";

const StreamsPage = (props) => {

    const [userDetail, setUserDetail] = useState();
    const {user} = useAuth();

    useEffect(() => {
        if (user) {
            getUserByEmail(user).then(detail => {
                if (detail) {
                    setUserDetail(detail);
                } else {
                    throw new Error("No user found");
                }
            }).catch(err => {
                console.error("Error", err);
            });
        }
    }, []);

    return userDetail && userDetail.activeMembership ? <>
            <KaHead page={"Taháky"}/>
            <PublicHeader titleComp={<h1 className="ka-title">Taháky</h1>}>
            </PublicHeader>
            <section className={"tahaky bg-dark-green"}>
                <TahakyPanel images={[divergence, Patterny01, Patterny02, Patterny03, Patterny04, Patterny05, Patterny06,
                    Patterny07, Patterny08, Patterny09, Patterny10, Patterny11, Patterny12, Patterny13, Patterny14, rising]} />
            </section>
            <section className={"slovnik bg-dark-black"}>
                <SlovnikPanel obj={slovnikList}/>
            </section>
            <PartnersSection/>
            <FooterSection/>
        </> : <>
        <KaHead page={"Taháky"} />
        <PublicHeader titleComp={<h1 className="ka-title">Nemáte aktivní<br/><span>členství!</span></h1>}></PublicHeader>
        <PartnersSection/>
        <FooterSection/>
    </>;
}

const TahakyPanel = ({images}) => {
    return <Container>
        <Row className={"tahaky-list"}>
            {images.map(c => {
                return <Col key={`tahak-${c.id}`} xs={12} sm={6} md={4}>
                    <img src={c} />
                </Col>
            })}
        </Row>
    </Container>
}

const SlovnikItem = ({title, list}) => {
    return <>
        <h5 className={"white"}>{title}</h5>
        <ul>
            {list.map((l, index)=><li className={"white"} key={"item-" + title + "-" + index}>{l}</li>)}
        </ul>
    </>
}

const SlovnikPanel = ({obj}) => {
    return <Container>
        <Row>
            <Col xs={12} lg={{span: 8, offset: 2}}>
                {obj.map((o, i)=><SlovnikItem key={`slovnik-${i}`} title={o.title} list={o.list} />)}
            </Col>
        </Row>
    </Container>
}

const slovnikList = [
    {
        title: "Bear market (medvědí trh)",
        list: [
            "Cena je po delší dobu zamítaná",
            "Cena dělá lower highs a prodávající mají větší nátlak na cenu jak kupující"
        ]
    },{
        title: "Bull market (býčí trh)",
        list: [
            "Cena po delší dobu stoupá",
            "Dělá se higher high a kupující mají větší nátlak na cenu jak prodávající"
        ]
    },{
        title: "SL",
        list: [
            "stop loss",
            "obchod se nám na zadané úrovni uzavře ztrátu",
        ]
    },{
        title: "TP",
        list: [
            "take profit",
            "na zadané úrovni vezme profit",
        ]
    },{
        title: "Konsolidace",
        list: [
            "Cena se pohybuje sideways",
            "Cena se rozhoduje, kam jít à čekáme buď na prolomení rezistence nebo supportu",
        ]
    },{
        title: "Downtrend",
        list: [
            "Série lower highs a lower low",
        ]
    },{
        title: "Uptrend",
        list: [
            "Série higher high a higher low",
        ]
    },{
        title: "Laddering",
        list: [
            "Nastavíme si několik buy/sell objednávek pro vstup do obchodu",
            "Objednávky nám poté zprůměrují cenu",
        ]
    },{
        title: "POC",
        list: [
            "Point of control",
            "Je to cena, kde se nejvíce obchodovalo po nějakou určitou dobu",
            "Můžeme mít mPOC (monthly POC), dPOC (daily POC) …",
        ]
    },{
        title: "nPOC",
        list: [
            "Je to POC, který nebyl za posledních 24 h otestován",
            "Převážně od něj můžeme čekat odraz při prvním doteku",
            "Samozřejmě vždy je lepší mít další formu confluence",
        ]
    },{
        title: "Swing trading",
        list: [
            "Styl obchodování, který trader drží dny až týdny",
            "Swing trader chce vždy zjistit celkový trend",
        ]
    },{
        title: "Scalp trading",
        list: [
            "Styl zaměřující se profitováním na malých částkách za krátké období",
            "Vyžaduje to disciplínu, soustředění a dobrou knowledge TA",
        ]
    },{
        title: "Spread",
        list: [
            "Rozdíl mezi kupujícími a prodávajícími (příklad: kupující (nabídka) 15$, prodávající (poptávka) 16$. Rozdíl je tedy 1$)",
            "Nechceme obchodovat něco s velkým “spredem“, protože to znamená, že likvidita je malá",
        ]
    },{
        title: "Trend",
        list: [
            "Neboli momentum je celkový směr marketu",
        ]
    },{
        title: "ATH",
        list: [
            "All time high",
            "Nejvyšší cena za celou dobu",
        ]
    },{
        title: "ATL",
        list: [
            "All time low",
            "Nejnižší cena za celou dobu",
        ]
    },{
        title: "Break even (BE)",
        list: [
            "Náš SL posuneme před naší otevírací cenu à zůstaneme profitový, pokud se cena obrátí",
            "Break even používáme např. když dosáhneme TP1",
        ]
    },{
        title: "BTC",
        list: [
            "Bitcoin",
        ]
    },{
        title: "GP",
        list: [
            "Golden pocket",
            "Fibo levely .618-0.66",
        ]
    },{
        title: "Delta",
        list: [
            "Souhrn agresivních obchodů",
            "Ukazuje nám, kdo měl větší kontrolu při dané svíci",
            "Deltu vypočítáme jako rozdíl mezi kupujícími a prodávajícími v dané svíci",
            "Delta je zároveň pozitivní (bullish) nebo negativní (bearish)",
        ]
    },{
        title: "CVD",
        list: [
            "Souhrn delt jdoucích po sobě",
            "delta = 1.5 B",
            "delta = -1 B",
            "delta = 0.5 B",
            "CVD je aktuálně 1B, když vznikne další delta tak CVD bude opět jiné",
        ]
    },{
        title: "VA",
        list: [
            "Value area",
            "VAH – value area high",
            "VAL – value area low",
            "Opět zde můžeme používat mVAH, dVAH, pdVAL …",
        ]
    },{
        title: "EMA",
        list: [
            "Exponential moving average",
        ]
    },{
        title: "EW",
        list: [
            "Elliot Wave",
            "Teorie, kterou vymyslel Ralph Nelson Elliott.",
        ]
    },{
        title: "FA",
        list: [
            "Fundamentální analýza",
        ]
    },{
        title: "FOMO",
        list: [
            "Fear of missing out (pocit, že vám ujíždí vlak)",
            "FOMO se stává převážně novým traderům co nemají ještě zkušenosti s obchodováním",
            "Např. když nekoupíte BTC na ceně 4k a pak toho litujete a přemýšlíte kolik byste teď mohli mít profit",
            "Tato emoce vás může navézt např. k otevření obchodu bez žádného plánu",
        ]
    },{
        title: "H&S",
        list: [
            "Head and shoulder",
            " Pattern",
        ]
    },{
        title: "HH",
        list: [
            "Higher High",
            "Cena vytvoří vrchol, který je vyšší než minulý",
        ]
    },{
        title: "HL",
        list: [
            "Higher Low",
            "Cena vytvoří low, které je vyšší než minulé",
        ]
    },{
        title: "LH",
        list: [
            "Lower High",
            "Cena vytvoří vrchol, který je menší jak ten minulý",
        ]
    },{
        title: "LL",
        list: [
            "Lower Low",
            "Cena vytvoří low, které je menší jak to minulé",
        ]
    },{
        title: "HTF/LTF",
        list: [
            "High/low time frame",
            "LTF – (1m-1H)",
            "HTF – (4h-Y)",
        ]
    },{
        title: "IB",
        list: [
            "Initial balance",
            "První hodina otevření trhu",
        ]
    },{
        title: "LO",
        list: [
            "London open",
        ]
    },{
        title: "MS",
        list: [
            "Market structure",
            "MS jsou v jednoduchosti S/R levely v grafu",
        ]
    },{
        title: "OBV",
        list: [
            "On balance volume",
            "Indikátor zobrazující pozitivní nebo negativní volume",
        ]
    },{
        title: "R:R",
        list: [
            "Risk:reward",
            "Risk to reward ratio nám ukazuje, jak velký profit může trader získat a jak moc může prodělat",
            "R:R by mělo být alespoň 2:1, preferujeme 3:1",
        ]
    },{
        title: "SFP",
        list: [
            "Swing failure pattern",
            "Cena udělá nové high ale uzavře se pod high nebo cena udělá nové low a uzavře nad low",
            "Může být buď bullish nebo bearish",
        ]
    },{
        title: "S/R",
        list: [
            "Support/resistence",
            "Cenová zóna, kde se cena odrazila",
            "Cenová zóna, přes kterou jsme se nemohli dostat",
            "Čím více tuto cenu otestujeme, tím slabší je",
        ]
    },{
        title: "TR",
        list: [
            "Trading range",
            "TR se objeví poté, co se cena začne pohybovat mezi určitým high a low po nějaký čas",
            "Vrchol range je rezistence a spodek support",
        ]
    },{
        title: "TV",
        list: [
            "Trading view",
        ]
    },
];

export default StreamsPage;