import {KA_ADMIN, PRODUCT_TYPE} from "../const";
import {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import bigDecimal from "js-big-decimal";

export const isValidId = (id) => Number.isInteger(id * 1);

export const createAdminLink = (link) => `${KA_ADMIN}/${link}`;

export const getFileSrc = (path) => {
    return process.env.PUBLIC_URL + "/upload/" + path;
}

export const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);

}

export const formatCurrency = (price, currency = "Kč") => {
    if (typeof price == "object") {
        return `${price.round(0, bigDecimal.RoundingModes.CEILING).value} ${currency}`;
    }
    return `${price.toFixed(0)} ${currency}`;
}

export const resolveVariantName = (variant) => {
    if (variant.product.productType == PRODUCT_TYPE.SIMPLE) {
        return variant.product.name;
    }
    return `${variant.product.name} - ${variant.optionValues.map(ov=>ov.valueName).join(", ")}`;
}

export const ToastWithLink = ({text, onClick}) => {
    return <div>
        <p onClick={onClick}>{text}</p>
    </div>
};

export const computeCouponPrice = (items, coupon) => {
    if (coupon.amountType == 'AMOUNT') {
        return coupon.amount;
    }
    let products = [...items];
    if (coupon.couponType == "PRODUCTS") {
        products = items.filter(i=>!i.product?.membershipDto);
    } else if (coupon.couponType == 'MEMBERSHIPS') {
        products = items.filter(i=>i.product?.membershipDto);
    }
    const totalPrice = products.reduce((prev, curr) => {
        prev += curr.price;
        return prev;
    }, 0.0);
    //console.log("Total", new bigDecimal(totalPrice).multiply(new bigDecimal(coupon.amount).divide(new bigDecimal(100))));
    //return new bigDecimal(totalPrice).multiply(new bigDecimal(coupon.amount).divide(new bigDecimal(100)));
    return totalPrice * (coupon.amount / 100);
}

