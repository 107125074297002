import React from 'react';
import {useAuth} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";
import KaHead from "./head";
import hegr from "../../assets/img/team/hegr.png";
import javorek from "../../assets/img/team/javorek.png";
import zindulka from "../../assets/img/team/zindulka.png";
import bartsch from "../../assets/img/team/Bartsch.png";
import aboutVideo from "../../assets/img/about-video.JPG";
import {useNavigate} from "react-router-dom";

const AboutPage = (props) => {
    const auth = useAuth();
    const loggedIn = !!auth.token && !!auth?.user;
    const intl = useIntl();

    const navigate = useNavigate();

    return (
        <>
            <KaHead page={"O nás"}/>
            <PublicHeader titleComp={<h1 className="ka-title">A tohle<br/><span>jsme my!</span></h1>}>
                <Row className={"about-title"}>
                    <Col xs={12} lg={6} className={"text-center"}>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/dfSXPFqKPZU?si=OijXz0w_k9vrDXvC"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="ka-box center">
                            {/*<h2 className="ka-title">Připravujeme pro Vás<br /><span>úvodní video</span></h2>*/}
                            <h2 className="ka-title">Toto musíš<br/><span>vidět než ...</span></h2>
                        </div>
                    </Col>
                </Row>
            </PublicHeader>
            {/*<section className="bg-dark-black">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <h2 className="ka-title">Chceš být součástí naší trading
                                rodiny?<br/><span>Připoj se k nám!</span></h2>
                        </Col>
                        <Col xs={12} md={4}>
                            <button onClick={() => navigate("/membership")} className="choose-membership">Vybrat členství</button>
                        </Col>
                    </Row>
                </Container>
            </section>*/}
            <section className="team bg-dark-green">
                <Container>
                    <Row style={{marginBottom: "30px"}}>
                        <div>
                            <h2 className="ka-title">A tohle jsme<br/><span>my!</span></h2>
                        </div>
                    </Row>
                    <Row>
                        <Profile name={"Kryštof Hegr"} nickname={"Anitramis"}
                                 description={intl.formatMessage({id: "homepage.krystof.description"})} photo={hegr}/>
                        <Profile name={"Tomáš Javorek"} nickname={"Sylvestr"}
                                 description={intl.formatMessage({id: "homepage.sylvestr.description"})} photo={javorek}/>
                        <Profile name={"Tomáš Bartsch"} nickname={"Zabiják"}
                                 description={intl.formatMessage({id: "homepage.zabijak.description"})} photo={bartsch}/>
                        <Profile name={"Jiří Zindulka"} nickname={"Zinda Hai"}
                                 description={intl.formatMessage({id: "homepage.zinda.description"})} photo={zindulka}/>
                    </Row>
                </Container>
            </section>
            {/*<ReviewsSection />*/}
            <PartnersSection />
            <FooterSection />
        </>
    )
}

const Profile = ({name, nickname, photo, description}) => {
    return <Col xl={3} md={6} xs={12} className="profile">
        <div>
            <div className="profile-header">
                <div className="profile-name">
                    <div className="profile-nickname">{nickname}</div>
                    <div className="profile-fullname">{name}</div>
                </div>
                <div className="profile-img">
                    <img src={photo} loading={"lazy"}/>
                </div>
            </div>
            <div className="profile-body">
                {description}
            </div>
        </div>
    </Col>
}

export default AboutPage;