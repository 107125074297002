import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        red: {
            main: '#F15C5F',
            contrastText: '#fff',
        },
        blue: {
            main: '#00b1b8',
            contrastText: '#fff',
        }
    },
});

export default theme;