import React, {useState} from 'react';

import PTable from "../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../components/table/columns";
import {ActionPanel} from "../../components/actionPanel";
import {DASHBOARD, useBreadcrumbsComponent, USER_LIST} from "../../utils/breadcrumbs";
import {
    createPasswordToken,
    getUsers,
    getUsersActive,
    sendInvitationEmailSingle,
    sendWelcomeEmail
} from "../../actions/userActions";
import {Button} from "@mui/material";
import {toast} from "react-toastify";
import {PATH} from "../../const";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'firstName', headerName: intl({id: "user.firstname"})},
        {field: 'lastName', headerName: intl({id: "user.lastname"})},
        {field: 'email', width: 200, headerName: intl({id: "user.email"})},
        ...auditColumns(intl),
        {field: 'passwordReset', headerName: '', width: 200, renderCell: (params) => (
                <Button
                    onClick={()=>{
                        console.log("Params", params);
                        createPasswordToken(params.row.email).then(res=>{
                            toast.success("Token na změnu hesla vytvořen");
                        }).catch(e=>{

                        })
                    }}
                    variant="contained"
                    size="small"
                    //style={{ marginLeft: 16 }}
                    tabIndex={params.hasFocus ? 0 : -1}
                >Password Reset</Button>

            )},
        {field: 'inviteEmail', headerName: '', width: 200, renderCell: (params) => (
                <Button
                    onClick={()=>{
                        console.log("Params", params);
                        sendWelcomeEmail(params.row.email).then(res=>{
                            toast.success("Uvítací email zaslán");
                        }).catch(e=>{

                        })
                    }}
                    variant="contained"
                    size="small"
                    //style={{ marginLeft: 16 }}
                    tabIndex={params.hasFocus ? 0 : -1}
                >Uvítací email</Button>

            )},
        detailColumn(intl, (id) => navigate(`${PATH.USERS.LIST}/${id}`)),
    ];
}

const UserScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, USER_LIST]);

    const [activeOnly,setActiveOnly] = useState(true);
    const [reload,setReload] = useState(0);

    const actions = [
        /*{color: "success",
            variant: "contained",
            title: "user.sendInvitationEmail",
            onClick: () => {

            },
        }*/
    ];

    if (activeOnly) {
        actions.push({
            color: "secondary",
            variant: "contained",
            title: 'user.showAll',
            onClick: () => {
                setActiveOnly(false);
                setReload(prevState => prevState + 1);
            }
        });
    } else {
        actions.push({
            color: "secondary",
            variant: "contained",
            title: 'user.showActiveOnly',
            onClick: () => {
                setActiveOnly(true);
                setReload(prevState => prevState + 1);
            }
        });
    }

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                fulltextEnabled={true}
                createColumns={createColumns}
                defaultSort={{field: 'lastName', sort: 'asc' }}
                getData={activeOnly ? getUsersActive : getUsers}
                reload={reload}
            />
        </div>
    )
}

export default UserScreen;