import {Button, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import _ from 'lodash';

export const addNewAction = (link) => {
    return {
        color: "success",
        variant: "contained",
        title: "common.addNew",
        link: link,
    }
};

export const addBackAction = (link) => {
    return {
        color: "red",
        variant: "contained",
        title: "common.back",
        link: link,
    }
};

export const addSaveAction = (onClick) => {
    return {
        color: "success",
        variant: "contained",
        title: "common.save",
        onClick: onClick,
    }
}

export const ActionPanel = ({actions}) => {
    return <div>
        <div className={"action-panel"}>
            {actions.map((action, i) => {
                const button = <Button key={`action-${i}`} onClick={action.onClick} color={action.color}
                                       variant={action.variant}>
                    <FormattedMessage id={action.title}/>
                </Button>;
                if (!!action.link) {
                    return <Link key={`action-${i}`} to={action.link}>
                        {button}
                    </Link>
                } else {
                    return button;
                }

            })}
        </div>
    </div>
}

