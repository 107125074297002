import {Formik} from 'formik';
import {Stack} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {MyFileUpload, MyTextInput} from "../../../components/form/components";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import {ActionPanel, addBackAction, addSaveAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import TitledForm from "../../../components/form/Form";
import {DASHBOARD, DETAIL, GIFT_LIST, NEW, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {isValidId} from "../../../utils/utils";
import {Col, Container, Row} from "react-bootstrap";
import {createGift, getGiftById, updateGift} from "../../../actions/giftActions";

const GiftDetail = () => {
    const intl = useIntl();
    const {id} = useParams();
    useBreadcrumbsComponent([DASHBOARD, GIFT_LIST, isValidId(id) ? DETAIL : NEW]);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
    });

    const onSubmit = (values) => {
        if (isValidId(id)) {
            updateGift(values, id)
                .then(response => {
                    navigate(PATH.GIFTS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        } else {
            createGift(values)
                .then(response => {
                    navigate(PATH.GIFTS.LIST);
                }).catch(err => {
                console.warn("Err", err);
            });
        }
    }

    const giftSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({id: "validation.tooShort"}))
            .max(50, intl.formatMessage({id: "validation.tooLong"}))
            .required(intl.formatMessage({id: "validation.required"})),
    });

    const initData = useCallback(data => {
        console.log("Data", data);
        if (data.image) {
            const img = data.image;
            setData({
                ...data,
                image: {id: img.id, name: img.filename, preview: `${process.env.PUBLIC_URL + "/upload/" + img.fullPath}`}
            });
        } else {
            setData(data);
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        if (isValidId(id)) {
            getGiftById(id)
                .then(res => {
                    if (mounted) {
                        initData(res.data[0]);
                    }
                });
        }
        return () => mounted = false;
    }, [id]);

    const actions = (values) => [
        addBackAction(PATH.GIFTS.LIST),
        addSaveAction(() => onSubmit(values))
    ];

    return <div>
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={giftSchema}
            initialValues={data}
        >
            {({values}) => (<>
                <ActionPanel actions={actions(values)}/>
                <TitledForm
                    title={intl.formatMessage({id: isValidId(id) ? "gift.detailTitle" : "gift.newTitle"})}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyTextInput
                                        label={intl.formatMessage({id: "gift.name"})}
                                        name="name"
                                        type="text"
                                        placeholder={intl.formatMessage({id: "gift.name"})}
                                    />
                                </Stack>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Stack spacing={10}>
                                    <MyFileUpload
                                        label={intl.formatMessage({id: "gift.image"})}
                                        name="image"
                                        multiple={false}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </TitledForm>
            </>)}
        </Formik>
    </div>
}

export default GiftDetail;