import {BASE_API_URL} from "../const";
import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {get, post, getBlob, patch} from "../utils/api.js";

const BASE_URL = `${BASE_API_URL}/api/tmpOrder`;

export const getTmpOrders = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const getTmpOrderById = (id) => {
    return get(BASE_URL + "/" + id);
}

export const convertToOrder = (id) => {
    return post(BASE_URL + `/${id}/convertToOrder`, {});
}