import {loadStripe} from "@stripe/stripe-js";

let stripePromise;

const getStripe = () => {
    if (!stripePromise) {
        //stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
        //stripePromise = loadStripe('pk_test_51JeMCbECiTcZVwbNgVnvL4JlTbosLr4WerOaqPx8hq58507bNx1hkAEepYD9bBfDtQPltNjcXjZ1FNL6phZPhUMc003DdUP04c');
        stripePromise = loadStripe('pk_live_51JeMCbECiTcZVwbNDyiC851XAMq3MUOC63yJHjsPFXPMUTsYzuSjPw21ZDIRQCYp6Pf6BL6S2bAn1DyBauJDZDsB00eeb87XLk');
    }
    return stripePromise;
}

export default getStripe;