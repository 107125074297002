import React, {useEffect, useState} from 'react';
import {useAuth} from "../../utils/auth";

import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";

import premiumVavrin from "../../assets/img/premium_vavrin.png";
import vipVavrin from "../../assets/img/vip_vavrin.png";
import merch from "../../assets/img/merch.png";
import notebook from "../../assets/img/notebook.png";
import triko from "../../assets/img/triko.png";
import trikoBile from "../../assets/img/merch/bile01.png";
import trikoCerne from "../../assets/img/merch/cerne01.png";
import trikoCerne2 from "../../assets/img/merch/cerne02.png";
import seed from "../../assets/img/merch/seed01.png";
import book from "../../assets/img/merch/book.png";
import otvirak from "../../assets/img/merch/otvirak.png";
import zapalovac from "../../assets/img/merch/zapalovac.png";
import PublicHeader from "../../components/publicHeader";
import PartnersSection from "./partners";
import ReviewsSection from "./reviews";
import FooterSection from "./footer";
import KaHead from "./head";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import ImageSlider from "../../components/Slider/imageSlider";
import {getPublicProductMemberships} from "../../actions/publicActions";
import SocialSection from "./social";

const slideFromLeft = {
    visible: {opacity: 1, x: 0, transition: {duration: 0.4}},
    hidden: {opacity: 0, x: -350}
};
const slideFromRight = {
    visible: {opacity: 1, x: 0, transition: {duration: 0.4}},
    hidden: {opacity: 0, x: 350}
};

const Animated = ({variant, children}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variant}
            className="square"
        >{children}</motion.div>
    );
}

const HomePage = (props) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [memberships,setMemberships] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const referal = searchParams.get("ref");

    useEffect(() => {
        if (referal && !auth.token) {
            console.log("Saving referal to LS");
            localStorage.setItem("referal", referal);
        }
        getPublicProductMemberships().then(res=>setMemberships(res));
    }, [])

    return (
        <>
            <KaHead>
                {/*<script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async crossOrigin="anonymous" />*/}
                <script type="text/javascript" src="/trustpilot.bootstrap.min.js" crossOrigin="anonymous"/>
                <script src="//code.jivosite.com/widget/UxzfkQ7Dqx" async></script>
            </KaHead>
            <PublicHeader titleComp={<h1 className="ka-title">Obchoduj<br/><span>jako master!</span></h1>}>
                <Row id={"hp-membership-info"}>
                    <Col xs={12} lg={4}>
                        <div className="ka-box">
                            <h2 className="ka-title">Slyšel jsi o našem <span>členství?</span></h2>
                            <p className={"white cursive"}>Naše členství obsahuje nabušené informace ze&nbsp;světa technické analýzy
                                a&nbsp;footprintu. Nenajdeš tu strategie a&nbsp;indikátory, které nepřináší žádnou přidanou
                                hodnotu. Provedeme tě světem obchodování za&nbsp;pomocí prvotřídních nástrojů KA.</p>
                            <div className="d-flex justify-content-between hp-membership">
                                <div>
                                    <img src={vipVavrin} loading={"lazy"}/>
                                    <h4>Vip</h4>
                                </div>
                                <div>
                                    <img src={premiumVavrin} loading={"lazy"}/>
                                    <h4>Premium</h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="ka-box">
                            <ImageSlider images={[trikoBile, trikoCerne, book, trikoCerne2, otvirak, seed, zapalovac]}/>
                            <div className={'ka-box-text'}>
                                <h2 className="ka-title">Zajímají tě<br/><span>parádní produkty?</span></h2>
                                <p className={"white cursive"}>Nejkvalitnější produkty, které nesmí chybět u&nbsp;tebe doma.
                                    Obchodování je on-line záležitost, tak co si pořídit něco hmotného?</p>
                                <p className={"white cursive"}>Jednou trader, navždy trader.</p>
                                <div className={"d-none"}>
                                    <img src={triko}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="ka-box">
                            <h2 className="ka-title">Za jakým účelem <br/><span>jsme začali?</span></h2>
                            <p className={"white cursive"}>Už nás nebavilo sledovat nefunkční strategie na&nbsp;internetu, desítky
                                indikátorů na&nbsp;grafu začátečníků a&nbsp;nejhorší jsou snad skupiny, které neučí základní věci
                                jako risk-management.</p>
                            <p className={"white cursive"}>Rozhodli jsme se jít vlastní cestou, proto jsme pro&nbsp;Vás vybrali
                                spolehlivé nástroje technické analýzy a&nbsp;footprintu a&nbsp;zpracovali na&nbsp;ně desítky kurzů.</p>
                            <p className={"white cursive"}>Toto není schéma rychlého zbohatnutí, obchodování není sprint, ale
                                maraton. Pokud se důkladně učíte, je možné vydělat peníze, musíte ale&nbsp;překonat svá
                                očekávání a&nbsp;řídit svá rizika.</p>
                            <p className={"green bold"}>Tvrdá práce se vyplácí!</p>
                        </div>
                    </Col>
                </Row>
            </PublicHeader>
            <section className="bg-dark-black">
                <Container>
                    <Row>
                        <Col xs={12} md={{span: 7, offset: 1}}>
                            <h2 className="ka-title text-center text-lg-start">Chceš být součástí naší trading
                                rodiny?<br/><span>Připoj se k&nbsp;nám!</span></h2>
                        </Col>
                        <Col xs={12} md={3} className={"text-center"}>
                            <button onClick={() => navigate("/membership")} className="choose-membership">Vybrat
                                členství
                            </button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-dark-green">
                <Container>
                    <Row className={"hp-memberships-section"}>
                        <Col xs={12} md={{span: 4, offset: 1}}>
                            <MembershipItem name={"VIP"} photo={vipVavrin} buttonClass={"blue"} membership={memberships.find(m=>m.name.toLowerCase() == "vip")}
                                            features={["30 kurzů", "stream 1x týdně", "Denní shrnutí trhu", "Chat pro VIP", "24h podpora na chatu", "x", "x", "x", "x","až 10% sleva na produkty","Sleva na TRADINGVIEW", "x"]}/>
                        </Col>
                        <Col xs={12} md={{span: 4, offset: 2}}>
                            <MembershipItem name={"PREMIUM"} photo={premiumVavrin} buttonClass={"green"} membership={memberships.find(m=>m.name.toLowerCase() == "premium")}
                                            features={["41 kurzů", "stream 2x týdně", "Denní shrnutí trhu", "Chat pro VIP i Premium", "24h podpora na chatu", "Daily plán", "Kurzy Exocharts / Templates", "1vs1 Trénink", "Livetrading","až 30% sleva na produkty","Sleva na TRADINGVIEW", "EXOCHARTS zdarma"]}/>
                        </Col>
                    </Row>
                </Container>
                <Container className={"free-courses"}>
                    <Row>
                        <Col xs={12} md={{span: 7, offset: 1}}>
                            <h2 className="ka-title text-center text-lg-start">Zajímá tě jak vypadají naše kurzy?<br/><span>U nás nekupuješ zajíce v&nbsp;pytli!</span></h2>
                        </Col>
                        <Col xs={12} md={3} className={"text-center"}>
                            <Link to={"/courses"}>
                                <button className="choose-membership">Podívat se</button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-dark-black">
                <Container className={"hp-merch"}>
                    <Row>
                        <Col xs={12} lg={6} className="center">
                            <h2 className="ka-title text-center text-lg-start">Merch, ochrana seedu nebo kniha plná informací o&nbsp;technické analýze!<br/><span>to jinde nenajdeš</span>
                            </h2>
                        </Col>
                        <Col xs={12} lg={6} className={'overflow-hidden'}>
                            <Animated variant={slideFromRight}>
                                <img src={merch} loading={"lazy"} style={{width: '100%'}}/>
                            </Animated>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-dark-green">
                <Container className={"hp-merch"}>
                    <Row>
                        <Col xs={12} md={6} className={'overflow-hidden'}>
                            <Animated variant={slideFromLeft}>
                                <img src={notebook} loading={"lazy"} style={{width: '100%'}}/>
                            </Animated>
                        </Col>
                        <Col xs={12} md={6} className="center">
                            <h2 className="ka-title text-center text-lg-start">Každý den analyzujeme trhy a&nbsp;připravujeme naše členy na&nbsp;možné
                                pohyby<br/><span>Profituj s námi!</span></h2>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ReviewsSection/>
            <section className="ready bg-dark-green">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="ka-title">Jsi připravený jít do toho s&nbsp;námi, <span>začni ještě dnes!</span>
                            </h2>
                        </Col>
                    </Row>
                    <Row className={"text-center"}>
                        <Col>
                            <button onClick={()=>navigate("/membership")} className={"ready cursive"}>Jsem <span className={"normal"}>připraven!</span></button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <PartnersSection/>
            <FooterSection/>
            <SocialSection/>
        </>
    )
}

const MembershipItem = ({name, photo, features, buttonClass, membership}) => {
    const navigate = useNavigate();
    return <div className={"ka-box"}><div className="membership-item">
        <div className="membership-header">
            <img src={photo} loading={"lazy"}/>
            <h4>{name}</h4>
        </div>
        <div className={"membership-features"}>
            <ul>
                {features.map((d, index) => <li key={`feature-${name}-${index}`}>{d}</li>)}
            </ul>
        </div>
        <div className={"membership-buy"}>
            <button onClick={()=>{
                if(membership) {
                    navigate("/products/" + membership.id);
                }
            }} className={buttonClass}>Koupit {name}</button>
        </div>
    </div>
    </div>
}

export default HomePage;