import React from 'react';

import PTable from "../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../components/table/columns";
import {ActionPanel, addNewAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import {DASHBOARD, STREAMS_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {getStreams} from "../../actions/streamsActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "stream.name"})},
        ...auditColumns(intl),
        detailColumn(intl, (id) => navigate(`${PATH.STREAMS.LIST}/${id}`)),
    ];
}

const StreamsScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, STREAMS_LIST]);

    const actions = [
        addNewAction(PATH.STREAMS.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions}/>
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'created', sort: 'desc'}}
                getData={getStreams}
            />
        </div>
    )
}

export default StreamsScreen;