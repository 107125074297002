import {Form} from "formik";

const TitledForm = ({title, children}) => {
    return <div className="titled-form">
        <h4>{title}</h4>
        <Form>
            {children}
        </Form>
    </div>
}

export default TitledForm;