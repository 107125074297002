import React from 'react';

import PTable from "../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../components/table/columns";
import {ActionPanel, addNewAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import {DASHBOARD, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {COURSES_LIST} from "../../utils/breadcrumbs";
import {getCourses} from "../../actions/coursesActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "course.name"})},
        {field: 'order', headerName: intl({id: "course.order"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.COURSES.LIST}/${id}`)),
    ];
}

const CoursesScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, COURSES_LIST]);

    const actions = [
        addNewAction(PATH.COURSES.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'name', sort: 'asc' }}
                getData={getCourses}
            />
        </div>
    )
}

export default CoursesScreen;