import {BASE_API_URL, PRODUCT_TYPE} from "../const";
import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {get, post} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/product`;

export const getProducts = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const getProductsForPublic = () => {
    return get(BASE_URL + "/pub");
}

export const getProductDetailForPublic = (id) => {
    return get(BASE_URL + "/pub/" + id);
}

const createProductBody = (values) => {
    const req = new FormData();
    const {image, ...rest} = values;
    if (typeof image === "File") {
        req.append("image", image, image.name);
    }
    req.append("body", JSON.stringify(rest));
    return req;
}

export const createProduct = (values) => {
    const formData = productBody(values);
    return createEntity(BASE_URL, formData);
}

const productBody = (values) => {
    const type = values.productType;
    var formData = new FormData();
    formData.append("name", values.name);
    if (values.description) {
        formData.append("description", values.description);
    }
    formData.append("productType", type);
    formData.append("categories", JSON.stringify(values.categories));
    formData.append("virtual", !!values.virtual);
    formData.append("discountExcluded", !!values.discountExcluded);
    if (values.membership) {
        formData.append("membershipId", values.membership);
    }
    if (!values.image?.id && values.image) {
        formData.append("img", values.image);
    }
    if (values.gallery) {
        const existing = values.gallery.filter(g=>!!g.id).map(g=>g.id);
        const newImages = values.gallery.filter(g=>!g.id);
        if (existing.length) {
            formData.append("galleryIds", existing);
        }
        if (newImages.length) {
            for (let i = 0; i < newImages.length; i++) {
                formData.append("gallery[]", newImages[i]);
            }
        }
    }
    formData.append("price", values.price);
    formData.append("priceLegacy", values.priceLegacy);
    if (type == PRODUCT_TYPE.VARIANTS) {
        formData.append("options", JSON.stringify(values.options.map(o=>o.optionId)))
        formData.append("variants", JSON.stringify(values.variants.map(v=>{
            const variant = {
                description: v.description,
                optionValues: v.optionValues.map(ov=>({
                    optionId: ov.optionId,
                    valueId: ov.valueId,
                }))
            };
            if (typeof v.id == "number") {
                variant.id = v.id;
            }
            return variant;
        })));
    }
    return formData;
}

export const updateProduct = (values, id) => {
    const formData = productBody(values);
    return updateEntity(BASE_URL, formData, id)
}

export const getProductById = (id) => {
    return get(`${BASE_URL}/${id}`);
    //return getById(BASE_URL, id);
}