import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = ({ images }) => {
    const settings = {
        //dots: true,
        //fade: true,
        infinite: true,
        speed: 2500,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 1,
        slidesToScroll: 2,
        arrows: false,
        centerMode: true,
        rtl: true,
        cssEase: "linear"
    };

    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={`Image ${index}`} />
                </div>
            ))}
        </Slider>
    );
};

export const TextSlider = ({ slides, className }) => {
    const settings = {
        //dots: true,
        fade: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        //centerMode: true,
    };

    return (
        <Slider className={className} {...settings}>
            {slides.map((slide, index) => (
                <div className={`${className}-item`} key={index} style={{width: '100%'}}>
                    <h5>{slide.name}</h5>
                    <p>{slide.text}</p>
                </div>
            ))}
        </Slider>
    );
};

export default ImageSlider;
