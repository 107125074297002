import {BASE_API_URL} from "../const";
import {getAllFiltered} from "./commonActions";
import {get} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/tag`;

export const getTags = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const getTagsToUi = () => {
    return get(BASE_URL + "/all");
}