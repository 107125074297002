import React from 'react';

import PTable from "../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../components/table/columns";
import {ActionPanel, addNewAction} from "../../components/actionPanel";
import {PATH} from "../../const";
import {DASHBOARD, REFERAL_DISCOUNT_LIST, useBreadcrumbsComponent} from "../../utils/breadcrumbs";
import {getReferalDiscounts} from "../../actions/referalDiscountActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'level', headerName: intl({id: "referalDiscount.level"})},
        //{field: 'discountPercent', headerName: intl({id: "referalDiscount.discountPercent"})},
        //{field: 'discountAmount', headerName: intl({id: "referalDiscount.discountAmount"})},
        ...auditColumns(intl),
        detailColumn(intl, (id) => navigate(`${PATH.REFERAL_DISCOUNT.LIST}/${id}`)),
    ];
}

const ReferalDiscountScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, REFERAL_DISCOUNT_LIST]);

    const actions = [
        addNewAction(PATH.REFERAL_DISCOUNT.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions}/>
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'level', sort: 'asc'}}
                getData={getReferalDiscounts}
            />
        </div>
    )
}

export default ReferalDiscountScreen;