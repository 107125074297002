import fb from "../../assets/img/social/fb.png";
import fba from "../../assets/img/social/fba.png";
import ig from "../../assets/img/social/ig.png";
import iga from "../../assets/img/social/iga.png";
import yt from "../../assets/img/social/yt.png";
import yta from "../../assets/img/social/yta.png";
import tw from "../../assets/img/social/tw.png";
import twa from "../../assets/img/social/twa.png";
import tt from "../../assets/img/social/tt.png";
import tta from "../../assets/img/social/tta.png";

import fbm from "../../assets/img/social/mobile/01.png";
import fbma from "../../assets/img/social/mobile/01a.png";
import igm from "../../assets/img/social/mobile/02.png";
import igma from "../../assets/img/social/mobile/02a.png";
import ytm from "../../assets/img/social/mobile/03.png";
import ytma from "../../assets/img/social/mobile/03a.png";
import twm from "../../assets/img/social/mobile/04.png";
import twma from "../../assets/img/social/mobile/04a.png";
import ttm from "../../assets/img/social/mobile/05.png";
import ttma from "../../assets/img/social/mobile/05a.png";
import React, {useCallback, useState} from "react";
import Image from "../../components/HoverImage";

const SocialSection = (props) => {
    const [hovered,setHovered] = useState([0,0,0,0,0]);
    const onMouseOver = useCallback((id) => {
        const newHover = [...hovered];
        newHover[id] = 1;
        setHovered(newHover);
    },[])
    const onMouseOut = useCallback((id) => {
        const newHover = [...hovered];
        newHover[id] = 0;
        setHovered(newHover);
    },[])
    return <div id={"social-icons"} onMouseOut={()=>setHovered([0,0,0,0,0])}>
        <div className={"d-none d-lg-flex social-inner"}>
            {/* TODO rewrite onMouseOut repeating */}
            <Image src={fb} hoverSrc={fba} link={"https://www.facebook.com/kryptoanalyzyCZ"} onMouseOut={()=>onMouseOut(0)} onMouseOver={()=>onMouseOver(0)} hovered={!!hovered[0]}onMouseOut={()=>onMouseOut(0)} onMouseOver={()=>onMouseOver(0)} hovered={!!hovered[0]}/>
            <Image src={ig} hoverSrc={iga} link={"https://www.instagram.com/_kryptoanalyzy/"} onMouseOut={()=>onMouseOut(1)} onMouseOver={()=>onMouseOver(1)} hovered={!!hovered[1]}/>
            <Image src={yt} hoverSrc={yta} link={"https://www.youtube.com/@kryptoanalyzy1356/featured"} onMouseOut={()=>onMouseOut(2)} onMouseOver={()=>onMouseOver(2)} hovered={!!hovered[2]}/>
            <Image src={tw} hoverSrc={twa} link={"https://twitter.com/kryptoanalyzy"} onMouseOut={()=>onMouseOut(3)} onMouseOver={()=>onMouseOver(3)} hovered={!!hovered[3]} />
            <Image src={tt} hoverSrc={tta} link={"https://www.tiktok.com/@_kryptoanalyzy"} onMouseOut={()=>onMouseOut(4)} onMouseOver={()=>onMouseOver(4)} hovered={!!hovered[4]} />
        </div>
        <div className={"d-flex d-lg-none social-inner"}>
            {/* TODO rewrite onMouseOut repeating */}
            <Image src={fbm} hoverSrc={fbma} link={"https://www.facebook.com/kryptoanalyzyCZ"} onMouseOut={()=>onMouseOut(0)} onMouseOver={()=>onMouseOver(0)} hovered={!!hovered[0]}onMouseOut={()=>onMouseOut(0)} onMouseOver={()=>onMouseOver(0)} hovered={!!hovered[0]}/>
            <Image src={igm} hoverSrc={igma} link={"https://www.instagram.com/_kryptoanalyzy/"} onMouseOut={()=>onMouseOut(1)} onMouseOver={()=>onMouseOver(1)} hovered={!!hovered[1]}/>
            <Image src={ytm} hoverSrc={ytma} link={"https://www.youtube.com/@kryptoanalyzy1356/featured"} onMouseOut={()=>onMouseOut(2)} onMouseOver={()=>onMouseOver(2)} hovered={!!hovered[2]}/>
            <Image src={twm} hoverSrc={twma} link={"https://twitter.com/kryptoanalyzy"} onMouseOut={()=>onMouseOut(3)} onMouseOver={()=>onMouseOver(3)} hovered={!!hovered[3]} />
            <Image src={ttm} hoverSrc={ttma} link={"https://www.tiktok.com/@_kryptoanalyzy"} onMouseOut={()=>onMouseOut(4)} onMouseOver={()=>onMouseOver(4)} hovered={!!hovered[4]} />
        </div>
    </div>
}

export default SocialSection;