import {AUTH_API_URL, BASE_API_URL} from "../const";
import {post, publicGet, publicPost} from "../utils/api";
import {BASIC_AUTH} from "../utils/authProvider";
import {getAllFiltered} from "./commonActions";

const BASE_URL = `${BASE_API_URL}/api/public`;

export const getPublicProducts = () => {
    return publicGet(BASE_URL + "/product/");
}

export const getPublicProductMemberships = () => {
    return publicGet(BASE_URL + "/product/memberships");
}

export const getPublicProductDetail = (id) => {
    return publicGet(BASE_URL + "/product/" + id);
}

export const checkUserByEmail = (email) => {
    return publicPost(BASE_URL + "/user/email", {email});
}

export const changePassword = (token, password) => {
    return publicPost(BASE_URL + "/user/changePassword/" + token, {password});
}

export const getPasswordToken = (token) => {
    return publicGet(BASE_URL + "/user/passwordToken/" + token);
}

export const checkCoupon = (code) => {
    return publicGet(BASE_URL + "/coupon/valid/" + code);
}

export const registerUser = (user) => {
    return post(`${AUTH_API_URL}/api/auth/signup`, user, {"Authorization": BASIC_AUTH});
}

export const createPublicPasswordToken = (email) => {
    return post(`${BASE_URL}/user/passwordReset`, {email});
}

export const createOrder = (order) => {
    return publicPost(BASE_URL + "/order/create", order);
}

export const createAndPayOrder = (order, payment) => {
    return post(BASE_URL + "/order/pay", {order, payment});
}

export const createTmpOrder = (order) => {
    return publicPost(BASE_URL + "/order/create-tmp", order);
}

export const getPublicGifts = () => {
    return publicGet(BASE_URL + "/gift/all");
}

export const getPublicReviews = () => {
    return publicGet(BASE_URL + "/review");
}