import React, {useCallback, useEffect, useState} from 'react';
import KaHead from "../head";
import PublicHeader from "../../../components/publicHeader";
import PartnersSection from "../partners";
import FooterSection from "../footer";
import {useAuth} from "../../../utils/auth";
import {downloadInvoice, getUserOrderDetail} from "../../../actions/orderActions";
import {Col, Container, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {formatCurrency, getFileSrc, isValidId} from "../../../utils/utils";
import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {PRODUCT_TYPE} from "../../../const";
import {FaPrint} from 'react-icons/fa';

const ProfileOrderDetailPage = () => {
    const {user} = useAuth();

    let {id} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState();

    useEffect(() => {
        if (isValidId(id)) {
            setLoading(true);
            try {
                getUserOrderDetail(id).then(setOrder);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }

        }
    }, [user]);

    const printInvoice = useCallback((id, orderId) => {
        downloadInvoice(id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `faktura_${orderId}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            toast.error("Nelze stáhnout fakturu");
        })
    }, []);

    return order && !loading ? <>
        <KaHead page={"Objednávka " + order.orderId}/>
        <PublicHeader
            type={"small"}
            titleComp={<h1 className="ka-title">{`Objednávka ${order.orderId}`}</h1>}></PublicHeader>
        <section className={"profile-order-detail bg-dark-green"}>
            <Container>
                <Row>
                    <Col className={"buttons-row"}>
                        <Button className={"button-green"} startIcon={<ArrowBackIcon/>}
                                onClick={() => navigate("/profile/orders")}>
                            Zpět
                        </Button>
                        <Button className={"button-green"} startIcon={<FaPrint />}
                                onClick={() => {printInvoice(order.id, order.orderId)}}>
                            Tisk faktury
                        </Button>
                    </Col>
                </Row>
            </Container>
            <OrderDetail order={order} printInvoice={printInvoice}/>
        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </> : loading ? <><KaHead page={"Načítání..."}/></> : <><KaHead page={"Objednávka nenalezena"}/></>

}

const translateStatus = (orderStatus) => {
    switch (orderStatus) {
        case 'NEW':
            return 'Nová';
        case 'PAID':
            return 'Zaplacená';
        case 'PROCESSED':
            return 'Dokončená';
    }
    return '';
}

const OrderDetail = ({order, printInvoice}) => {
    const date = new Date(order.created);
    const formatted = date.toLocaleString('cs-CS').replaceAll('. ', '.');

    return <Container className={"user-order-detail-table"}>
        <Row>
            <Col>
                <div className={"d-none d-lg-block"}>
                    <ProductTable items={order.products} gift={order.gift} coupon={order.coupon} total={order.total} shippingPrice={order.shippingPrice} />
                </div>
                {/*<div className={"d-lg-none"}>
                    <ProductMobileTable items={items} appliedGift={appliedGift} appliedCoupon={appliedCoupon}/>
                </div>*/}
            </Col>
        </Row>
    </Container>
}

const ProductTable = ({items, gift, coupon, total, shippingPrice}) => {
    return <table className={"white"}>
        <thead>
        <tr>
            <th></th>
            <th>Produkt</th>
            <th>Cena</th>
            <th>Množství</th>
            <th>Mezisoučet</th>
        </tr>
        </thead>
        <tbody>
        {
            items.filter(i => !i.type).map(i => <CartItem key={`cart-item-${i.id}`} {...i} />)
        }
        {gift && <tr className={"cart-item-row"}>
            <td></td>
            <td>{gift.name} (Dárek)</td>
            <td>zdarma</td>
            <td>1</td>
            <td>zdarma</td>
        </tr>
        }
        {
            coupon && <tr className={"cart-item-row"}>
                <td></td>
                <td>Slevový kód {coupon.code}</td>
                <td>-{coupon.amount} {coupon.amountType == "AMOUNT" ? "Kč" : "%"}</td>
                <td></td>
                <td></td>
            </tr>
        }
        {
            shippingPrice && <tr className={"cart-item-row"}>
                <td></td>
                <td>Doprava</td>
                <td>{formatCurrency(shippingPrice)}</td>
                <td></td>
                <td>{formatCurrency(shippingPrice)}</td>
            </tr>
        }
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Celkem</td>
            <td>{formatCurrency(total)}</td>
        </tr>
        </tbody>
    </table>
}

const CartItem = (props) => {
    const {id, product, price, count} = props;
    const {image} = product;
    return (
        <tr className={"cart-item-row"} key={id}>
            <td className={"cart-item-img"}>
                {image && <img src={getFileSrc(image.fullPath)}/>}
            </td>
            <td>
                {resolveVariantName(props)}
            </td>
            <td>
                {formatCurrency(price)}
            </td>
            <td>
                {count}
            </td>
            <td>
                {formatCurrency(count * price)}
            </td>
        </tr>
    )
}

const resolveVariantName = (variant) => {
    //console.log("Variant", variant);
    if (variant.product.productType == PRODUCT_TYPE.SIMPLE) {
        return variant.product.name;
    }
    return `${variant.product.name} - ${variant.productVariantDto.optionValues.map(ov => ov.valueName).join(", ")}`;
}

export default ProfileOrderDetailPage;