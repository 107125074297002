import React, {useEffect, useState} from 'react';
import KaHead from "../head";
import PublicHeader from "../../../components/publicHeader";
import ReviewsSection from "../reviews";
import PartnersSection from "../partners";
import FooterSection from "../footer";
import {getUserByEmail} from "../../../actions/userActions";
import {useAuth} from "../../../utils/auth";
import * as Yup from "yup";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Container, Row} from "react-bootstrap";
import {IconButton, Stack, TextField} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Oval} from "react-loader-spinner";
import discordIcon from "../../../assets/img/discord-icon.png";
import {joinDiscord} from "../../../actions/discordActions";

const ProfileMembershipPage = () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

    const [discordLoading, setDiscordLoading] = useState(false);

    const {user} = useAuth();
    const intl = useIntl();

    const [userDetail, setUserDetail] = useState();

    useEffect(() => {
        if (accessToken) {
            setDiscordLoading(true);
            fetch('https://discord.com/api/users/@me', {
                headers: {
                    authorization: `${tokenType} ${accessToken}`,
                },
            })
                .then(result => result.json())
                .then(response => {
                    const { id, username, discriminator } = response;
                    //console.log("RESPONSE", response);
                    //document.getElementById('info').innerText += ` ${username}#${discriminator}`;
                    return id;
                })
                .then(userId => joinDiscord({userId, accessToken}))
                .catch(console.error)
                .finally(() => {
                    setDiscordLoading(false);
                    toast.success("Uživatel přidán do Discordu");
                    if(window.location.hash) {
                        window.history.replaceState("", document.title, window.location.pathname);
                    }
                });
        }

        getUserByEmail(user).then(detail => {
            if (detail) {
                setUserDetail(detail);
            } else {
                throw new Error("No user found");
            }
        }).catch(err => {
            console.error("Error", err);
        });
    }, []);


    return userDetail ? <>
        <KaHead page={"Členství"}/>
        <PublicHeader
            type={"small"}
            titleComp={<h1 className="ka-title">{`Členství`}</h1>}></PublicHeader>
        <section className={"profile bg-dark-green"}>
            {userDetail.activeMembership && <DiscordPanel membership={userDetail.activeMembership} discordLoading={discordLoading} />}
            {!userDetail.activeMembership && <Container className={"cart"}>
                <Row className={"cart-empty"}>
                    <Col className={"text-center"}>
                        <h3 className={"white"}>Nemáte žádné aktivní členství</h3>
                        <a className={"button-green"} href={"/products"}>Zpět do obchodu</a>
                    </Col>
                </Row>
            </Container>}
        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </> : <><KaHead page={"Členství"}/></>

}

const navigateToDiscord = (navigate) => {
    const RETURN_URL = process.env.REACT_APP_DISCORD_RETURN_URL;
    //window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1126532010089906267&redirect_uri=${encodeURIComponent(RETURN_URL)}&response_type=token&scope=identify%20email%20connections%20guilds%20guilds.join`;
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=822813103812116511&redirect_uri=${encodeURIComponent(RETURN_URL)}&response_type=token&scope=identify%20email%20connections%20guilds%20guilds.join`;
}

const DiscordPanel = ({membership, discordLoading}) => {
    const navigate = useNavigate();
    const validTo = new Date(membership.validTo);
    const formatted = validTo.toLocaleString('cs-CS').replaceAll('. ', '.');
    return <Container className={"discord-panel"}>
        <Row>
            <Col xs={12}>
                <Stack className={"referal-stack"} direction={"horizontal"}>
                    <label>Aktivní členství: {membership.name} {membership.variant && ` - ${membership.variant}`} (Platné do: {formatted})</label>
                    <IconButton disabled={discordLoading} className={"discord-button"} edge="end" aria-label="delete" onClick={() => navigateToDiscord(navigate)}>
                        {discordLoading ? <Oval
                            height={30}
                            width={30}
                            color="white"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#dddddd"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        /> : <img width={30} src={discordIcon} />}
                        <span>Připojit</span>
                    </IconButton>
                </Stack>
            </Col>
        </Row>
    </Container>
}

export default ProfileMembershipPage;