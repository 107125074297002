import React from 'react';
import PTable from "../../../components/table/PTable";
import {auditColumns, detailColumn, idColumn} from "../../../components/table/columns";
import {ActionPanel, addNewAction} from "../../../components/actionPanel";
import {PATH} from "../../../const";
import {DASHBOARD, OPTION_LIST, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {getOptions} from "../../../actions/optionActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "option.name"})},
        ...auditColumns(intl),
        detailColumn(intl, (id)=>navigate(`${PATH.OPTIONS.LIST}/${id}`)),
    ];
}

const OptionsScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, OPTION_LIST]);

    const actions = [
        addNewAction(PATH.OPTIONS.NEW)
    ];

    return (
        <div>
            <ActionPanel actions={actions} />
            <PTable
                createColumns={createColumns}
                defaultSort={{field: 'name', sort: 'asc' }}
                getData={getOptions}
            />
        </div>
    )
}

export default OptionsScreen;