import {BASE_API_URL} from "../const";
import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {get, post, getBlob, patch} from "../utils/api.js";

const BASE_URL = `${BASE_API_URL}/api/order`;

export const getOrders = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

const createOrderBody = (values) => {
    const req = new FormData();
    const {image, ...rest} = values;
    if (typeof image === "File") {
        req.append("image", image, image.name);
    }
    req.append("body", JSON.stringify(rest));
    return req;
}

export const createOrder = (values) => {
    const companyId = values.company?.id;
    if (companyId) {
        values.companyId = companyId;
        delete values.company;
    }
    values.locations = values.locations?.map(l=>l.id);
    return createEntity(BASE_URL, values);
}

export const updateOrder = (values, id) => {
    const companyId = values.company?.id;
    if (companyId) {
        values.companyId = companyId;
        delete values.company;
    }
    values.locations = values.locations?.map(l=>l.id);
    return updateEntity(BASE_URL, createOrderBody(values), id)
}

export const getOrderById = (id) => {
    //return getById(BASE_URL, id);
    return get(BASE_URL + "/" + id);
}

export const updateOrderPayment = (orderId, payment) => {
    return patch(BASE_URL + "/" + orderId + "/pay", payment);
}

export const getUserOrders = (email) => {
    return get(BASE_URL + "/user");
}

export const getUserOrderDetail = (id) => {
    return get(BASE_URL + "/user/" + id);
}

export const downloadInvoice = (orderId) => {
    return getBlob(`${BASE_URL}/${orderId}/invoice`);
}

export const downloadInvoiceAdmin = (orderId) => {
    return getBlob(`${BASE_URL}/${orderId}/invoice/download`);
}

export const downloadAllInvoice = (year) => {
    return getBlob(`${BASE_URL}/invoice/download/${year}`);
}