import React, {useState} from 'react';
import PTable from "../../../components/table/PTable";
import {auditColumns, idColumn} from "../../../components/table/columns";
import {CATEGORIES_LIST, DASHBOARD, useBreadcrumbsComponent} from "../../../utils/breadcrumbs";
import {Button, Stack, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {createCategory, getCategories} from "../../../actions/categoryActions";

const createColumns = (intl, navigate) => {
    return [
        idColumn(intl),
        {field: 'name', headerName: intl({id: "category.name"})},
        ...auditColumns(intl),
    ];
}

const CategoriesScreen = () => {
    useBreadcrumbsComponent([DASHBOARD, CATEGORIES_LIST]);
    const [reload, setReload] = useState(1);
    const [loading, setLoading] = useState(false);


    const intl = useIntl();

    return (
        <div>
            <NewCategory intl={intl} setLoading={setLoading} onSubmit={() => {
                setReload((prevState => prevState + 1));
                console.log("Should reload");
            }} />
            {!loading && reload &&
                <PTable
                    createColumns={createColumns}
                    defaultSort={{field: 'name', sort: 'asc'}}
                    getData={getCategories}
                />
            }
        </div>
    )
}

const NewCategory = ({intl, onSubmit, setLoading}) => {
    const [newCategory, setNewCategory] = useState("");

    return <Stack direction={"row"} spacing={5}>
        <TextField
            label={intl.formatMessage({id: "category.name"})}
            type="text"
            name="newCategory"
            placeholder={intl.formatMessage({id: "category.name"})}
            onChange={e => setNewCategory(e.target.value)}
        />
        <Button color={"success"} variant={"contained"} onClick={() => {
            if (!newCategory) {
                return;
            }
            setLoading(true);
            createCategory({name: newCategory})
                .then(res=>{
                    setNewCategory("");
                    onSubmit();
                })
                .catch(err=>{

                })
                .finally(() =>{
                    setLoading(false);
                });
        }
        }><FormattedMessage id={"common.addNew"}/></Button>
    </Stack>
}

export default CategoriesScreen;