import React, {useEffect, useState} from 'react';
import KaHead from "./head";
import PublicHeader from "../../components/publicHeader";
import {Col, Container, Row} from "react-bootstrap";
import ReviewsSection from "./reviews";
import PartnersSection from "./partners";
import FooterSection from "./footer";
import {useNavigate, useParams} from "react-router-dom";
import {getPublicProductDetail, getPublicProducts} from "../../actions/publicActions";
import {isValidId, ToastWithLink} from "../../utils/utils";
import {toast} from "react-toastify";
import {useCart} from "react-use-cart";
import {PRODUCT_TYPE} from "../../const";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import {FastField, Formik} from "formik";
import * as Yup from "yup";
import {number} from "yup";
import {MySelect} from "../../components/form/components";
import {useUser} from "../../utils/auth";
import {getProductDetailForPublic, getProductsForPublic} from "../../actions/productActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lightbox from 'react-spring-lightbox';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useIntl} from "react-intl";

const ProductDetailPage = (props) => {
    const intl = useIntl();

    const navigate = useNavigate();

    let {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const user = useUser();

    const {addItem, items, inCart, removeItem, emptyCart} = useCart();

    const addVariantToCart = (product, variant) => {
        const withoutVariants = {...product};
        delete withoutVariants.variants;
        addItem({...variant, product: withoutVariants});
        //toast.success(`Produkt ${product.name} přidán do košíku`);
        toast.success(<ToastWithLink onClick={()=>navigate("/cart")} text={`Produkt ${product.name} přidán do košíku`} />);
    }

    const onAddToCart = (item, values) => {
        if (item.productType == PRODUCT_TYPE.SIMPLE) {
            addVariantToCart(item, item.variants[0]);
        } else {
            const variants = item.variants;
            const entries = Object.entries(values);

            const foundVariant = variants.find(v => {
                const optionValues = v.optionValues;
                let foundCombination = true;
                for (let [key, value] of entries) {
                    const optionId = Number(key.replace("opt-", ""));
                    const found = optionValues.find(ov => ov.optionId == optionId && ov.valueId == value);
                    if (!found) {
                        foundCombination = false;
                        break;
                    }
                }

                return foundCombination;
            });

            if (foundVariant) {
                addVariantToCart(item, foundVariant);
            } else {
                toast.error("Neexistujicí kombinace");
            }
        }
    }

    const removeFromCart = (item) => {
        removeItem(item);
        toast.error(`Product removed to Cart`);
    }

    useEffect(() => {
        if (isValidId(id)) {
            setLoading(true);
            try {
                if (user) {
                    getProductDetailForPublic(id).then((res) => {
                        setData(res);
                    });
                } else {
                    getPublicProductDetail(id).then((res) => {
                        setData(res);
                    });
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }

        }
    }, [user]);

    return <>
        <KaHead page={data ? data.name : ''}/>
        <PublicHeader titleComp={<h1 className="ka-title">{data && data.name}</h1>}/>
        <section className={"cart bg-dark-green"}>

            <Container>
                <Row>
                    <Col style={{marginBottom: "20px"}}>
                        <Button className={"button-green"} startIcon={<ArrowBackIcon/>} onClick={() => navigate(-1)}>
                            Zpět
                        </Button>
                    </Col>
                </Row>
            </Container>
            {data && <Detail data={data} addToCart={onAddToCart} inCart={inCart} removeFromCart={removeFromCart}
                             intl={intl}/>}

        </section>
        {/*<ReviewsSection/>*/}
        <PartnersSection/>
        <FooterSection/>
    </>

}

const Detail = ({data, addToCart, inCart, removeFromCart, intl}) => {
    const {id, name, image, description, price, variants, productType, options, gallery} = data;
    const productInCart = inCart(id);

    const [submitted, setSubmitted] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(price);

    const [selectedVariants, setSelectedVariants] = useState({});

    const validation = productType == PRODUCT_TYPE.SIMPLE ? {} : options.reduce((all, current) => {
        all[`opt-${current.optionId}`] = number().required(intl.formatMessage({id: "validation.required"}));
        return all;
    }, {});

    const variantsSchema = Yup.object().shape(validation);

    const editorDescription = description && description.startsWith("<");

    return <Container>
        <Row className={"product-detail"}>
            <Col xs={12} md={6}>
                {image && <img src={process.env.PUBLIC_URL + "/upload/" + image.fullPath}/>}
                {gallery && gallery.length && <CoolLightbox images={gallery} />}
            </Col>
            <Col xs={12} md={6}>
                {/*<h1>{name}</h1>*/}
                <h2>{currentPrice && currentPrice.toFixed(0)} Kč</h2>

                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        addToCart(data, values);
                    }}
                    validationSchema={variantsSchema}
                    initialValues={selectedVariants}
                >
                    {({values, setFieldValue, submitForm, errors}) => (<>
                        {
                            productType == PRODUCT_TYPE.VARIANTS &&
                            <VariantSelector setPrice={setCurrentPrice} values={values} submitted={submitted} errors={errors} variants={variants}
                                             options={options} setFieldValue={setFieldValue}/>
                        }

                        <div className={"product-button"}>
                            {
                                productInCart ?
                                    <Button endIcon={<ShoppingCartIcon/>} onClick={() => removeFromCart(id)}>
                                        Odebrat z košíku
                                    </Button> :
                                    <Button endIcon={<ShoppingCartIcon/>} onClick={() => {
                                        setSubmitted(true);
                                        submitForm();
                                    }}>
                                        Přidat do košíku
                                    </Button>
                            }
                        </div>
                    </>)}
                </Formik>
                {/*<div>Kategorie:</div>*/}
            </Col>
        </Row>
        <Row className={"product-detail"}>
            <Col xs={12} className={"product-description"}>
                <h3>Popis</h3>
                {editorDescription ? <div className={"product-description-value"} dangerouslySetInnerHTML={{__html: description}}></div> : <p>{description}</p>}
            </Col>
        </Row>
    </Container>;
}

const VariantSelector = ({variants, options, setFieldValue, errors, submitted, values, setPrice}) => {
    //console.log("Errors", errors);
    return <div className={"product-variants"}>
        {options.map(o => {
            const variantOptions = variants.reduce((all, curr) => {
                const opt = curr.optionValues.find(ov => ov.optionId == o.optionId);
                if (!all.find(a=>a.valueId == opt.valueId)) {
                    all.push({...opt, price: curr.price});
                }
                return all;
            }, []).sort((a, b) => (a.numberValue || 1) - (b.numberValue || 1));
            return <MySelect
                key={`option-${o.optionId}`}
                label={o.name}
                name={`opt-${o.optionId}`}
                options={variantOptions.map(v => ({id: v.id, name: v.valueName}))}
                onChange={(value) => {
                    const vprice = variantOptions.find(v => v.id == value);
                    if (vprice) {
                        setPrice(vprice.price);
                    }
                }}
            />
        })}
    </div>;
}

const CoolLightbox = ({images}) => {
    const [currentImageIndex, setCurrentIndex] = useState(0);
    const [opened, setOpened] = useState(false);

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < images.length &&
        setCurrentIndex(currentImageIndex + 1);

    const img = images.map((i, index)=>{
        return <img key={`gallery-${i.id}`} src={process.env.PUBLIC_URL + "/upload/" + i.fullPath} onClick={() => {
            setCurrentIndex(index);
            setOpened(true);
        }} />
    });

    return (
        <>
        <div className={"product-detail-gallery"}>
            {img}
        </div>
        <Lightbox
            isOpen={opened}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={images.map(g=>({
                src: process.env.PUBLIC_URL + "/upload/" + g.fullPath,
                loading: 'lazy'
            }))}
            currentIndex={currentImageIndex}
            renderHeader={() => (<div className={'lightbox-close'}><IconButton onClick={() => setOpened(false)} type="button">
                <CloseIcon size={60} />
            </IconButton></div>)}
            // renderFooter={() => (<CustomFooter />)}
            renderPrevButton={() => (<div className={'lightbox-prev'}><IconButton onClick={gotoPrevious} type="button">
                <ArrowBackIosIcon size={60} />
            </IconButton></div>)}
            renderNextButton={() => (<div className={'lightbox-next'}><IconButton onClick={gotoNext} type="button">
                <ArrowForwardIosIcon size={60} />
            </IconButton></div>)}
            // renderImageOverlay={() => (<ImageOverlayComponent >)}

            // className="cool-class"
            style={{ background: "grey" }}

             onClose={() => setOpened(false)}

            singleClickToZoom

            pageTransitionConfig={{
               from: { transform: "scale(0.75)", opacity: 0 },
               enter: { transform: "scale(1)", opacity: 1 },
               leave: { transform: "scale(0.75)", opacity: 0 },
               config: { mass: 1, tension: 320, friction: 32 }
             }}
        />
        </>
    );
};

export default ProductDetailPage;