import {BASE_API_URL, PRODUCT_TYPE} from "../const";
import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {get, post} from "../utils/api";

const BASE_URL = `${BASE_API_URL}/api/streams`;

export const getStreams = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const getStreamsToUi = () => {
    return get(BASE_URL + "/all");
}

export const createStream = (values) => {
    const formData = streamBody(values);
    return createEntity(BASE_URL, formData);
}

const streamBody = (values) => {
    return {
        name: values.name,
        url: values.url,
        tags: values.tags.map((t)=>{
            const tag = {
                name: t.name,
            }
            if (t.id && typeof t.id == "number") {
                tag.id = t.id;
            }
            return tag;
        })
    };
}

export const updateStream = (values, id) => {
    const formData = streamBody(values);
    return updateEntity(BASE_URL, formData, id)
}

export const getStreamById = (id) => {
    return get(`${BASE_URL}/${id}`);
}