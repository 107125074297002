import {createEntity, getAllFiltered, getById, updateEntity} from "./commonActions";
import {BASE_API_URL, PRODUCT_TYPE} from "../const";

const BASE_URL = `${BASE_API_URL}/api/gift`;

export const getGifts = (sortModel, filterModel, pageNumber, pageSize) => {
    return getAllFiltered(BASE_URL, sortModel, filterModel, pageNumber, pageSize);
}

export const createGift = (values) => {
    const formData = giftBody(values);
    return createEntity(BASE_URL, formData);
}

export const updateGift = (values, id) => {
    const formData = giftBody(values);
    return updateEntity(BASE_URL, formData, id);
}

export const getGiftById = (id) => {
    return getById(BASE_URL, id);
}

const giftBody = (values) => {
    var formData = new FormData();
    formData.append("name", values.name);
    if (!values.image?.id && values.image) {
        formData.append("img", values.image);
    }
    return formData;
}