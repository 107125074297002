import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../utils/auth";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import '../styles/Login.scss';
import logo from '../assets/img/logo_pruhledny.png';
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@mui/material";
import LanguageSwitcher from "../components/languageSwitcher";
import {useCart} from "react-use-cart";

const LogoutPage = (props) => {
    const auth = useAuth();
    const {emptyCart} = useCart();
    localStorage.removeItem("referal");
    emptyCart();
    auth.signout();

    return <Navigate to="/" />;
}

export default LogoutPage;